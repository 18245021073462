import React, { useEffect, useState } from 'react'
import SbEditable from '../../utils/sb-editable'
import { renderBloks } from 'utils'
import { Grid, GridItem, PageIntro } from 'components'
import { InspirationBoardConsumer } from '../../contexts/InspirationBoard/inspirationBoardContext'

const INSPIRATION_BOARD_URL = `${process.env.GATSBY_API_URL}/inspiration-board`

const InspirationBoardPage = ({ body, pins, isLoading }) => (
  <div className="container">
    {pins ? (
      <>
        {body}
        <Grid columns={pins.length > 20 ? 4 : 3}>
          {pins.map((pin, index) => (
            <GridItem key={index} image={pin.image} title={pin.title} link={`${pin.link}`} type={pin.type} uuid={pin.uuid} />
          ))}
        </Grid>
      </>
    ) : (
      <PageIntro title={`Inspiration Board`}> {isLoading ? 'Loading...' : 'There is no Inspiration Board associated with this ID'} </PageIntro>
    )
    }
  </div>
)

export const EditableInspirationBoardPage = ({ blok }) => {
  const [fetchedPins, setFetchedPins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const search = typeof window !== 'undefined' ? window.location.search : null;

  useEffect(() => {

    if (!search) {
      return;
    }
    const params = new URLSearchParams(window.location.search);
    const boardId = params.get('board');

    async function fetchBoard(id) {

      setIsLoading(true);
      fetch(`${INSPIRATION_BOARD_URL}/${id}`)
        .then(response => {
          return response.json();
        })
        .then(response => {
          setIsLoading(false)
          if (response.error) {
            setIsLoaded(false)
            return;
          }
          setFetchedPins(JSON.parse(decodeURIComponent(window.atob(response))))
          setIsLoaded(true)
        });
    }

    if (boardId) {
      fetchBoard(boardId)
    }

  }, [search])

  return (
    <SbEditable content={blok}>
      <InspirationBoardConsumer>
        {({ pins }) => (
          <InspirationBoardPage body={blok.body && renderBloks(blok.body)} pins={isLoaded ? fetchedPins : pins} loading={isLoading} />
        )}
      </InspirationBoardConsumer>
    </SbEditable>
  )
}

export default InspirationBoardPage

import React from 'react'
import { marked } from 'marked'
import SbEditable from '../../utils/sb-editable'
import { replaceIcons } from 'components/icon/icon'
import ReactHtmlParser from 'react-html-parser'

const Text = ({ children }) => (
  <div className="text-container">
    {children && ReactHtmlParser(marked(children), {
      transform: node => {
        node.data = replaceIcons(node.data)
      }
    })}
  </div>
)

export const EditableText = ({ blok }) => (
  <SbEditable content={blok}>
    <Text>{blok.text}</Text>
  </SbEditable>
)

export default Text

import React, { useMemo } from 'react';
import SbEditable from '../../utils/sb-editable'
import Grid from '../grid/grid';
import Image from '../image/image.js';
import aiaCes from './images/AIA-CES-Provider-Logo.png';
import yelWht from './images/Horizontal-Single-Program-YEL-WHT.png'

import mindfulMaterials from './images/Mindful-logo-Transparentbackground.png'
import globalServices from './images/SCS Global Services Contour without Percentages.png'
import envProduct from './images/SCS_Verified_EnvProducDeclarationBasedonLCA_1CG.png'
import healthProduct from './images/SCS_Verified_HealthProductDeclaration_1CG.png'

const defaultImages = [
  {
    filename: aiaCes,
    alt: 'AIA CES Provider Logo'
  },
  {
    filename: mindfulMaterials,
    alt: 'Mindful Materials'
  },
  {
    filename: yelWht,
    alt: 'YEL WHITE'
  },
  {
    filename: globalServices,
    alt: 'SCS Global Services Contour without Percentages'
  },
  {
    filename: envProduct,
    alt: 'SCS Verified EnvProducDeclarationBasedonLCA'
  },
  {
    filename: healthProduct,
    alt: 'SCS Verified HealthProductDeclaration'
  }
];

export const calculateColumns = (totalItems, maxColumns = 6) => {
  const columns = Math.max(1, Math.min(maxColumns, totalItems));
  const rows = Math.ceil(totalItems / columns);
  return Math.ceil(totalItems / rows);
}

const Certifications = ({ size, title, grayscale, images }) => {
  const numberOfColumns = useMemo(() => {
    return calculateColumns(images.length);
  }, [images, calculateColumns]);
  return (
    <div className={`certifications certifications--${size}`}>
      <Grid columns={numberOfColumns} title={title} centered={true}>
        {(images || defaultImages).map((image, index) => (
          <div key={index} className={`certifications__item certifications__item--${size} ${grayscale ? 'certifications__item--grayscale' : ''}`}>
            <Image src={image.filename || image.image} link={image.link?.cached_url} alt={image.alt || image.text} />
          </div>
        ))}
      </Grid>
    </div>
  )
};

export const EditableCertifications = ({ blok }) => (
  <SbEditable content={blok}>
    <Certifications size={blok.size} title={blok.title} grayscale={!!blok.grayscale} images={blok.logos} />
  </SbEditable>
)

export default Certifications;
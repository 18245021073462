import React, { useCallback, useEffect, useState, useRef } from 'react'
import { parse, stringify } from 'tiny-querystring'
import algoliasearch from 'algoliasearch'
import { InstantSearch, connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import { Button, FacetSearchFilters } from 'components';
import { useInView } from 'react-intersection-observer';

const DEBOUNCE_TIME = 400
const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY, { _useRequestCache: true })

export const flattenState = state => {
  const facets =
    state.refinementList &&
    Object.keys(state.refinementList).reduce((facetList, refinement) => {
      if (state.refinementList[refinement] === '') return facetList
      facetList[refinement] = state.refinementList[refinement].join('~')
      return facetList
    }, {})
  return {
    page: state.page,
    ...facets,
  }
}
export const createURL = state =>
  `${location.protocol}//${location.host}${location.pathname}?${stringify(flattenState(state))}`

export const searchStateToUrl = searchState => (searchState ? createURL(searchState) : '')

export const urlToSearchState = location => {
  const flatState = parse(location.search.slice(1))
  const { page, ...refinements } = flatState
  return {
    page: page || 1,
    refinementList:
      refinements &&
      Object.keys(refinements).reduce((refinementList, refinement) => {
        refinementList[refinement] = refinements[refinement].split('~')
        return refinementList
      }, {}),
  }
}

const FacetSearch = ({ facets, datasources, indexName, renderHits, hitsPerPage = 24 }) => {

  const [searchState, setSearchState] = useState(urlToSearchState({ search: '?' }));
  const [debouncedSetState, setDebouncedSetState] = useState(null);
  const sentinel = useRef();

  useEffect(() => {
    setSearchState(urlToSearchState(location))
  }, [])

  const onSearchStateChange = useCallback(updatedSearchState => {
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
      setTimeout(() => {
        history.pushState(
          updatedSearchState,
          null,
          searchStateToUrl(updatedSearchState)
        );
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  }, [DEBOUNCE_TIME])

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <div className="facet-search">
        <Configure hitsPerPage={hitsPerPage} />
        <FacetSearchFilters hideImages={true} facets={facets} datasources={datasources} />
        <div className="facet-search__results" ref={sentinel}>
          <InfiniteHits renderHits={renderHits} />
        </div>
      </div>
    </InstantSearch>
  )

}

const InfiniteHits = connectInfiniteHits(({ hits, hasMore, refineNext, renderHits }) => {
  const [sentinel, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    inView && refineNext();
  }, [sentinel, inView])

  return (
    <div>
      {renderHits(hits)}
      {hasMore && (
        <div className="facet-search__loadmore" ref={sentinel}>Loading more...</div>
      )}
    </div>
  )
})

export default FacetSearch
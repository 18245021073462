import React from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import ConditionalWrap from 'conditional-wrap'
import { Link } from 'components'
import { renderBloks, slugify } from 'utils'

const Card = ({ alignment = 'center', color, image, imageHeight, imagePosition, title, title_size, full_bleed, link, children }) => (
  <div
    className={cn(
      'card',
      { 'card--regular': imagePosition === 'background' || imagePosition === '' },
      { card__fullbleed: full_bleed === true },
      `card--${alignment}`
    )}
    style={{
      color: color,
      backgroundImage: `url(${imagePosition === 'background' ? image : ''})`,
      minHeight: imagePosition === 'background' ? imageHeight : 'auto',
    }}
  >
    {imagePosition === 'below_title' && (
      <h2 className={cn('card__title', 'card__title--above', `card__title--${title_size || 'md'}`)}>{title}</h2>
    )}
    {imagePosition !== 'background' && (
      <ConditionalWrap condition={link} wrap={children => <Link to={link}>{children}</Link>}>
        <div
          className={cn('card__image', { 'card__image--above': imagePosition === 'above_title' }, 'd-print-none')}
          style={{ backgroundImage: `url(${image})`, height: imageHeight }}
          data-link={link}
        />
      </ConditionalWrap>
    )}
    <figure className="d-none d-print-block">
      <img src={image} alt={title} />
    </figure>

    <div className="card__body">
      <div className="card__content">
        {(imagePosition === 'above_title' || imagePosition === 'background') && (
          <h2
            className={cn('card__title', 'card__title--below', `card__title--${title_size || 'md'}`)}
            id={slugify(title)}
          >
            {title}
          </h2>
        )}
        {children}
      </div>
    </div>
  </div>
)
export const CardSlim = ({ title, cta, color, background, full_bleed }) => (
  <div
    className={cn('card', 'card--slim', { card__fullbleed: full_bleed === true })}
    style={{ color: color, backgroundImage: `url(${background || ''})` }}
  >
    <figure className="d-none d-print-block">
      <img src={background} alt={title} />
    </figure>

    <h2 className="card__title" id={slugify(title)}>{title}</h2>
    {cta}
  </div>
)

export const EditableCard = ({ blok }) => (
  <SbEditable content={blok}>
    <Card
      color={blok.color}
      image={blok.image}
      imageHeight={blok.image_height ? `${blok.image_height}px` : '230px'}
      imagePosition={blok.image_position === '' ? 'background' : blok.image_position}
      title={blok.title}
      title_size={blok.title_size}
      alignment={blok.alignment}
      link={blok.link && blok.link.cached_url}
    >
      {blok.content && renderBloks(blok.content)}
    </Card>
  </SbEditable>
)

export const EditableCardSlim = ({ blok }) => (
  <SbEditable content={blok}>
    <CardSlim
      title={blok.title}
      cta={renderBloks(blok.cta)}
      color={blok.color}
      background={blok.background}
      full_bleed={blok.full_bleed}
    />
  </SbEditable>
)

export default Card

import React, { useState } from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import { graphql, useStaticQuery } from 'gatsby'
import { Nav, Search, Icon, CartToggle, BoardToggle, Link } from 'components'

const HeaderToggle = ({ open, ...props }) => (
  <div {...props}>{open ? <Icon name={`close`} /> : <Icon name={`hamburger`} />}</div>
)

const Header = ({ logo, logoInverse, nav }) => {
  const [open, setOpenState] = useState(false);

  const toggle = () => {
    setOpenState(!open)
  }

  const close = () => {
    setOpenState(false)
  }

  return <OutsideClickHandler onOutsideClick={close}>
    <div className={cn('header', { 'header--open': open })}>
      <div className="header__inner">
        <Link className="header__logo" to={`/`}>
          <img className="header__logo-img" src={logo} alt="Sensitile" />
          <img className="header__logo-img--reverse" src={logoInverse} alt="Sensitile" />
        </Link>
        <div className="header__tools">
          <div className={cn('header__cart', { 'header__cart--hidden': open })}>
            <CartToggle />
          </div>
          <HeaderToggle className="header__toggle" open={open} onClick={toggle} />
        </div>
        <div className="header__navbar">
          <div className="header__nav">
            <Nav items={nav} onNavigate={close} />
          </div>

          <div className="header__nav-tools">
            <div className="header__search">
              <Search />
            </div>
            <div className="header__contacts">
              <Link to="/support-and-care/contact-us" onClick={close}>
                <Icon name="contacts"> Contact Us</Icon>
              </Link>
            </div>
            <div className="header__board">
              <BoardToggle />
            </div>
            <div className="header__nav-cart">
              <CartToggle />
            </div>
          </div>
        </div>
      </div>
    </div>
  </OutsideClickHandler>
}

export const EditableHeader = ({ blok }) => (
  <SbEditable content={blok}>
    <Header logo={blok.logo} logoInverse={blok.logo_inverse} nav={blok.nav} />
  </SbEditable>
)

export const HeaderContainer = () => {

  const { storyblokEntry: { content } } = useStaticQuery(graphql`
    query HeaderQuery {
      storyblokEntry(slug: { eq: "site-header" }) {
        content
      }
    }
  `);

  return <EditableHeader blok={JSON.parse(content)} />

}

export default Header

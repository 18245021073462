import React, { Component } from 'react'
import { Card } from 'components'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import serialize from 'form-serialize'
import { ReCaptcha } from 'react-recaptcha-v3'

const GATSBY_API_URL = process.env.GATSBY_API_URL
const GATSBY_RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY

class NewsletterForm extends Component {
  state = {
    isSubmitting: false,
    isSubmitted: false,
    recaptchaToken: '',
    error: '',
  }

  recaptchaRef = React.createRef()

  verifyRecaptcha = recaptchaToken => {
    this.setState({ recaptchaToken })
  }

  resetRecaptcha = () => {
    this.recaptchaRef.current.execute()
  }

  handleSubmit = async ev => {
    ev.preventDefault()
    const data = serialize(this.form, { hash: true })
    data.recaptchaToken = this.state.recaptchaToken
    this.setState({
      isSubmitting: true,
      isSubmitted: false,
      error: '',
    })

    try {
      const response = await fetch(this.form.getAttribute('action'), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const result = await response.json()
      this.resetRecaptcha()
      this.setState({
        isSubmitting: false,
        isSubmitted: response.status === 201,
        error: response.status > 201 ? result.message : '',
      })
    } catch (err) {
      this.resetRecaptcha()
      this.setState({
        isSubmitting: false,
        error: err.message,
      })
    }
  }

  render() {
    const { list_id, color, ...props } = this.props
    const { isSubmitting, isSubmitted, error } = this.state
    return (
      <Card color={color} {...props} >
        <div className="newsletter__body">
          {isSubmitted && (
            <p className="newsletter__message newsletter-message--success">
              Success, please check your email for confirming your subscription.
            </p>
          )}
          {error && <p className="newsletter__message newsletter-message--error">{error}</p>}
          <form
            ref={el => (this.form = el)}
            className="newsletter__form"
            aria-label="Newsletter Form"
            action={`${GATSBY_API_URL}/newsletter/${list_id}`}
            onSubmit={this.handleSubmit}
          >
            <fieldset className="form-group">
              <input
                style={{ color }}
                className="newsletter__input newsletter__input--email"
                type="email"
                name="email"
                placeholder="Enter your Email Address"
              />
            </fieldset>
            <button className="newsletter__input newsletter__input--submit" type="submit" disabled={isSubmitting}>
              Sign Up
            </button>
            <ReCaptcha
              ref={this.recaptchaRef}
              sitekey={GATSBY_RECAPTCHA_SITE_KEY}
              action="newsletter_form"
              verifyCallback={this.verifyRecaptcha}
            />
          </form>
        </div>
      </Card>
    )
  }
}

export const EditableNewsletterForm = ({ blok }) => (
  <SbEditable content={blok}>
    <NewsletterForm
      title={blok.title}
      list_id={blok.list_id}
      color={blok.color}
      image={blok.image}
      imageHeight={blok.image_height ? `${blok.image_height}px` : '230px'}
      imagePosition={blok.image_position === '' ? 'background' : blok.image_position}
      full_bleed={blok.full_bleed}
    />
  </SbEditable>
)

export default NewsletterForm

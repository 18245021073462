import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import CloseOnEscape from 'react-close-on-escape'

const Legend = ({ title, description, x, y }) => {

  const [open, setOpen] = useState(false)

  const toggle = (ev) => {
    ev && ev.preventDefault();
    ev && ev.stopPropagation();
    setOpen(!open)
    return false;
  }

  const close = (_ev) => {
    setOpen(false)
    return false;
  }

  return (
    <li className="legend__item" style={{ left: `${x}%`, top: `${y}%` }}>
      <div role="button" aria-label={open ? 'Close' : 'Open'} className="legend__icon" onClick={toggle}>•</div>
      {open && (
        <div className="legend__contents">
          <OutsideClickHandler onOutsideClick={close}>
            <CloseOnEscape onEscape={close}>
              <h4 className="legend__title">{title}</h4>
              <div className="legend__description">{description}</div>
            </CloseOnEscape>
          </OutsideClickHandler>
        </div>
      )}
    </li>
  )

}

export default Legend

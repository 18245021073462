import React, { useState, useEffect, useCallback, useMemo } from 'react'
import cn from 'classnames'
import { hasRulesMatched } from '../../utils/utils'

const SpecifierCheckbox = (props) => {
  const { form, name, checked, readonly } = props
  const [state, setState] = useState({ 
    checked: false,
    readonly: false, 
    fraction: null
  })

  // eg replacer={{ "4'x8'": '48"x80"' }}
  const replaceStr = (match, p1) => {
    if(!props.replacer || !props.replacer[p1]) { return p1 }
    return props.replacer[p1]
  }

  const replacedLabel = useMemo(
    () => props.label.replace(/{(.*?)}/, replaceStr),
    [props.label, replaceStr]
  )

  // use this to set value and manually trigger onchange
  const setValue = (value, e) => {
    setState({ 
      ...value,
      checked: !!value.checked, // value `on` becomes true
      fraction: !!value.checked && props.fraction });
    
    // give fake timeout for Chrome issue
    setTimeout(() => props.triggerChange(e), 0)
  }

  const handleOnChange = useCallback(({ target: { checked } }) => {
    // don't change state if readonly is set
    if(state.readonly) { return }

    setValue({ checked, readonly: false })
  }, [setValue])


  // if target is set, its value needs to match with form selection
  const renderNotes = (note, idx) => {
    const content = note.content.replace(/{(.*?)}/, replaceStr);

    return (
      hasRulesMatched(note.dependon, form) &&
      <small key={idx} className='specifier-materials__field__helper'>{content}</small>
    )
  }

  useEffect(function onPropsChange() {
    if(state.checked !== checked || state.readonly !== readonly) {
      setValue({ checked, readonly })
    }
  }, [checked, readonly])


  // when it's forced unchecked and reaonly, this checkbox should not be visible
  return (
    <div className={cn(
      'form-group col-sm-12 specifier-materials__checkbox',
      props.classNames,
      { 'sr-only': !state.checked && state.readonly }
    )}>
      <div className='custom-control custom-checkbox'>
        <input type='checkbox'
          name={name}
          id={name}
          onChange={handleOnChange}
          checked={state.checked}
          readOnly={state.readonly}
          className='custom-control-input'
          value="on"
        />
        <label htmlFor={name} className='custom-control-label'>
          {replacedLabel}
        </label>
        {state.fraction && <input type='hidden' name={`measure-${props.measure}`} value={state.fraction} />}
      </div>

      {!state.checked && <input type="hidden" name={name} value="off" />}
      
      {props.description && <p>{props.description}</p>}
      {props.notes && props.notes.map(renderNotes)}
    </div>
  )
}


export default SpecifierCheckbox

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import { renderBlok } from 'utils'

const Grid = ({ columns, title, children = [], description, addClass = '', centered = false }) => {
  const rows = children.reduce((result, child, index) => {
    const ch = Math.floor(index / columns)
    result[ch] = [].concat(result[ch] || [], child)
    return result
  }, [])

  return (
    <div className={`grid container ${addClass}`}>
      {title && <h2 className="grid__title">{title}</h2>}
      {description && <div className="grid__description">{description}</div>}
      {rows.map((row, index) => (
        <div className={cn(['row', { 'row--centered': centered }])} key={index}>
          {row.map((child, index) => (
            <div key={index} className={cn({ [`col-sm-${12 / columns}`]: columns != 5, 'col-2dot4': columns == 5 })}>
              {child}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export const EditableGrid = ({ blok }) => (
  <SbEditable content={blok}>
    <Grid columns={blok.columns}>{blok.items && blok.items.map((item) => renderBlok(item))}</Grid>
  </SbEditable>
)

export default Grid

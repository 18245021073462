import React, { forwardRef } from 'react'
import cn from 'classnames'

const SpecifierCodeField = forwardRef(({
  orderingCode,
  isCopyTooltipVisible,
  handleCopyClick
}, ref) => (
  <div className="specifier-lights__code-field form-group">
    <label htmlFor="ordering-code">Ordering Code</label>

    <div className="input-group specifier-lights__copy-field">
      {/* Readonly Code Field */}
      <input type="text" id="ordering-code" className="form-control" value={orderingCode} ref={ref} readOnly />
      
      {/* Copy Button */}
      <div className="input-group-append">
        <div className={cn('specifier-lights__copy-field-message text-primary', { 'specifier-lights__copy-field-message--hidden': !isCopyTooltipVisible })}>Copied to Clipboard!</div>
        <button type="button" className="btn btn-primary d-print-none" onClick={handleCopyClick}>Copy</button>
      </div>
    </div>
  </div>

))

export default SpecifierCodeField
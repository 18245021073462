import React, { Fragment } from 'react'
import cn from 'classnames'
import { marked } from 'marked'
import SbEditable from '../../utils/sb-editable'
import Icon, { replaceIcons } from 'components/icon/icon'

const Table = ({ title, header = [], body = [], footer = [], notes }) => (
  <Fragment>
    <h3 className="table__title">{title}</h3>
    <div className="table__container">
      <table className="table">
        <thead>
          <tr className="table__header-row">
            {header.map((cell, index) => (
              <th key={index} className={cn('table__cell', { 'table__cell__title': index > 1 }, { 'table__small-text': index < 1 })}>
                <div className="table__cell-contents">
                  {replaceIcons(cell)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.map((row, index) => (
            <tr key={index} className="table__body-row">
              {row.map((cell, index) => (
                <td key={index} className="table__cell">
                  <div className="table__cell-contents">
                    {replaceIcons(cell)}
                  </div>
                </td>
              ))}
            </tr>
          ))}
          {footer && (
            <tr>
              <td colSpan={100}>
                <div className="table__footer">
                  {footer.map((footerItem, index) => (
                    <div key={index} className="table__footer-item"><Icon name={footerItem.icon} /> = {footerItem.text}</div>
                  ))}
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {notes && (
          <tfoot>
            <tr>
              <td colSpan={100} dangerouslySetInnerHTML={{ __html: marked(notes) }}>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  </Fragment>
)


export const EditableTable = ({ blok }) => (
  <SbEditable content={blok}>
    <Table title={blok.title} header={blok.table.thead} body={blok.table.tbody} footer={blok.footer} notes={blok.notes} />
  </SbEditable>
)

export default Table

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import { Grid } from 'components'
import { renderBlok, renderBloks } from 'utils'

const Hardware = ({ text, image, attachment }) => (
  <div className="hardware">
    <img className="hardware__image" src={image} />
    {text}
    {attachment}
  </div>
)

export const HardwareGrid = ({ title, description, children }) => (
  <div className="hardware-grid__container">
    <Grid title={title} description={description} columns={2}>
      {children}
    </Grid>
  </div>
)

export const EditableHardwareGrid = ({ blok }) => (
  <SbEditable content={blok}>
    <HardwareGrid title={blok.title} description={renderBloks(blok.description)}>
      {blok.hardware.map(hardware => renderBlok(hardware))}
    </HardwareGrid>
  </SbEditable>
)

export const EditableHardware = ({ blok }) => (
  <SbEditable content={blok}>
    <Hardware text={renderBloks(blok.text)} image={blok.image} attachment={renderBloks(blok.attachment)} />
  </SbEditable>
)

export default Hardware

import React, { Component, useRef, useState } from 'react'
import SbEditable from '../../utils/sb-editable'
import { snakeCase } from 'lodash'
import { renderBlok } from 'utils'
import withConversation from '../conversational-form/conversational-form'
import { slugify } from '../../utils'
const FormContext = React.createContext()

const FORM_LAMBDA_URL = `${process.env.GATSBY_API_URL}/generic-form`

const Form = ({ title, action, textAlign, minimal, inverse, isConversational, children }) => {
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [fields, setFields] = useState([])

  const form = useRef()

  const registerField = (field) => {
    setFields([...fields, field])
  }

  const deregisterField = (remove) => {
    setFields(fields.filter((field) => field !== remove))
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const failedInputs = processFailedInputs()

    if (failedInputs === 0) {
      const formData = new FormData(form.current)
      postForm(formData)
    }
  }

  const processFailedInputs = () => {
    const failedInputs = fields.filter((field) => {
      field.validate()
      if (field.isValid()) {
        return false
      } else {
        return true
      }
    })
    return failedInputs.length
  }

  const postForm = (data) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', FORM_LAMBDA_URL, true)
    xhr.send(data)
    xhr.addEventListener('load', function () {
      if (this.status >= 200) {
        setSuccess('Your request was successfully sent')
        // fields.forEach(field => field.setState({'value':''}))
        form.current.reset()
      }
    })
  }

  return (
    <div className="container">
      {title && <h1 id={slugify(title)}>{title}</h1>}
      <form
        ref={form}
        aria-labelledby={slugify(title)}
        onSubmit={handleSubmit}
        className={`form form--${textAlign}-text ${minimal ? 'minimal' : ''} ${inverse ? 'inverse' : ''}`}
        action={action}
      >
        <FormContext.Provider value={{ registerField, deregisterField, isConversational }}>
          {children}
        </FormContext.Provider>
        {error && <p className="newsletter__message newsletter-message--error">{error}</p>}
        {success && <p className="newsletter__message newsletter-message--succes">{success}</p>}
      </form>
    </div>
  )
}

export const FormRow = ({ children }) => <div className="form__row">{children}</div>

export const EditableFormRow = ({ blok }) => (
  <SbEditable content={blok}>
    <FormRow>{blok.fields.map((field) => renderBlok(field))}</FormRow>
  </SbEditable>
)

export const EditableForm = ({ blok, getConversation }) => {
  const isConversational = blok.component === 'conversational_form'
  blok = blok.component === 'conversational_form' ? blok.form[0] : blok
  return (
    <SbEditable content={blok}>
      <Form
        isConversational={isConversational}
        title={blok.title}
        email={blok.email_address}
        textAlign={blok.text_position ? blok.text_position : 'left'}
        minimal={blok.minimal}
        inverse={blok.inverse}
        getConversation={getConversation}
      >
        {blok.fields &&
          blok.fields.map((field, index) => {
            field.name = snakeCase(field.name)
            return (
              <FormContext.Consumer key={index}>
                {(context) => {
                  return renderBlok(field, index, context)
                }}
              </FormContext.Consumer>
            )
          })}
      </Form>
    </SbEditable>
  )
}

export const ConversationalForm = withConversation(EditableForm)
export const FormConsumer = FormContext.Consumer

export default Form

import Cta, { Button, CtaGroup, EditableCtaGroup, EditableCta } from './cta/cta'
import Page from './page'
import Project, { ProjectCategoriesTable } from './project/project'
import ProjectMap, { EditableProjectMap } from './project-map/project-map'
import FacetSearch from './facet-search/facet-search'
import FacetSearchFilters from './facet-search/facet-search-filters'
import FileDownload, { EditableFileDownload } from './file-download/file-download'
import { EditableSpecificationBlock } from './specification-block/specification-block'
import Footer, { FooterContainer, EditableFooter } from './footer/footer'
import Header, { HeaderContainer, EditableHeader } from './header/header'
import Icon, { EditableIcon } from './icon/icon'
import Layout from './layout/layout'
import Link, { EditableLink } from './link/link'
import Nav from './nav/nav'
import NavItem from './nav/nav-item'
import Search from './search/search'
import Social, { EditableSocial } from './social/social'
import Thumbnail, { ThumbnailGroup, EditableThumbnailGroup, EditableThumbnail } from './thumbnail/thumbnail'
import Text, { EditableText } from './text/text'
import Hero, { EditableHero } from './hero/hero'
import Image, { EditableImage } from './image/image'
import ImageWithLegend, { EditableImageWithLegend } from './image/image-with-legend'
import Carousel, { EditableCarousel, CarouselItem, EditableCarouselItem } from './carousel/carousel'
import PageIntro, { EditablePageIntro } from './page-intro/page-intro'
import Card, { EditableCard, CardSlim, EditableCardSlim } from './card/card'
import CardGroup, { EditableCardGroup } from './card/card-group'
import RiverFlow, { EditableRiverFlow, InlineRiverFlow } from './river-flow/river-flow'
import Grid, { EditableGrid } from './grid/grid'
import SectionHeading, { EditableSectionHeading } from './section-heading/section-heading'
import SingleProject, { EditableSingleProject } from './single-project/single-project'
import Portfolio, { EditablePortfolio, PortfolioItem } from './portfolio/portfolio'
import Modal, { EditableModal } from './modal/modal'
import ShopifyCollection from './shopify/collection'
import ShopifyProduct from './shopify/product'
import Cart, { CartToggle } from './shopify/cart'
import Board, { BoardToggle } from './inspiration-board/board'
import Banner, { EditableBanner } from './banner/banner'
import { EditableSample } from './sample/sample'
import ThicknessComparison, {
  EditableThicknessComparison,
  ThicknessSlice,
  EditableThicknessSlice,
} from './thickness-comparison/thickness-comparison'
import CarouselGrid, { EditableCarouselGrid } from './carousel-grid/carousel-grid'
import SamplesPage, { EditableSamplesPage } from './samples-page/samples-page'
import SpecifierMaterials from './specifiers/specifier-materials/specifier-materials'
import SpecifierLights from './specifiers/specifier-lights/specifier-lights'
import NewsletterForm, { EditableNewsletterForm } from './newsletter/newsletter'
import GridItem, { EditableGridItem } from './grid-item/grid-item'
import ProjectSearch from './project-search/project-search'
import Legend from './legend/legend'
import SEO, { EditableSEO } from './SEO/SEO'
import ErrorBoundary from './error-boundary/error-boundary'
import CategoryTable from './category-table/category-table'
import Form, { FormRow, EditableFormRow, EditableForm, ConversationalForm } from './form/form'
import { ShortText, LongText, EditableTextField } from './form/text-fields'
import { Select, EditableSelect } from './form/select-fields'
import { HiddenField } from './form/hidden-field'
import { Phone } from './form/phone'
import { RadioButtons } from './form/radio-buttons'
import { Checkboxes } from './form/checkboxes'
import { FileField, EditableFile, SubmitButton, EditableSubmitButton } from './form/form-fields'
import IssuuPublication, { EditableIssuuPublication } from './issuu-publication/issuu-publication'
import Table, { EditableTable } from './table/table'
import VideoPlayer, { EditableVideoPlayer } from './video-player/video-player'
import SiteMap, { EditableSiteMap } from './site-map/site-map'
import InstagramFeed, { EditableInstagramFeed } from './instagram-feed/instagram-feed'
import Breadcrumbs, { EditableBreadcrumbs } from './breadcrumbs/breadcrumbs'
import Share from './share/share'
import Container, { EditableContainer } from '././container/container'
import InspirationBoardPage, { EditableInspirationBoardPage } from './inspiration-board/inspiration-board-page'
import Hardware, { EditableHardware, HardwareGrid, EditableHardwareGrid } from './hardware/hardware'
import OnHandStock, { EditableOnHandStock } from './on-hand-stock/on-hand-stock'
import OnHandStockTable, { EditableOnHandStockTable } from './on-hand-stock/on-hand-stock-table'
import SingleStockItem, { EditableSingleStockItem } from './single-stock-item/single-stock-item'
import StockItemSearch from './stock-item-search/stock-item-search'
import StockItemForm from './form/stock-item-form'
import Toolbar from './toolbar/toolbar'
import Lighting, { EditableLighting, LightingColumn, EditableLightingColumn } from './lighting/lighting'
import KeyValues, { EditableKeyValues, KeyValue, EditableKeyValue } from './key-value/key-value'
import PatternPage, { EditablePatternPage } from './pattern-page/pattern-page'
import ColorPage, { EditableColorPage } from './color-page/color-page'
import { EditableSingleSample } from './single-sample/single-sample'
import Certifications, { EditableCertifications } from './certifications/certifications'

import ImageComposition, {
  EditableImageComposition,
  ImageCompositionItem,
  EditableImageCompositionItem,
} from './image-composition/image-composition'
import NewsItem from './news/news-item'
import NewsPage from './news/news-page'

export const EditableComponents = {
  banner: EditableBanner,
  card_group: EditableCardGroup,
  card_slim: EditableCardSlim,
  card: EditableCard,
  carousel_grid: EditableCarouselGrid,
  carousel_item: EditableCarouselItem,
  carousel: EditableCarousel,
  certifications: EditableCertifications,
  color_page: EditableColorPage,
  conversational_form: ConversationalForm,
  cta_group: EditableCtaGroup,
  cta: EditableCta,
  container: EditableContainer,
  file_download: EditableFileDownload,
  footer: EditableFooter,
  form_field_file: EditableFile,
  form_field_hidden: HiddenField,
  form_field_select: EditableSelect,
  form_field_submit_button: EditableSubmitButton,
  form_field_text: EditableTextField,
  form_row: EditableFormRow,
  form: EditableForm,
  grid_item: EditableGridItem,
  grid: EditableGrid,
  hardware: EditableHardware,
  hardware_grid: EditableHardwareGrid,
  header: EditableHeader,
  hero: EditableHero,
  icon: EditableIcon,
  image_with_legend: EditableImageWithLegend,
  image: EditableImage,
  image_composition: EditableImageComposition,
  image_composition_item: EditableImageCompositionItem,
  inspiration_board_page: EditableInspirationBoardPage,
  instagram_feed: EditableInstagramFeed,
  key_value: EditableKeyValue,
  key_values: EditableKeyValues,
  lighting: EditableLighting,
  lighting_column: EditableLightingColumn,
  link: EditableLink,
  modal: EditableModal,
  newsletter_form: EditableNewsletterForm,
  news_item: NewsItem,
  news_page: NewsPage,
  on_hand_stock: EditableOnHandStock,
  on_hand_stock_table: EditableOnHandStockTable,
  page_intro: EditablePageIntro,
  page: Page,
  pattern_page: EditablePatternPage,
  portfolio: EditablePortfolio,
  project_categories_table: ProjectCategoriesTable,
  project_map: EditableProjectMap,
  project_search: ProjectSearch,
  project: Project,
  river_flow: EditableRiverFlow,
  sample: EditableSample,
  samples_page: EditableSamplesPage,
  section_heading: EditableSectionHeading,
  SEO: EditableSEO,
  shopify_collection: ShopifyCollection,
  shopify_product: ShopifyProduct,
  single_project: EditableSingleProject,
  single_sample: EditableSingleSample,
  single_stock_item: EditableSingleStockItem,
  site_map: EditableSiteMap,
  social: EditableSocial,
  stock_item_search: StockItemSearch,
  specification_block: EditableSpecificationBlock,
  specifier: SpecifierMaterials,
  specifier_lights: SpecifierLights,
  text: EditableText,
  thickness_comparison: EditableThicknessComparison,
  thickness_slice: EditableThicknessSlice,
  thumbnail_group: EditableThumbnailGroup,
  thumbnail: EditableThumbnail,
  issuu_publication: EditableIssuuPublication,
  table: EditableTable,
  video_player: EditableVideoPlayer,
}

export {
  Banner,
  Breadcrumbs,
  Button,
  Board,
  BoardToggle,
  Card,
  CardSlim,
  CardGroup,
  Carousel,
  CarouselItem,
  CarouselGrid,
  Cart,
  CartToggle,
  CategoryTable,
  Checkboxes,
  Certifications,
  ColorPage,
  ConversationalForm,
  Cta,
  CtaGroup,
  Container,
  EditableForm,
  ErrorBoundary,
  FacetSearch,
  FacetSearchFilters,
  FileDownload,
  FileField,
  Footer,
  FooterContainer,
  Form,
  FormRow,
  Grid,
  GridItem,
  Hardware,
  HardwareGrid,
  Header,
  HeaderContainer,
  Hero,
  Icon,
  Image,
  ImageComposition,
  ImageCompositionItem,
  InstagramFeed,
  InlineRiverFlow,
  InspirationBoardPage,
  IssuuPublication,
  KeyValue,
  KeyValues,
  Layout,
  Legend,
  Lighting,
  LightingColumn,
  Link,
  LongText,
  Modal,
  Nav,
  NavItem,
  NewsletterForm,
  NewsItem,
  NewsPage,
  OnHandStock,
  OnHandStockTable,
  Page,
  PageIntro,
  PatternPage,
  Phone,
  Portfolio,
  PortfolioItem,
  ProjectSearch,
  ProjectMap,
  RadioButtons,
  RiverFlow,
  SamplesPage,
  Search,
  SectionHeading,
  Select,
  SEO,
  Share,
  ShopifyCollection,
  ShopifyProduct,
  ShortText,
  SingleProject,
  SingleStockItem,
  SiteMap,
  Social,
  SpecifierMaterials,
  SpecifierLights,
  StockItemForm,
  StockItemSearch,
  SubmitButton,
  Table,
  Text,
  ThicknessComparison,
  ThicknessSlice,
  Thumbnail,
  ThumbnailGroup,
  Toolbar,
  VideoPlayer,
}

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import OutsideClickHandler from 'react-outside-click-handler'
import CloseOnEscape from 'react-close-on-escape'
import { Legend } from 'components'
import { renderBlok } from 'utils'

class ImageWithLegend extends React.Component {
  state = { open: false }

  render() {
    const { image, legend } = this.props
    return (
      <div className="image-legend" style={{position: 'relative'}}>
        {image}
        {legend && (
          <ul className="legend">
            {legend.map(({ title, description, x, y }, index) => (
              <Legend key={index} x={x} y={y} title={title} description={description} />
            ))}
          </ul>
        )}
      </div>
    )
  }
}

export const EditableImageWithLegend = ({ blok }) => (
  <SbEditable content={blok}>
    <ImageWithLegend image={blok.image.length && renderBlok(blok.image[0])} legend={blok.legend} />
  </SbEditable>
)

export default ImageWithLegend

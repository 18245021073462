import React, { Component } from 'react'

class ErrorBoundary extends Component {

  state = {
    error: null,
    errorInfo: null
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="alert alert-danger text-center">
          <h2>Sorry</h2>
          <p>Something went wrong while rendering this {this.props.blok && <span className="badge badge-dark">{this.props.blok.component}</span>} component.</p>
        </div>
      );
    }
    return this.props.children;
  }

}

export default ErrorBoundary
import React, { useContext, useMemo } from 'react'
import ShopifyContext from '../../contexts/Shopify/shopifyContext'
import AddToCartButton from './add-to-cart-button'
import { Price } from '../price/Price'

const ProductItem = (props) => {
  const { title, image, priceV2, require_tc } = props
  const { client } = useContext(ShopifyContext)
  const src = useMemo(() => {
    if (!image) { return }
    return image.src && client.image.helpers.imageForSize(image, { maxWidth: 350, maxHeight: 350 })
  }, [image])
  return (
    <figure>
      <img
        className="img-fluid"
        src={src}
        alt={title}
      />
      <figcaption>
        <h3>{title}</h3>
        <div>
          <Price amount={priceV2.amount} />
        </div>
        <AddToCartButton require_tc={require_tc} variant={props} />
      </figcaption>
    </figure>
  )
}
export default ProductItem

import React, { Fragment } from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import Slider from 'react-slick'
import CloseOnEscape from 'react-close-on-escape'
import { renderBlok, renderBloks } from 'utils'
import { Icon, Thumbnail, Link } from 'components'

export const RiverFlow = ({
  minHeight,
  backgroundColor,
  whiteText,
  side,
  verticalSide,
  fullBleed,
  images,
  thumbnails,
  content,
  textAlign,
  seeMoreLink,
  showArrows = false,
  showThumbnails,
  currentSlide,
  onChangeSlide,
  mask,
}) => (
  <div className="river-flow" style={{ minHeight: `${minHeight}px`, backgroundColor: `${backgroundColor}` }}>
    <div
      className={cn('river-flow__container', `river-flow__container--${side}`, {
        'river-flow__container--white': whiteText,
      })}
    >
      <div
        className={cn(
          'river-flow__images-container',
          `river-flow__images-container--${verticalSide}`,
          { [`river-flow__images-container--full-bleed`]: fullBleed },
          `river-flow__images-container--${side}`
        )}
      >
        {mask && <RiverFlowMask color={backgroundColor} />}
        {images.length > 1 ? (
          <RiverFlowCarousel
            thumbnails={thumbnails}
            link={seeMoreLink}
            showArrows={showArrows}
            showThumbnails={showThumbnails}
            currentSlide={currentSlide}
            onChangeSlide={onChangeSlide}
          >
            {images}
          </RiverFlowCarousel>
        ) : (
          images
        )}
      </div>
      <div className={cn('river-flow__text-ctas', `river-flow__text-ctas--${textAlign ? textAlign : 'center'}`)}>
        {content}
      </div>
    </div>
  </div>
)

export class RiverFlowCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.changeSlide = this.changeSlide.bind(this)
    this.state = {
      slider: null,
      currentSlide: 0,
    }
  }

  componentDidMount() {
    this.setState({
      slider: this.slider,
      currentSlide: this.props.currentSlide || 0,
    })
  }

  componentWillReceiveProps(newProps) {
    newProps.currentSlide && this.changeSlide(newProps.currentSlide)
  }

  changeSlide(slide) {
    this.setState({
      currentSlide: slide,
    })
    this.slider.slickGoTo(slide)
  }

  render() {
    const { thumbnails, link, children, showArrows, showThumbnails, onChangeSlide } = this.props
    const slickSettings = {
      dots: false,
      arrows: showArrows,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <Fragment>
        <Slider
          className="river-flow-carousel"
          ref={slider => (this.slider = slider)}
          beforeChange={(oldIndex, newIndex) => this.setState({ currentSlide: newIndex })}
          afterChange={currentSlide => onChangeSlide && onChangeSlide(currentSlide)}
          {...slickSettings}
        >
          {children}
        </Slider>
        {showThumbnails && (
          <RiverFlowCarouselControls
            thumbnails={thumbnails}
            link={link}
            currentSlide={this.state.currentSlide}
            onNavClick={this.changeSlide}
          />
        )}
      </Fragment>
    )
  }
}

export const EditableRiverFlow = ({ blok }) => (
  <SbEditable content={blok}>
    <RiverFlow
      minHeight={blok.min_height}
      backgroundColor={blok.background_color}
      side={blok.side}
      whiteText={blok.white_text}
      verticalSide={blok.bottom ? 'bottom' : 'top'}
      fullBleed={blok.full_bleed && blok.images.length === 1}
      textAlign={blok.text_align}
      content={renderBloks(blok.body)}
      images={blok.images.map(image => renderBlok(image))}
      thumbnails={blok.images}
      seeMoreLink={blok.link}
      showThumbnails={true}
      mask={blok.mask}
    />
  </SbEditable>
)

const RiverFlowCarouselControls = ({ thumbnails, link, currentSlide, onNavClick }) => (
  <div className="river-flow-carousel__controls">
    {thumbnails.length > 1 &&
      thumbnails.map((image, index) => (
        <a
          key={index}
          className={cn('river-flow__icon', { 'river-flow__icon--active': currentSlide === index })}
          onClick={() => onNavClick(index)}
        >
          <Thumbnail image={image.image} label={image.text} mobileLabel={image.text} inline={false} />
        </a>
      ))}
    {link && link.length > 0 && (
      <Link to={link[0].url.cached_url} className="river-flow__icon">
        <Thumbnail icon={<Icon name="plus" />} label={'See All'} mobileLabel={'See All'} inline={false} />
      </Link>
    )}
  </div>
)

export class InlineRiverFlow extends React.Component {
  state = { open: false, offset: 0 }
  riverFlow = React.createRef()

  open = e => {
    e && e.preventDefault()
    this.setState({ open: true }, () => this.setLeft())
  }

  close = () => {
    this.setState({ open: false })
  }

  setLeft = () => {
    window.requestAnimationFrame(
      () =>
        this.riverFlow.current &&
        this.setState(
          {
            offset: 0,
          },
          () =>
            this.setState({
              offset: this.riverFlow.current.getBoundingClientRect().left,
            })
        )
    )
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.setLeft)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setLeft)
  }

  render() {
    const { content } = this.props

    return (
      <CloseOnEscape onEscape={() => this.close()}>
        {this.state.open && (
          <div
            ref={this.riverFlow}
            className="inline-river-flow__container"
            style={{ marginLeft: `-${this.state.offset}px` }}
          >
            <div className={cn('inline-river-flow')}>
              <div className="inline-river-flow__close" onClick={() => this.close()}>
                <span className="inline-river-flow__close-text">Close</span>
              </div>
              {content}
            </div>
          </div>
        )}
      </CloseOnEscape>
    )
  }
}

const RiverFlowMask = ({ color = 'white' }) => (
  <svg className="river-flow__image-mask" viewBox="0 0 95 560">
    <polygon
      id="Triangle"
      stroke={color ? color : 'white'}
      strokeWidth="2"
      fill={color ? color : 'white'}
      transform="translate(48.500000, 281.078125) scale(1, -1) translate(-48.500000, -281.078125) "
      points="96 1.078125 96 561.078125 1 561.078125"
    />
  </svg>
)

export default RiverFlow

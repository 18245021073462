import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { flattenDataSources } from '../../utils'
import { Grid, FacetSearch, SingleProject } from 'components'

const facets = [
  { key: 'application', label: 'Application' },
  { key: 'industry', label: 'Industry' },
  { key: 'material', label: 'Material' },
  { key: 'patternFamily', label: 'Family' },
  { key: 'environment', label: 'Environment' },
  { key: 'materialProperty', label: 'Material Property' },
]

export const ProjectSearch = ({ datasources }) => (
  <FacetSearch
    indexName="Projects"
    facets={facets}
    datasources={flattenDataSources(datasources)}
    renderHits={(hits) =>
      hits.length > 0 ? (
        <Grid columns={2} addClass="projects-search__grid">
          {hits.map((project) => (
            <SingleProject
              key={project.objectID}
              title={project.title}
              image={project.thumbnail}
              description={project.summary}
              url={`${project.slug}`}
              uuid={project.objectID}
            />
          ))}
        </Grid>
      ) : (
        <div className="container">
          <p>No results were found for your search</p>
        </div>
      )
    }
  />
)

export default (props) => {
  const { datasources } = useStaticQuery(graphql`
    query {
      datasources: allDatasourcesJson {
        edges {
          node {
            name
            value
            data_source
          }
        }
      }
    }
  `)

  return <ProjectSearch datasources={datasources} {...props} />
}

import React, { useMemo } from 'react'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { Link, Icon } from 'components'

export const splitPath = path => {
  return path.pathname
    .split('/')
    .filter((part) => part)
    .map((part, index, parts) => {
      const name = part
      let url = '/'
      for (let i = 0; i <= index; i++) {
        url = url + `${parts[i]}/`
      }
      return {
        name: startCase(camelCase(name)),
        url: url,
      }
    })
}

const Breadcrumbs = ({location}) => {
  const pathParts = useMemo(() => splitPath(location), [location])
  return (
    <div className="breadcrumbs">
      <Breadcrumb text="Home" link="/" last={pathParts.length === 0} />
      {pathParts.map((pathPart, index) => (
        <Breadcrumb key={index} text={pathPart.name} link={pathPart.url} last={(pathParts.length - 1) === index} />
      ))}
    </div>
  )
}

export const Breadcrumb = ({ text, link, last }) => (
  <div className="breadcrumb">
    <Link className="breadcrumb__link" to={link}>{text}</Link>
    {!last && <Icon className="breadcrumb__icon" name="arrow-right" />}
  </div>
)

export default Breadcrumbs

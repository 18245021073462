import React from 'react'
import 'whatwg-fetch'
import { Layout } from 'components'
import { ShopifyProvider } from './src/contexts/Shopify/shopifyContext'
import { InspirationBoardProvider } from './src/contexts/InspirationBoard/inspirationBoardContext'


export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  const location = element.props.location.href
  if (location.match(/editor.*globals/)) {
    // When editing global blocks (header, footer, etc.) wrapping in a layout duplicates them on the page, we just use the needed context providers
    return (
      <InspirationBoardProvider>
        <ShopifyProvider>
          {element}
        </ShopifyProvider>
      </InspirationBoardProvider>)
    return element
  } else {
    return <Layout {...props}>{element}</Layout>
  }
}

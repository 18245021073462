import React, { Fragment, useContext, useEffect, useState } from 'react'
import ShopifyContext from '../../contexts/Shopify/shopifyContext'

import CollectionItem from './collection-item'
import { renderBloks } from 'utils'

const ShopifyCollection = ({ blok }) => {

  const [products, setProducts] = useState((blok.collection && blok.collection.products) || [])
  const { client } = useContext(ShopifyContext)

  useEffect(() => {
    const collectionId = blok.collection && blok.collection.id || window.btoa(`gid://shopify/Collection/${blok.collection_id}`)
    const fetchCollection = async () => {
      const res = await client.collection.fetchWithProducts(collectionId).catch(console.error)
      if (!res) { return }
      const { products: fetchedProducts } = res
      //exclude tags
      const productsWithOldTags = fetchedProducts.map(product => {
        const { tags } = products.find(prod => prod.id === product.id) || product.tags
        return {
          ...product,
          tags
        }
      })
      setProducts(productsWithOldTags)
    }
    fetchCollection()
  },[blok])

  return (
    <Fragment>
      {blok.body && renderBloks(blok.body)}
      {products.map((product, index) => (
        <CollectionItem client={client} key={product.id} index={index} product={product} />
      ))}
    </Fragment>
  )

}

export default ShopifyCollection

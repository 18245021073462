import React from 'react'
import SbEditable from '../../utils/sb-editable'

const GATSBY_ISSUU_ACCOUNT = process.env.GATSBY_ISSUU_ACCOUNT

const IssuuPublication = ({ publicationSlug }) => (
  <div className="issuu-publication__container">
    <iframe
      className="issuuembed"
      style={{border:'none'}}
      src={`//e.issuu.com/embed.html?d=${publicationSlug}&u=${GATSBY_ISSUU_ACCOUNT}`}
      allowFullScreen
    />
  </div>
)

export const EditableIssuuPublication = ({ blok }) => (
  <SbEditable content={blok}>
    <IssuuPublication publicationSlug={blok.publication_slug} />
  </SbEditable>
)

export default IssuuPublication

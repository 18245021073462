import React from 'react'
import SbEditable from '../../utils/sb-editable'
import { GridItem, Text } from 'components'

const SingleProject = ({ image, title, description, url, forceClose, uuid }) => {
  const descriptionToText = () => <Text>{description}</Text>
  return <GridItem className="single-project" title={title} text={descriptionToText()} image={image} forceClose={forceClose} link={url} type="project" />
}

export const SingleProjectContainer = ({ link, title, thumbnail, description, project, render, uuid }) => {
  const image = <img src={thumbnail} />
  return render ? (
    render({ image, title: title, description, project, link })
  ) : (
      <SingleProject
        image={thumbnail}
        title={title}
        description={description}
        url={link}
      />
    )
}

export const EditableSingleProject = ({ blok, forceClose, render }) => (
  <SbEditable content={blok}>
    <SingleProjectContainer
      link={blok.project.full_slug}
      title={blok.project.title}
      thumbnail={blok.image_override ? blok.image_override : blok.project.thumbnail}
      project={blok.project}
      description={blok.project.description}
      render={render}
      forceClose={forceClose}
    />
  </SbEditable>
)

export default SingleProject

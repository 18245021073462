import React from 'react'
import cn from 'classnames'
import { Link } from 'components'
import ConditionalWrap from 'conditional-wrap'

const Portfolio = ({ items, fullBleed = false, className }) => {
  const [mainItem, topLeftItem, topRightItem, bottomRightItem, bottomLeftItem] = items || []

  return (
    <ConditionalWrap
      condition={!fullBleed}
      wrap={children => (
        <div className={cn('portfolio__container', className)}>
          {children}
        </div>
      )}
    >
      <div className="portfolio__sizer">
        <div className="portfolio">
          {mainItem}
          {!topRightItem && topLeftItem}

          {topRightItem && (
            <div className="portfolio__square">
              <div className="portfolio__row">
                {topLeftItem}
                {bottomLeftItem && topRightItem}
              </div>
              <div className="portfolio__row">
                {!bottomLeftItem && topRightItem}
                {bottomLeftItem}
                {bottomRightItem}
              </div>
            </div>
          )}
        </div>
      </div>
    </ConditionalWrap>
  )
}

export const EditablePortfolio = ({ blok }) => {
  const projectImage = project => {
    const src = project.image_override ? project.image_override : project.project.thumbnail
    return <img src={src} />
  }
  const items = () => {
    let portfolioItems = blok.projects.map(projectBlok => (
      <PortfolioItem link={projectBlok.project.full_slug} image={projectImage(projectBlok)} />
    ))
    portfolioItems.push(
      <Link className="portfolio__square" to={blok.link.cached_url}>
        <h2 className="portfolio__title">{blok.title}</h2>
        <h5 className="portfolio__title">{blok.subtitle}</h5>
      </Link>
    )

    return portfolioItems
  }
  return <Portfolio items={items()} fullBleed={blok.full_bleed}  />
}

export const PortfolioItem = ({ link, image, name }) => (
  <Link className="portfolio__square" to={link}>
    <div className="portfolio__background">{image}</div>
    <div className="portfolio__name">{name}</div>
  </Link>
)

export default Portfolio

import React, { useState } from 'react'
import SbEditable from '../../utils/sb-editable'
import SlickSlider from 'react-slick'
import cn from 'classnames'
import { Text, VideoPlayer } from 'components'
import { renderBloks, renderBlok } from 'utils'

const slickSettings = {
  slidesToShow: 1,
  arrows: true,
  dots: true,
}

export const CarouselItem = ({ background, children }) => (
  <div className={'carousel-item'}>
    <div className="carousel-item__background">{background}</div>
    <div className="carousel-item__content">
      <div className="carousel-item__content-inner">{children}</div>
    </div>
  </div>
)

export const EditableCarouselItem = ({ blok }) => (
  <CarouselItem background={blok.background && renderBlok(blok.background[0])}>
    {blok.body && <Text>{blok.body}</Text>}
    {blok.ctas && renderBloks(blok.ctas)}
  </CarouselItem>
)

const Carousel = ({ items, infinite, autoplay, animation }) => {

  const [slideNumber, setSlideNumber] = useState(0)
  const [videoPlaying, setVideoPlaying] = useState(false)

  let configurableSlickSettings = {
    infinite: infinite ? infinite : false,
    autoplay: autoplay ? autoplay : false,
    fade: animation && animation === 'fade'
  }

  const onVideoPlay = () => {
    setVideoPlaying(true)
  }

  const onVideoPause = () => {
    setVideoPlaying(false)
  }

  const renderChild = (item, index) => {

    if (!item.background || !item.background.length) {
      return <div />
    }

    if (item.background[0].component === 'video_player') {
      return (
        <CarouselItem
          key={index}
          background={
            <VideoPlayer
              video={`${item.background[0].video_url}`}
              autoplay={item.background[0].autoplay && slideNumber === index}
              hideControls={item.background[0].show_controls}
              repeat={item.background[0].repeat}
              startTime={item.background[0].start_time ? item.background[0].start_time : '0'}
              onPlay={onVideoPlay}
              onPause={onVideoPause}
            />
          }
        >
          <div className={cn('carousel-item__video-content', { hidden: videoPlaying })}>
            {item.body && <Text>{item.body}</Text>}
            {item.ctas && renderBloks(item.ctas)}
          </div>
        </CarouselItem>
      )
    }

    if (item.background[0].component === 'image' || item.background[0].component === 'image_with_legend') {
      return renderBlok(item, index)
    }

  }

  return (
    <div className={'carousel'}>
      <SlickSlider {...slickSettings} {...configurableSlickSettings} afterChange={setSlideNumber}>
        {items.map((item, index) => (renderChild(item, index)))}
      </SlickSlider>
    </div>
  )

}

export const EditableCarousel = ({ blok }) => (
  <SbEditable content={blok}>
    {blok.items && (
      <Carousel
        items={blok.items}
        infinite={blok.infinite}
        autoplay={blok.autoplay}
        animation={blok.animation}
      />
    )}
  </SbEditable>
)

export default Carousel

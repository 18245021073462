import specifier from './global'
import scintilla from './scintilla'
import jali from './jali'
import slant from './slant'
import lumina from './lumina'
import celeste from './celeste'
import infinity from './infinity'
import spark from './spark'
import vapor from './vapor'
import pixa from './pixa'
import terrazzo from './terrazzo'
import terrazzolumina from './terrazzo-lumina'
import ripplemotif from './ripple-motif'


export default {
  specifier,
  scintilla,
  jali,
  slant,
  lumina,
  celeste,
  infinity,
  spark,
  vapor,
  pixa,
  terrazzo,
  'terrazzo-lumina': terrazzolumina,
  'ripple-motif': ripplemotif
}

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import { renderBloks } from 'utils'

const cardSeparators = cards => {
  return cards.length && cards[0].image_position === 'below_title' ? 'dark' : 'light'
}

const CardGroup = ({ separatorType, children }) => (
  <div className={`card-group card-group--${separatorType}`}>
    <div className="card-group__cards">{children}</div>
  </div>
)

export const EditableCardGroup = ({ blok }) => (
  <SbEditable content={blok}>
    {blok.cards.length > 0 && <CardGroup separatorType={cardSeparators(blok.cards)}>{renderBloks(blok.cards)}</CardGroup>}
  </SbEditable>
)

export default CardGroup

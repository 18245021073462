import React, { Fragment } from 'react'
import cn from 'classnames'
import SbEditable from '../../utils/sb-editable'
import { Icon } from 'components'

const OnHandStockTable = ({ title, header = [], body = [] }) => (
  <Fragment>
    <h3 className="table__title">{title}</h3>
    <div className="table__container">
      <table className="on-hand-stock-table">
        <thead className="on-hand-stock-table__header">
          <tr className="on-hand-stock-table__header-row">
            {header.map((cell, index) => (
              <th key={index} className={cn('table__cell')}>
                <div className="table__cell-contents">
                  {cell}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="on-hand-stock-table__body">
          {body.map((row, index) => (
            <tr key={index} className="table__body-row">
              {row.map((cell, index) => (
                <td key={index} className="table__cell">
                  <div className="table__cell-contents">
                    {cell}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Fragment>
)

export const EditableOnHandStockTable = ({ blok }) => (
  <SbEditable content={blok}>
    <OnHandStockTable title={blok.title} header={blok.table.thead} body={blok.table.tbody} footer={blok.footer} />
  </SbEditable>
)

export default OnHandStockTable

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import ReactModal from 'react-modal'
import { renderBloks } from 'utils'

class Modal extends React.Component {
  state = {
    open: false,
  }
  open = e => {
    e && e.preventDefault()
    this.setState({ open: true })
  }

  close = () => {
    this.setState({ open: false })
  }

  render() {
    const { title, children, fullScreen, className, onAfterOpen } = this.props
    const { open } = this.state
    return (
      <ReactModal
        isOpen={open}
        className={cn('modal', { 'modal--open': open }, { 'modal--full-screen': fullScreen }, className)}
        portalClassName="modal__container"
        // not editable option
        onAfterOpen={onAfterOpen}
      >
        <OutsideClickHandler onOutsideClick={() => this.close()}>
          <div className="modal__contents">
            <div className="modal__header">
              <h2>{title}</h2>
              <div className="modal__close" onClick={() => this.close()}>
                <span className="modal__close-text">Close</span>
              </div>
            </div>
            <div className="modal__body">{children}</div>
          </div>
        </OutsideClickHandler>
      </ReactModal>
    )
  }
}

export const EditableModal = ({ blok }) => (
  <SbEditable content={blok}>
    <Modal title={blok.title} fullScreen={blok.full_screen} className={blok.classname}>{renderBloks(blok.body)}</Modal>
  </SbEditable>
)

// Can't select element in tests
process.env.NODE_ENV !== 'test' && ReactModal.setAppElement('#___gatsby')

export default Modal

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import { renderBloks, formatSrc } from 'utils'

export const ThumbnailGroup = ({ children }) => (
  <div className="thumbnail-group">{children}</div>
)
export const EditableThumbnailGroup = ({ blok }) => (
  <SbEditable content={blok}>
    <ThumbnailGroup>
      {renderBloks(blok.thumbnails)}
    </ThumbnailGroup>
  </SbEditable>
)

const Thumbnail = ({ image, icon, label, mobileLabel, inline }) => (
  <div className={cn("thumbnail", { 'thumbnail--inline': inline })}>
    {image && (
      <div className="thumbnail__mask">
        <img src={formatSrc({ src: image, size: '200x200' })} alt={label} />
      </div>
    )}
    {icon && !inline && (
      <div className="thumbnail__mask thumbnail__mask--icon">
        <span className="thumbnail__icon">
          {icon}
        </span>
      </div>
    )}
    {icon && inline && (
      <span className="thumbnail__icon">
        {icon}
      </span>
    )}
    {!!label && <>
      <span className="thumbnail__label">{label}</span>
      <span className="thumbnail__label--mobile">{mobileLabel ? mobileLabel : label.split(' ')[0]}</span>
    </>
    }
  </div>
)

export const EditableThumbnail = ({ blok }) => (
  <SbEditable content={blok}>
    <Thumbnail image={blok.image} icon={renderBloks(blok.icon)} label={blok.label} mobileLabel={blok.mobileLabel} inline={blok.inline} />
  </SbEditable>
)

export default Thumbnail;

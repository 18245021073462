import React, { Component, Fragment } from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import { Portfolio, Modal } from 'components'
import { renderBlok } from 'utils'

const ImageComposition = ({ items, fullBleed = true, title }) => (
  <Fragment>
    <Portfolio className={cn('image-composition', { 'full-width': fullBleed })} items={items} />
  </Fragment>
)

export const EditableImageComposition = ({ blok }) => (
  <SbEditable content={blok}>
    <ImageComposition items={blok.items && blok.items.map(item => renderBlok(item))} fullBleed={blok.full_bleed} />
  </SbEditable>
)

export class ImageCompositionItem extends Component {
  childComponent = React.createRef()

  openModal = () => {
    this.childComponent.current && this.childComponent.current.open()
  }

  render() {
    const { content, modal, isVideo, title, hasModal } = this.props
    return (
      <Fragment>
        <div className="portfolio__square image-composition__square" onClick={this.openModal}>
          <div className="portfolio__background">{content}</div>
          <div className="portfolio__name">{title}</div>
          {isVideo && <div className="image-composition__video-overlay" />}
        </div>
        {hasModal && <Modal ref={this.childComponent} title={modal.title} fullScreen={modal.fullScreen}>
          {modal.content}
        </Modal>}
      </Fragment>
    )
  }
}

export const EditableImageCompositionItem = ({ blok }) => {
  const itemModal = () => ({
    title: blok.title,
    content: blok.content.length && renderBlok(blok.content[0]),
    fullScreen: blok.full_screen,
  })

  return (
    <SbEditable content={blok}>
      <ImageCompositionItem
        content={renderBlok(blok.content.length && blok.content[0])}
        modal={itemModal()}
        isVideo={blok.content.length && blok.content[0].component == 'video_player'}
        title={blok.title}
        hasModal={!blok.no_modal}
      />
    </SbEditable>
  )
}

export default ImageComposition

import React, { Component, useEffect, useMemo, useRef } from 'react'
import { navigate } from 'gatsby'
import SbEditable from '../../utils/sb-editable'
import algoliasearch from 'algoliasearch'
import { InstantSearch, connectHits, Configure } from 'react-instantsearch-dom'
import cn from 'classnames'
import GoogleMapReact from 'google-map-react'
import uniqBy from 'lodash/uniqBy'
import { Icon } from 'components'
import { titleCase } from 'utils'
import mapStyle from './project-map-style'
import markerIcon from './map-marker.svg'
import highlightedIcon from './map-marker-highlight.svg'

const mapConfig = {
  styles: mapStyle,
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: 'auto',
}

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY, { _useRequestCache: true })

export class ProjectMapContainer extends Component {
  state = { searchedLocations: [], zoom: 3, selectedLocationSlug: '' }

  mapRef = React.createRef()

  showLocation = location => {
    this.setState({ selectedLocationSlug: location.slug })
    this.panToLocation(location)
  }

  panToLocation = location => {
    // - 1 to get the marker just a little bit higher and over the title
    this.mapRef.current.map_.panTo({ lat: location.latitude - 1, lng: location.longitude })
    setTimeout(() => this.mapRef.current.map_.setZoom(7), 500)
  }

  render() {
    const { customLocations } = this.props
    const { selectedLocationSlug } = this.state
    return (
      <div className="map-container">
        <InstantSearch searchClient={searchClient} indexName="Projects">
          <Configure hitsPerPage={1000} />
          <LocationsSearcher
            renderLocations={locations => {
              const combinedLocations = locations.concat(customLocations)
              return (
                <div className="map-wrapper">
                  <GoogleMapReact
                    ref={this.mapRef}
                    bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_API_KEY }}
                    defaultCenter={{ lat: 44, lng: -25 }}
                    defaultZoom={1}
                    options={mapConfig}
                    // some sort of units used by Google Maps, smaller is closer to marker
                    hoverDistance={10}
                  >
                    {combinedLocations.map(
                      (location, index) =>
                        location.latitude &&
                        location.longitude && (
                          <Marker
                            key={index}
                            title={location.label}
                            lat={location.latitude}
                            lng={location.longitude}
                            slug={location.slug}
                            isSelected={location.slug === selectedLocationSlug}
                            text={location.project ? location.project : location.name}
                          />
                        )
                    )}
                  </GoogleMapReact>
                  <MapSearch locations={combinedLocations} showLocation={this.showLocation} />
                </div>
              )
            }}
          />
          <div className="project-map-title">
            <h1>Portfolio</h1>
          </div>
        </InstantSearch>
      </div>
    )
  }
}

const Marker = ({ lat, lng, text, slug, isSelected, $hover }) => (
  <div className={cn('map-marker', { active: isSelected || $hover })}>
    <img src={isSelected || $hover ? highlightedIcon : markerIcon} onClick={() => navigate(slug)} />
    {(isSelected || $hover) && <span className="map-marker__label">{text}</span>}
  </div>
)

const LocationsSearcher = connectHits(({ hits, renderLocations }) => (
  <div>
    {renderLocations(
      hits.map(hit => ({
        latitude: hit.location.latitude,
        longitude: hit.location.longitude,
        name: hit.location.name,
        slug: `/${hit.slug}`,
        project: hit.title,
      }))
    )}
  </div>
))


export const MapSearch = ({ locations, showLocation }) => {

  const select = useRef()

  const uniqueSortedLocations = useMemo(() => {
    return uniqBy([...locations].filter(location => !!location.name && location.latitude),(location => location.name)).sort((a,b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) { return -1 }
      if (nameA > nameB) { return 1}
      return 0;
    })
  },[locations])

  const handleSelect = () => {
    showLocation(uniqueSortedLocations[select.current.selectedIndex])
  }

  return (
    <div className="map-search">
      <div className="form__select-wrapper form minimal">
        <select className="form__input form__select" ref={select} onChange={handleSelect}>
          {uniqueSortedLocations.map((location,index) => (
            <option key={index} value={index}>{location.name}</option>
          ))}
        </select>
        <Icon name="arrow-down" />
      </div>
    </div>
  )

}

export const EditableProjectMap = ({ blok }) => {
  const getLocation = point => ({
    latitude: parseFloat(point.address.latitude),
    longitude: parseFloat(point.address.longitude),
    name: titleCase(point.address.search),
    slug: point.project.full_slug,
  })
  return (
    <SbEditable content={blok}>
      <ProjectMapContainer customLocations={blok.points.map(point => getLocation(point))} />
    </SbEditable>
  )
}

export default ProjectMapContainer

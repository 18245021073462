import React from 'react'
import SbEditable from '../../utils/sb-editable'
import { renderBloks } from 'utils'

const Container = ({ size, children }) => (
  <div className="container">
    <div className="row justify-content-center">
      <div className={`col-md-${Math.max(size,8)}`}>
        {children}
      </div>
    </div>
  </div>
)

export const EditableContainer = ({ blok }) => (
  <SbEditable content={blok}>
    <Container size={blok.size}>
      {renderBloks(blok.body)}
    </Container>
  </SbEditable>
)

export default Container

import React, { Component } from 'react'
import SbEditable from '../../utils/sb-editable'
import { snakeCase } from 'lodash'
import cn from 'classnames'
import { renderBloks } from 'utils'

export const checkValidity = (input, required, validation = '.*') => {
  if (!required) return true
  if (input === '') return false
  const regex = new RegExp(validation)
  return regex.test(input)
}

class FormField extends Component {
  renderField = () => (
    console.log('renderField must be overridden')
  )

  isValid = () => {
    return this.state.valid
  }

  componentDidMount = () => {
    const { registerField } = this.props
    registerField && registerField(this)
  }

  componentWillUnmount = () => {
    const { deregisterField } = this.props
    deregisterField && deregisterField(this)
  }

  render() {
    const { label, errormessage, helpText } = this.props
    const { valid } = this.state
    return (
      <div className="form__field">
        {label}
        {this.renderField()}
        {!valid && <small className="form__error-text">{errormessage}</small>}
        {helpText && <small className="text-muted form__help-text">{helpText}</small>}
      </div>
    )
  }
}

export class FileField extends FormField {
  state = { filename: null, uploaded: false, valid: true }
  fileInput = React.createRef()

  validate = () => {
    const { required } = this.props
    const { uploaded } = this.state

    this.setState({ valid: required ? uploaded : true })
  }

  clearUploadedFile = e => {
    const { required } = this.props
    e.preventDefault()
    this.fileInput.current.value = ''
    this.setState({ filename: null, uploaded: false }, () => this.validate())
  }

  onFileChange = e => {
    e.preventDefault()
    if (this.fileInput.current.files.length == 0) return
    const filename = this.fileInput.current.files[0].name

    this.setState({ filename: filename, uploaded: this.fileInput.current.files.length > 0 }, () => this.validate())
  }

  clickRealButton = () => {
    this.fileInput.current.click()
  }

  renderField = () => {
    const { name, buttonText, message, required, question, errormessage, conversationalProps } = this.props
    const { filename, uploaded, valid } = this.state
    return (
      <div className="form__file">
        <fieldset cf-questions={question}>
          <input
            ref={this.fileInput}
            className={cn('form__file-upload form__input', { invalid: !valid }, { required: required })}
            type="file"
            name={name}
            onChange={e => this.onFileChange(e)}
            required={required}
            cf-error={errormessage}
            {...conversationalProps}
          />
          <button type="button" onClick={this.clickRealButton} className="form__file-button">
            {buttonText}
          </button>
          <span className="form__file-info">{filename ? filename : message}</span>
          {uploaded && (
            <span className="form__file-clear">
              <a href="#" onClick={e => this.clearUploadedFile(e)}>
                clear
              </a>
            </span>
          )}
        </fieldset>
      </div>
    )
  }
}

export const SubmitButton = ({ label, style, inverse }) => (
  <input
    className={cn('form__submit', 'cta', `cta--${style}`, { 'cta--inverse': inverse })}
    type="submit"
    value={label}
  />
)

export const EditableFile = ({ blok, registerField, deregisterField, isConversational, conversationalProps }) => (
  <SbEditable content={blok}>
    <FileField
      name={snakeCase(blok.name)}
      label={renderBloks(blok.label)}
      question={blok.question}
      helpText={blok.help_text}
      buttonText={blok.button_text ? blok.button_text : 'Upload a file'}
      message={blok.message ? blok.message : 'No file chosen'}
      required={blok.required}
      errormessage={blok.error_message ? blok.error_message : 'This field is required.'}
      registerField={registerField}
      deregisterField={deregisterField}
      isConversational={isConversational}
      conversationalProps={conversationalProps}
    />
  </SbEditable>
)

export const EditableSubmitButton = ({ blok, context }) => (
  <SbEditable content={blok}>
    <SubmitButton label={blok.label ? blok.label : 'Submit'} style={blok.style} inverse={blok.inverse} />
  </SbEditable>
)

export default FormField

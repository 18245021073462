import React, { Fragment } from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import { Icon, RadioButtons, Checkboxes } from 'components'
import { renderBlok, renderBloks, parseBlok } from 'utils'
import { checkValidity } from './form-fields'
import FormField from './form-fields'
export class Select extends FormField {
  state = { selected: '', valid: true }

  updateSelected = value => {
    this.setState({ selected: value }, () => this.validate())
  }

  validate = () => {
    const { required } = this.props
    const { selected } = this.state
    this.setState({ valid: checkValidity(selected, required) })
  }

  renderField = () => {
    const { options, defaultText, required, name, chatQuestion, isConversational, errormessage, conversationalProps } = this.props
    const { selected, valid } = this.state
    return (
      <Fragment>
        <div className="form__select-wrapper">
          <select
            onChange={e => this.updateSelected(e.target.value)}
            className={cn('form__input', 'form__select', { invalid: !valid }, { required: required })}
            name={name}
            cf-questions={chatQuestion}
            required={required}
            cf-error={errormessage}
            {...conversationalProps}
          >
            <option>{defaultText}</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <Icon name="arrow-down" />
        </div>
        {options.map(option => (
          isConversational ? (
            option.subfields?.map((field, index) => (
              <field.component {...field.props} key={index} conversationalProps={{ [`cf-conditional-${name}`]: option.value }} />
            ))
          ) : option.value === selected && (
            option.subfields?.map((field, index) => {
              <field.component {...field.props} key={index}></field.component>
            })
          )
        ))}
      </Fragment>
    )
  }
}

// renderBlok(subfield, index, { registerField: registerField, deregisterField: deregisterField, isConversational: isConversational, conversationalProps: { [`cf-conditional-${blok.name}`]: option.value_override ? option.value_override : option.label } })
export const EditableSelect = ({ blok, registerField, deregisterField, isConversational, conversationalProps }) => {
  const selectField = () => {
    if (blok.type === 'checkboxes') {
      return (
        <Checkboxes
          label={renderBloks(blok.label)}
          options={blok.options.length > 0 ? (
            blok.options.map(option => ({
              value: option.value_override ? option.value_override : option.label,
              label: option.label,
              description: option.description,
              subfields: option.subfields ? (
                option.subfields.map((subfield, index) => parseBlok(subfield, index, { registerField: registerField, deregisterField: deregisterField, isConversational: isConversational }))
              ) : [],
              rules: option.rules,
            }))
          ) : []}
          helpText={blok.help_text}
          orientation={blok.list_orientation}
          required={blok.required}
          name={blok.name}
          errormessage={blok.error_message ? blok.error_message : 'This field is required.'}
          registerField={registerField}
          deregisterField={deregisterField}
          isConversational={isConversational}
          chatQuestion={blok.chat_question}
          conversationalProps={conversationalProps}
        />
      )
    } else if (blok.type === 'radio') {
      return (
        <RadioButtons
          label={renderBloks(blok.label)}
          options={blok.options.length > 0 ? (
            blok.options.map(option => ({
              value: option.value_override ? option.value_override : option.label,
              label: option.label,
              description: option.description,
              subfields: option.subfields ? (
                option.subfields.map((subfield, index) => parseBlok(subfield, index, { registerField: registerField, deregisterField: deregisterField, isConversational: isConversational }))
              ) : []
            }))
          ) : []}
          helpText={blok.help_text}
          orientation={blok.list_orientation}
          required={blok.required}
          name={blok.name}
          errormessage={blok.error_message ? blok.error_message : 'This field is required.'}
          registerField={registerField}
          deregisterField={deregisterField}
          isConversational={isConversational}
          chatQuestion={blok.chat_question}
          conversationalProps={conversationalProps}
        />
      )
    } else {
      return (
        <Select
          label={renderBloks(blok.label)}
          defaultText={blok.default_text}
          options={blok.options.length > 0 ? (
            blok.options.map(option => ({
              value: option.value_override ? option.value_override : option.label,
              label: option.label,
              subfields: option.subfields ? (
                option.subfields.map((subfield, index) => parseBlok(subfield, index, { registerField: registerField, deregisterField: deregisterField, isConversational: isConversational }))
              ) : []
            }))
          ) : []}
          helpText={blok.help_text}
          required={blok.required}
          name={blok.name}
          errormessage={blok.error_message ? blok.error_message : 'This field is required.'}
          registerField={registerField}
          deregisterField={deregisterField}
          isConversational={isConversational}
          chatQuestion={blok.chat_question}
          conversationalProps={conversationalProps}
        />
      )
    }
  }
  return <SbEditable content={blok}>{selectField()}</SbEditable>
}

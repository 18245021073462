import React, { Fragment } from 'react'
import { renderBloks, renderSEO } from 'utils'
import { Grid, GridItem, PageIntro, Container } from '../'
import { useStaticQuery, graphql } from "gatsby"

const extractImageComponent = (body) => {
  return body.find(blok => {
    return blok.component === 'image'
  })
}

export default ({ blok }) => {
  const { news } = useStaticQuery(query)
  
  const newsItems = news.edges.map(({ node }) => {
    const { title, summary, body } = JSON.parse(node.content)
    return {
      id: node.uuid,
      slug: node.full_slug,
      image: extractImageComponent(body[0].body).image,
      title,
      summary
    } 
  })

  // console.log('news items',newsItems)

  return (
    <Fragment>
      {renderSEO(blok)}
      <PageIntro title="News"/>
      <Container size={12}>
        <Grid columns={4}>
          {newsItems.map(item => (
            <GridItem key={item.id} image={item.image} title={item.title} link={item.slug} text={item.summary}/>
          ))}
        </Grid>
      </Container>
    </Fragment>
  )
}

const query = graphql`
  query newsQuery {
    news: allStoryblokEntry(filter: {full_slug: {regex: "/news\/.+/"}}) {
      edges {
        node {
          uuid
          full_slug
          content
        }
      }
    }
  }
`
import React, { Fragment, useState, useContext, useCallback, useRef } from 'react'
import ShopifyContext from '../../contexts/Shopify/shopifyContext'
import cn from 'classnames'

const AddToCartButton = ({ require_tc, variant }) => {

  const { addItem } = useContext(ShopifyContext)
  const [accepted_tc, setAcceptedTC] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const TCCheckbox = useRef({
    checked: false
  })

  const onCheckboxChange = () => {
    setAcceptedTC(TCCheckbox.current.checked)
  }

  const handleSubmit = useCallback(() => {
    setIsBusy(true)
    addItem(variant).then(() => setIsBusy(false))
  }, [variant])

  return (
    <Fragment>
      { require_tc && <div className="collection-item__require-tc">
        <label>
          <input className="collection-item__require-tc-toggle" type="checkbox" ref={TCCheckbox} onChange={onCheckboxChange} />
          &nbsp;Accept Sample Kits Terms and Conditions
        </label>
      </div>}
      <button
        className={cn('cta', 'cta--full', 'add-to-cart-button')}
        disabled={(require_tc && !accepted_tc) || !variant.available || isBusy}
        onClick={handleSubmit}
      >
        {isBusy ? <span>Adding...</span> : <span>Add To Cart</span>}
      </button>
    </Fragment>
  )

}

export default AddToCartButton
import React from 'react'
import { Icon } from 'components'
import SbEditable from '../../utils/sb-editable'

const Social = ({ links }) => (
  <div className="social">
    <span className="social__header">Follow Us:</span>
    <div className="social__row">
      <a className="social__icon" href={links.facebook}>
        <Icon name={`facebook`} />
      </a>
      <a className="social__icon" href={links.instagram}>
        <Icon name={`instagram`} />
      </a>
      <a className="social__icon" href={links.pinterest}>
        <Icon name={`pinterest`} />
      </a>
      <a className="social__icon" href={links.twitter}>
        <Icon name={`twitter`} />
      </a>
      <a className="social__icon" href={links.vimeo}>
        <Icon name={`vimeo`} />
      </a>
      <a className="social__icon" href={links.linkedin}>
        <Icon name={`linkedin`} />
      </a>
    </div>
  </div>
)

export const EditableSocial = ({ blok }) => (
  <SbEditable content={blok}>
    <Social links={blok} />
  </SbEditable>
)

export default Social

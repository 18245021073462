import material from './material'
import type from './type'
import family from './family'
import panelsize from './panel-size'
import frontcolor from './front-color'
import frontthickness from './front-thickness'
import frontfinish from './front-finish'
import frontproperty from './front-property'
import corepattern from './core-pattern'
import coredensity from './core-density'
import coregrainwidth from './core-grain-width'
import corethickness from './core-thickness'
import doublecores from './double-cores'
import doublecoresstack from './double-cores-stack'
import interlayer from './interlayer'
import middlefilm from './middle-film'
import backfilm from './back-film'
import backfilmrequired from './back-film-required'
import backcolor from './back-color'
import backfinish from './back-finish'
import backproperty from './back-property'
import backthickness from './back-thickness'
import lightemission from './light-emission'
import lightpointsize from './light-point-size'
import lightpoints from './light-points'
import ledcolor from './led-color'
import ledk from './led-k'
import thickness from './thickness'
import pattern from './pattern'
import size from './size'
import finish from './finish'
import edge from './edge'
import edgelighting from './edge-lighting'
import sealer from './sealer'
import terminalshape from './terminal-shape'
import terminalsize from './terminal-size'
import projection from './projection'
import projectionpattern from './projection-pattern'
import terrazzocolor from './terrazzo-color'
import whiteprotect from './white-protect'
import withaggregate from './with-aggregate'
import waterresistant from './water-resistant'
import preview from './preview'

import spark from './spark'
import celeste from './celeste'
import infinity from './infinity'
import terrazzo from './terrazzo'
import terrazzolumina from './terrazzo-lumina'
import ripplemotif from './ripple-motif'
import slant from './slant'
import lumina from './lumina'
import vapor from './vapor'
import pixa from './pixa'
import scintilla from './scintilla'

export default {
  'material': material,
  'type': type,
  'family': family,
  'panel-size': panelsize,
  'front-color': frontcolor,
  'front-thickness': frontthickness,
  'front-finish': frontfinish,
  'front-property': frontproperty,
  'core-pattern': corepattern,
  'core-density': coredensity,
  'core-grain-width': coregrainwidth,
  'core-thickness': corethickness,
  'double-cores': doublecores,
  'double-cores-stack': doublecoresstack,
  'interlayer': interlayer,
  'middle-film': middlefilm,
  'back-film': backfilm,
  'back-film-required': backfilmrequired,
  'back-color': backcolor,
  'back-finish': backfinish,
  'back-property': backproperty,
  'back-thickness': backthickness,
  'light-emission': lightemission,
  'light-point-size': lightpointsize,
  'light-points': lightpoints,
  'led-color': ledcolor,
  'led-k': ledk,
  'thickness': thickness,
  'pattern': pattern, // for spark and infinity (all others have custom)
  'size': size,
  'finish': finish,
  'edge': edge,
  'edge-lighting': edgelighting,
  'sealer': sealer,
  'terminal-shape': terminalshape,
  'terminal-size': terminalsize,
  'projection': projection,
  'projection-pattern': projectionpattern,
  'terrazzo-color': terrazzocolor,
  'white-protect': whiteprotect,
  'with-aggregate': withaggregate,
  'water-resistant': waterresistant,
  'preview': preview,

  'spark': spark,
  'celeste': celeste,
  'infinity': infinity,
  'terrazzo': terrazzo,
  'terrazzo-lumina': terrazzolumina,
  'ripple-motif': ripplemotif,
  'slant': slant,
  'lumina': lumina,
  'vapor': vapor,
  'pixa': pixa,
  'scintilla': scintilla
}
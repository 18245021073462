import React, { useRef, useState, useEffect, useCallback } from 'react'
import serialize from 'form-serialize'
import { useLocation } from '@reach/router'
import Products from './products'
import SpecifierPreview from './components/specifier-preview/specifier-preview'
import SpecifierContact from './components/specifier-contact/specifier-contact'
import specifiers from './data/rules';
import fields from './data/fields'
import { removeDuplicatedRules } from './utils/utils'
import { getSku } from './utils/getsku';
import { renderBloks } from 'utils'

/**
 * Specifier for Materials (eg Jali, SLANT, Lumina etc)
 */
const SpecifierMaterials = ({ blok: { family, contact_form } }) => {
  const location = useLocation()
  const previewRef = useRef()
  const formRef = useRef()
  const [state, setState] = useState({
    form: null,
    fractions: null,
    hash: '',
    orderingCode: null
  })

  const Fields = Products[family]
  let rules = [
    ...specifiers[family],
    ...specifiers.specifier
  ]
  rules = removeDuplicatedRules(rules)

  // preview notes data
  let previewFields = fields.preview;
  if(fields[family]) {
    previewFields = { ...previewFields, ...fields[family].preview };
  }

  const setForm = hash => {
    if(!hash) { return }

    const orderingCode = getSku(hash, family)
    const split = hash.slice(1, hash.length).split('&')
    let form = {};
    (split || []).forEach(key => {
      const [ name, value ] = key.split('=')
      form[name] = value
    });

    setState({ ...state, hash, form, orderingCode })
  }

  // set measurement fractions (filter all `measure-xx`)
  const getFractions = form => {
    let fractions = null
    form && Object.keys(form)
      .filter(key => key.indexOf('measure-') >= 0)
      .forEach(key => {
        fractions = {...fractions, [key.replace('measure-', '')]: form[key]}
      })
    return fractions
  }

  const getValues = form => form && Object.keys(form)
    .filter(key => key.indexOf('measure') < 0)
    .map(key => `${key}=${form[key]}`)

  /** All methods you need, will include also some function to pushState */
  const handleOnChange = useCallback(e => {
    const form = serialize(formRef.current, { hash: true })
    const fractions = getFractions(form)
    const values = getValues(form)
    const hash = `?${values.join('&')}`;
    const orderingCode = getSku(hash, family)

    history.pushState(null, null, `${location.pathname.replace(/\/+$/, '')}/${hash}`)
    setState({ form, fractions, hash, orderingCode })

  }, [getFractions])

  useEffect(function onMount() {
    const hash = location.search
    
    if(hash) {
      setForm(hash)
    } else {
      handleOnChange()
    }
  }, [location.search])

  return (
    <section className="specifier-materials" data-testid="SpecifierMaterials">
      <div className='container-fluid'>
        <div className='row'>
          <div className="specifier-materials__sidebar col-sm-10 col-lg-3 offset-sm-1 offset-lg-0">
            {state.fractions && 
              <SpecifierPreview
              {...state.fractions}
              form={state.form}
              ref={previewRef}
              fields={previewFields}
              />
            }

            {contact_form &&
              <SpecifierContact sku={state.orderingCode}>
                {renderBloks(contact_form)}
              </SpecifierContact>
            }
          </div>

          <form ref={formRef}
            onChange={handleOnChange}
            className='specifier-materials__form col-sm-10 col-lg-8 col-xl-6 offset-sm-1 offset-lg-1'>
            {Fields && 
            <div>
              <Fields form={state.form} rules={rules} triggerChange={handleOnChange} />
            </div>
            }
          </form>

        </div>
      </div>


    </section>
  )
}


export default SpecifierMaterials
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { flattenDataSources } from '../../utils'
import { Grid, FacetSearch, SingleStockItem } from 'components'

const facets = [
  { key: 'application', label: 'Application' },
  { key: 'industry', label: 'Industry' },
  { key: 'material', label: 'Material' },
  { key: 'patternFamily', label: 'Family' },
  { key: 'environment', label: 'Environment' },
  { key: 'materialProperty', label: 'Material Property' }
]

export const StockItemSearch = ({ datasources }) => (
  <FacetSearch
    indexName="Stock Items"
    facets={facets}
    datasources={flattenDataSources(datasources)}
    renderHits={hits =>
      hits.length > 0 ? (
        <Grid columns={3}>
          {hits.map(stockItem => (
            <SingleStockItem
              key={stockItem.objectID}
              image={stockItem.thumbnail}
              title={stockItem.title}
              link={`/${stockItem.slug}`}
              uuid={stockItem.objectID}
              body={stockItem.description}
            />
          ))}
        </Grid>
      ) : (
        <div className="container">
          <p>No results were found for your search</p>
        </div>
      )
    }
  />
)

export default props => {

  const { datasources } = useStaticQuery(graphql`
      query {
        datasources: allDatasourcesJson {
          edges {
            node {
              name
              value
              data_source
            }
          }
        }
      }
    `);

  return <StockItemSearch datasources={datasources} {...props} />

}

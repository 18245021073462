import React from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import { EditableComponents } from 'components'
import { renderBloks } from 'utils'
import mask from './mask.svg'

export const Hero = ({ align, image, limitImageWidth = false, disableDiagonalMask = false, children }) => (
  <div className={cn('hero', `hero--${align}`, { 'hero--fit-image': limitImageWidth })}>
    <div className="hero__image d-print-none" style={{ backgroundImage: `url(${image})` }} />
    <span className={cn('hero__mask', { 'hero__mask--no-diagonal': disableDiagonalMask })}>
      <img src={mask} />
    </span>
    <div className="hero__inner">
      <figure className="d-none d-print-block">
        <img src={image} />
      </figure>
      <div className="hero__content">{children}</div>
    </div>
  </div>
)

export const EditableHero = ({ blok }) => (
  <SbEditable content={blok}>
    <Hero
      align={blok.align}
      image={blok.image}
      limitImageWidth={blok.limit_image_width}
      disableDiagonalMask={blok.disable_diagonal_mask}
    >
      {renderBloks(blok.content)}
    </Hero>
  </SbEditable>
)

export default Hero

import React, { useContext, useState, useMemo } from 'react'
import ShopifyContext from '../../contexts/Shopify/shopifyContext'
import { Price } from '../price/Price'
import AddToCartButton from './add-to-cart-button'
import { RiverFlow } from '..'
import { Image } from 'components'

const CollectionItem = ({ index, product }) => {

  const { client } = useContext(ShopifyContext)
  const { title, descriptionHtml, tags, variants } = product
  const [selectedVariant, selectVariant] = useState(0)
  const require_tc = useMemo(() => (tags && tags.map(tag => tag.toLowerCase()).includes('sample kit') || false), [tags])
  const side = useMemo(() => (index % 2 === 0 ? 'left' : 'right'), [index])

  const onSelectChange = ev => {
    selectVariant(ev.target.value)
  }

  const variantImages = variants
    .filter(variant => variant.image)
    .map((variant, index) => (
      <Image key={index} src={client.image.helpers.imageForSize(variant.image, { maxWidth: 705, maxHeight: 705 })} alt={variant.title} />
    ))

  const content = (
    <div className="collection-item__content">
      <h2 className="collection-item__title">{title}</h2>
      <div className="collection-item__description" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
      {variants.length > 1 && (
        <label className="collection-item__variants">
          <span>Choose Variant:</span>
          <select
            className="form-control collection-item__variant--selector"
            value={selectedVariant}
            onChange={onSelectChange}
          >
            {variants.map((variant, index) => (
              <option key={variant.id} value={index}>
                {variant.title}
              </option>
            ))}
          </select>
        </label>
      )}
      <div className="collection-item__price">
        <Price amount={variants[selectedVariant].priceV2.amount} />
      </div>
      <AddToCartButton require_tc={require_tc} variant={variants[selectedVariant]} />
    </div>
  )

  return (
    <article className="collection-item">
      <RiverFlow
        images={variantImages}
        side={side}
        seeMoreLink={[]}
        content={content}
        showArrows={true}
        showThumbnails={false}
        currentSlide={selectedVariant}
        onChangeSlide={selectVariant}
      />
    </article>
  )
}

export default CollectionItem

import React, { Component, Fragment } from 'react'
import SbEditable from '../../utils/sb-editable'
import ConditionalWrap from 'conditional-wrap'
import { Link } from 'gatsby'
import { FacetSearch, Grid, GridItem, RiverFlow, Icon, Text, Image } from 'components'
import { renderBloks, slugify } from '../../utils'

class PatternPage extends Component {

  patternMaterialType = pattern => {
    let materialType = pattern.materialType || pattern.materialTypeFilterable
    materialType = materialType && materialType.toLowerCase().replace('-', ' ')
    switch (materialType) {
      case 'interactive':
        return [<Icon key={pattern.objectID} title="Interactive" name="interactive" />]
      case 'light filtering':
        return [<Icon key={pattern.objectID} title="Light Filtering" name="light-filtering" />]
      case 'light emitting':
        return [<Icon key={pattern.objectID} title="Light Emitting" name="light-emitting" />]
      default:
        return null
    }
  }

  patternRiverFlow = pattern => {
    return (
      <RiverFlow
        side="left"
        whiteText={false}
        verticalSide="top"
        fullBleed={false}
        textAlign="left"
        content={
          <Fragment>
            <ConditionalWrap
              condition={pattern.family || pattern.familyFilterable}
              wrap={children => (
                <Link to={`/materials/families/${slugify(pattern.family || pattern.familyFilterable)}`}>
                  {children}
                </Link>
              )}
            >
              <h2>
                {pattern.family || pattern.familyFilterable}{' '}
                <small>
                  <Icon
                    name={pattern.material || pattern.materialFilterable}
                    title={pattern.material || pattern.materialFilterable}
                  />{' '}
                  {this.patternMaterialType(pattern)}
                </small>
              </h2>
            </ConditionalWrap>
            <p>
              {(pattern.material || pattern.materialFilterable) && (
                <>
                  Material: <strong>{pattern.materialFilterable}</strong>
                  <br />
                </>
              )}
              {(pattern.materialtype || pattern.materialTypeFilterable) && (
                <>
                  Material Type: <strong>{pattern.materialtype || pattern.materialTypeFilterable}</strong>
                  <br />
                </>
              )}
              {(pattern.pattern || pattern.patternFilterable) && (
                <>
                  Pattern: <strong>{pattern.pattern || pattern.patternFilterable}</strong>
                  <br />
                </>
              )}
            </p>
            {pattern.description && <p>{pattern.description}</p>}
          </Fragment>
        }
        images={[<Image key={1} src={pattern.image} />]}
        showThumbnails={false}
      />
    )
  }

  render() {
    const { family, hero, body, facets=[] } = this.props
    const indexName = `${family} patterns`
    return (
      <div>
        {hero}
        {body}
        {<FacetSearch
            indexName={indexName}
            facets={facets}
            perPage={30}
            renderHits={hits =>
              hits.length > 0 ? (
                <Grid columns={5}>
                  {hits.map(pattern => (
                    <GridItem
                      key={pattern.objectID}
                      {...pattern}
                      title={pattern.pattern || pattern.patternFilterable}
                      image={pattern.image}
                      riverFlow={this.patternRiverFlow(pattern)}
                      type="pattern"
                      uuid={pattern.objectID}
                      text={<Text>{pattern.description}</Text>}
                    />
                  ))}
                </Grid>
              ) : (
                <div className="container">
                  <p>No results were found for your search</p>
                </div>
              )
            }
          />}
      </div>
    )
  }
}

export const EditablePatternPage = ({ blok }) => (
  <SbEditable content={blok}>
    <PatternPage
      family={blok.pattern_name}
      hero={renderBloks(blok.hero)}
      body={renderBloks(blok.body)}
      attributes={blok.attributes && blok.attributes.split(',')}
      facets={blok.facets}
    />
  </SbEditable>
)

export default PatternPage

import React, { useMemo} from 'react'
import cn from 'classnames'

const symbols = {
  USD: '$',
  CAD: '$'
}

export const Price = ({ amount, currency='USD', className }) => {
  const symbol = useMemo(() => {
    return (symbols[currency] || '')
  }, [currency])
  return (
    <span className={cn('price', className)}>
      <span className="price__currency">{currency}</span>
      <span className="price__amount">{symbol}{Number(amount).toFixed(2)}</span>
    </span>
  )
}

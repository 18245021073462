import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import SbEditable from '../../utils/sb-editable'

const forceLeadingSlash = url => {
  if (url.startsWith('/')) return url
  if (url.startsWith('?') && typeof window !== 'undefined') return `${window.location.pathname}${url}`
  return '/' + url
}

const Link = ({ children, to, ...props }) => {
  if (!to || to.startsWith('http') || to.startsWith('//') || to.startsWith('#')) 
    // External links can't use GatsbyLink
    return (
      <a href={to} {...props}>
        {children}
      </a>
    )
  else
    return (
      <GatsbyLink to={forceLeadingSlash(to)} {...props}>
        {children}
      </GatsbyLink>
    )
}

export const EditableLink = ({ blok }) => (
  <SbEditable content={blok}>
    <Link to={`/${blok.url.cached_url}`}>{blok.label}</Link>
  </SbEditable>
)

export default Link

import React, { Component } from 'react'
import avatar from './robot.png'
import loadjs from 'loadjs'
const cfURL = 'https://cdn.jsdelivr.net/gh/space10-community/conversational-form@0.9.83/dist/conversational-form.min.js'

const FORM_LAMBDA_URL = `${process.env.GATSBY_API_URL}/generic-form`

const withConversation = (WrappedComponent, options = {}) => {

  return class Conversation extends Component {
    onFlowUpdate = (ev) => {
      options.onFlowUpdate && options.onFlowUpdate(ev, this.conversation)
      const currentStep = this.conversation.flowManager.step
    }

    flowStep = (dto, success, error) => {
      if (this.validate(dto)) {
        return success()
      }
      else {
        return error()
      }
    }

    validate = dto => {
      const input = dto.text
      // only checking the regex on a text field
      if (dto.tag.type !== 'text') {
        return true
      }
      const validation = dto.tag.domElement.dataset.validation
      const regex = new RegExp(validation)
      return input.match(regex)
    }

    getFormData = () => {
      const conversation = this.conversation
      const data = conversation.getFormData()
      const serializedData = conversation.getFormData(true)
      const cleanFormData = new FormData()

      data.forEach((value, key) => {
        const item = serializedData[key]
        if (!!item && !!value && Array.isArray(item) && item.length > 0 && item[0] !== '') {
          cleanFormData.append(key, value)
        } else if (!!item && !!value && !cleanFormData.get(key)) {
          cleanFormData.append(key, value)
        }
      })

      return cleanFormData
    }

    postForm = () => {
      const conversation = this.conversation
      const data = this.getFormData()
      conversation.addRobotChatResponse("Submitting your inquiry, please wait...")
      conversation.stop()
      var xhr = new XMLHttpRequest();
      xhr.open('POST', FORM_LAMBDA_URL, true);
      xhr.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 201) {
          conversation.addRobotChatResponse("Thank you! We'll be in touch soon.")
        }
        else if (this.readyState === XMLHttpRequest.DONE && this.status !== 201) {
          conversation.addRobotChatResponse("There was an error submitting your inquiry. Please try again.")
        }
      }
      xhr.send(data)
    }

    componentDidMount() {
      loadjs(cfURL, () => {
        const cf = window.cf
        this.dispatcher = new cf.EventDispatcher()
        this.dispatcher.addEventListener(cf.FlowEvents.FLOW_UPDATE, this.onFlowUpdate, false)
        this.conversation = cf.ConversationalForm.startTheConversation({
          //overridable options
          robotImage: avatar,
          userImage: 'text:YOU',
          loadExternalStyleSheet: false,
          preventAutoStart: false,
          //hideUserInputOnNonTextInput: true,
          ...options,
          //non overridable options
          eventDispatcher: this.dispatcher,
          formEl: this.instance.querySelector('form'),
          context: this.instance,
          flowStepCallback: this.flowStep,
          submitCallback: this.postForm
        })
      })
    }

    render() {
      return (
        <div className="conversation">
          <div className="conversation__container">
            <div className="conversation__instance" ref={(el) => (this.instance = el)}>
              <WrappedComponent className="conversation__form" getConversation={() => (this.conversation)} {...this.props} />
            </div>
          </div>
        </div>
      )
    }
  }

}

export default withConversation

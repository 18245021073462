import React from 'react'
import Page from '../page'

const NewsItem = ({ blok }) => {
  return (
    <Page blok={blok}/>
  )
}

export default NewsItem

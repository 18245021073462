import React from 'react'
import SbEditable from '../../utils/sb-editable'

const Banner = props => (
  <div className="banner" role="banner">{props.children}</div>
)

export const EditableBanner = ({ blok }) => (
  <SbEditable content={blok}>
    <Banner>
      {blok.body}
    </Banner>
  </SbEditable>
)

export default Banner
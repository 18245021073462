import React, { Fragment, Component } from 'react'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import CloseOnEscape from 'react-close-on-escape'
import { Icon } from '../'
import { InspirationBoardConsumer } from '../../contexts/InspirationBoard/inspirationBoardContext'
import { GridItem, CarouselGrid, Button, Legend } from 'components'

export const BoardToggle = () => (
  <InspirationBoardConsumer>
    {({ toggleBoard, closeBoard, pins }) => (
      <CloseOnEscape onEscape={() => closeBoard()}>
        <button className="inspiration-board__toggle" onClick={toggleBoard}>
          <Fragment>
            <Icon name={`pin`} badge={pins && pins.length > 0 && pins.length} />
          </Fragment>
        </button>
      </CloseOnEscape>
    )}
  </InspirationBoardConsumer>
)

class Board extends Component {
  state = { copied: false, error: false, link: '' }

  urlField = React.createRef()

  share = async shareFunction => {
    const { url, copied, successful } = await shareFunction()
    this.setState({ copied: copied, error: !successful, link: url }, () => {
      !copied && this.urlField.current.select()
    })
  }

  closeBoard = onClose => {
    this.setState({ copied: false })
    onClose()
  }

  render = () => {
    const { copied, error, link } = this.state
    let copyText = ''
    if (copied) {
      copyText = 'Copied!'
    }
    else if (error) {
      copyText = "We couldn't copy the link to your clipboard! Try copying the link below."
    }
    return (
      <InspirationBoardConsumer>
        {({ closeBoard, pins, isOpen, shareBoard }) => (
          <OutsideClickHandler onOutsideClick={() => this.closeBoard(closeBoard)}>
            <div className={cn('inspiration-board', { 'inspiration-board--open': isOpen })}>
              {pins.length ? (
                <div>
                  <CarouselGrid autoplay={false} itemsPerSlide={6} itemsPerScroll={1} title="Your pins">
                    {pins.map((pin, index) => (
                      <GridItem key={index} image={pin.image} title={pin.title} link={pin.link} type={pin.type} inInspirationBoard={true} />
                    ))}
                  </CarouselGrid>
                  <div className="inspiration-board__share">
                    {copyText ? copyText : (
                      <Button kind="link" onClick={() => this.share(shareBoard)}>
                        <Fragment>
                          <Icon name={`link`} />
                          Copy link to this board
                        </Fragment>
                      </Button>
                    )}
                    {error &&
                      <input ref={this.urlField} readOnly className="inspiration-board__url-field" value={link} />
                    }
                  </div>
                </div>
              ) : (
                <h2 className="inspiration-board__no-pins">You have no pinned items.</h2>
              )
              }
            </div>
          </OutsideClickHandler>
        )}
      </InspirationBoardConsumer>
    )
  }
}

export default Board

import panelsize from './panel-size'
import frontcolor from './front-color'
import backproperty from './back-property'
import backcolor from './back-color'
import corethickenss from './core-thickness'
import pattern from './pattern'
import preview from './preview'

export default {
  'panel-size': panelsize,
  'front-color': frontcolor,
  'back-property': backproperty,
  'back-color': backcolor,
  'core-thickness': corethickenss,
  'pattern': pattern,
  'preview': preview,
}
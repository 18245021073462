import React from 'react'
import Form, { FormConsumer } from './form'
import { ShortText, LongText } from './text-fields'
import { SubmitButton } from './form-fields'

const StockItemForm = ({ itemName }) => (
  <Form
    isConversational={false}
    title="Inquire about this item"
    textAlign="left"
    minimal={false}
    inverse={false}
  >
    <FormConsumer>
      {({ registerField, deregisterField }) => (
        <ShortText
          type="hidden"
          label=""
          validation=""
          name="item"
          registerField={registerField}
          deregisterField={deregisterField}
          isConversational={false}
          defaultValue={itemName}
        />
      )}
    </FormConsumer>
    <FormConsumer>
      {({ registerField, deregisterField }) => (
        <ShortText
          type="text"
          label="Email"
          validation="^[^\s@]+@[^\s@]+\.[^\s@]+$"
          required={true}
          name="email"
          errormessage="This field is required."
          registerField={registerField}
          deregisterField={deregisterField}
          isConversational={false}
        />
      )}
    </FormConsumer>
    <FormConsumer>
      {({ registerField, deregisterField }) => (
        <LongText
          label="Your Inquiry"
          maxLength={500}
          required={true}
          name="inquiry"
          errormessage="This field is required."
          registerField={registerField}
          deregisterField={deregisterField}
          isConversational={false}
        />
      )}
    </FormConsumer>
    <FormConsumer>
      {({ registerField, deregisterField }) => (
        <SubmitButton label="Submit" style="full" inverse={false} />
      )}
    </FormConsumer>
  </Form>
)

export default StockItemForm

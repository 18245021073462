import React, { Fragment } from 'react'
import fieldsObject from '../data/fields';
import { filteredFields } from '../utils/utils';
import SpecifierField from '../components/specifier-field/specifier-field'
import SpecifierCheckbox from '../components/specifier-checkbox/specifier-checkbox'


const Lumina = (props) => {
  const { form } = props;
  const fields = filteredFields({ ...props, fields: { ...fieldsObject, ...fieldsObject['lumina'] }});

  return (
    <Fragment>
      <small>These specifiers are designed to allow viable selections to be made. However light emission and efficient light travel through the material is complex and affected by the property of the light emitting surface material, including its surface finish.</small>

      {fields['material'] && (
        <section className='specifier-block__section row'>
          <SpecifierField {...fields['material']} {...props} />
          <input type="hidden" name="family" value="lumina" /> 
        </section>
      )}

      {form && form['material'] &&
        <Fragment>
          {fields['panel-size'] && (
            <section className='specifier-block__section row'>
              <SpecifierField {...fields['panel-size']} {...props} />
            </section>  
          )}

        {form['material'] && form['panel-size'] &&
          <Fragment>
            <section className='specifier-block__section'>
              <h2 className='text--subheader'>Lighting</h2>
              <div className='row'>
                {fields['light-emission'] && <SpecifierField {...fields['light-emission']} {...props} />}
                {fields['water-resistant'] && <SpecifierCheckbox {...fields['water-resistant']} {...props} />}
                {fields['led-color'] && <SpecifierField {...fields['led-color']} {...props} />}
                {fields['led-k'] && <SpecifierField {...fields['led-k']} {...props} />}
              </div>
            </section>

            <section className='specifier-block__section'>
              <h2 className='text--subheader'>A. Front Cladding</h2>
              <div className='row'>
                {fields['front-property'] && <SpecifierField {...fields['front-property']} {...props} />}
              </div>
              {form['front-property'] &&
                <div className='row'>
                  {fields['front-color'] && <SpecifierField {...fields['front-color']} {...props} />}
                  {fields['front-finish'] && <SpecifierField {...fields['front-finish']} {...props} />}
                  {fields['front-thickness'] && <SpecifierField {...fields['front-thickness']} {...props} />}
                </div>
              }
            </section>

            <section className='specifier-block__section'>
              <h2 className='text--subheader'>B. Clear Core</h2>
              <div className='row'>
                {fields['pattern'] && <SpecifierField {...fields['pattern']} {...props} />}
                {fields['core-grain-width'] && <SpecifierField {...fields['core-grain-width']} {...props} />}
                {fields['core-thickness'] && <SpecifierField {...fields['core-thickness']} {...props} />}

                {fields['interlayer'] && (
                  form['light-emission'] === 'faceback' ?
                  <SpecifierField {...fields['back-film-required']} {...props} /> : 
                  <SpecifierField {...fields['interlayer']} {...props}/>
                )}
              </div>
            </section>

            <section className='specifier-block__section'>
              <h2 className='text--subheader'>C. Back Cladding</h2>
              <div className='row'>
                {fields['back-property'] && <SpecifierField {...fields['back-property']} {...props} />}
              </div>
              {form['back-property'] &&
                <div className='row'>
                  {fields['back-color'] && <SpecifierField {...fields['back-color']} {...props} />}
                  {fields['back-finish'] && <SpecifierField {...fields['back-finish']} {...props} />}
                  {fields['back-thickness'] && <SpecifierField {...fields['back-thickness']} {...props} />}
                </div>
              }
            </section>

            <section className='specifier-block__section'>
              <h2 className='text--subheader'>D. White Protect</h2>
              <div className='row'>
                {fields['white-protect'] && <SpecifierCheckbox {...fields['white-protect']} {...props} />}
              </div>
            </section>
          </Fragment>
        }
      </Fragment>
    }
    </Fragment>
  )
};

export default Lumina;
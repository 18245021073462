import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const SEO = ({ title, description, og_image, og_title, twitter_image, twitter_title, og_description, twitter_description, slug }) => {

  const data = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: url
          defaultImage: image
          twitterUsername
        }
      }
    }
  `)

  const {
    site: {
      siteMetadata: {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
        twitterUsername,
      }
    }
  } = data;

  const seo = {
    title: title || defaultTitle,
    og_title: og_title || title || defaultTitle,
    twitter_title: twitter_title || title || defaultTitle,
    description: description || defaultDescription,
    og_description: og_description || description || defaultDescription,
    twitter_description: twitter_description || description || defaultDescription,
    og_image: (og_image && og_image.indexOf('//') > -1) ? og_image : `${siteUrl}${og_image || defaultImage}`,
    twitter_image: (twitter_image && twitter_image.indexOf('//') > -1) ? twitter_image : `${siteUrl}${twitter_image || defaultImage}`,
    url: `${siteUrl}/${slug || ''}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.og_title} />
      <meta property="og:description" content={seo.og_description} />
      <meta property="og:image" content={seo.og_image} />
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
      <meta name="twitter:title" content={seo.twitter_title} />
      <meta name="twitter:description" content={seo.twitter_description} />
      <meta name="twitter:image" content={seo.twitter_image} />
    </Helmet>
  )

}

export default SEO

export const EditableSEO = ({ blok }) => {
  return (<SEO
    title={blok.title || blok.name}
    description={blok.description}
    og_image={blok.og_image !== '' && blok.og_image || undefined}
    og_title={blok.og_title !== '' && blok.og_title || blok.title || blok.name}
    twitter_image={blok.twitter_image !== '' && blok.twitter_image || undefined}
    twitter_title={blok.twitter_title !== '' && blok.twitter_title || blok.title || blok.name}
    og_description={blok.og_description !== '' && blok.og_description || blok.description}
    twitter_description={blok.twitter_description !== '' && blok.twitter_description || blok.description}
    slug={(blok.slug && blok.slug !== 'home') ? blok.slug : ''}
  />)
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
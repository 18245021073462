import panelsize from './panel-size'
import lightemission from './light-emission'
import frontcolor from './front-color'
import frontthickness from './front-thickness'
import backcolor from './back-color'
import backthickness from './back-thickness'
import backfilm from './back-film'

export default {
  'panel-size': panelsize,
  'light-emission': lightemission,
  'front-color': frontcolor,
  'front-thickness': frontthickness,
  'back-color': backcolor,
  'back-thickness': backthickness,
  'back-film': backfilm
}
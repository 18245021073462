import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import NavItem from './nav-item'

class Nav extends React.Component {
  state = { openDropdown: null, dropdownHeight: 0 }
  clearDropdown() {
    this.setState({ openDropdown: null, dropdownHeight: 0 })
  }
  render() {
    const { items, onNavigate } = this.props
    const { openDropdown, dropdownHeight } = this.state
    const urlFromItem = item => (item.address.id ? item.address.cached_url : '')
    return (
      <OutsideClickHandler onOutsideClick={() => this.setState({ openDropdown: null, dropdownHeight: 0 })}>
        <ul className="nav" style={{ paddingBottom: dropdownHeight }}>
          {items &&
            items.map((item, index) => {
              return (
                <NavItem
                  key={index}
                  url={urlFromItem(item)}
                  label={item.text}
                  onNavigate={() => {
                    onNavigate()
                    this.clearDropdown() 
                  }}
                  children={item.children.map(child => ({
                    url: urlFromItem(child),
                    label: child.text,
                    thumbnail: child.thumbnail,
                  }))}
                  open={openDropdown === index}
                  onToggleDropdown={dropdownHeight =>
                    openDropdown !== null
                      ? this.clearDropdown()
                      : this.setState({ openDropdown: index, dropdownHeight: dropdownHeight })
                  }
                />
              )
            })}
        </ul>
      </OutsideClickHandler>
    )
  }
}

export default Nav

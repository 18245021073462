import fin from './fin'
import disc from './disc'
import wing from './wing'
import cube from './cube'

export default {
  fin,
  disc,
  wing,
  cube
}
import family from './family'
import pattern from './pattern'
import contrust from './contrust'
import shape from './shape'
import size from './size'
import thickness from './thickness'
import width from './width'
import height from './height'
import length from './length'
import finish from './finish'
import film from './film'
import edge from './edge'
import led from './led'
import mounting from './mounting'
import wire from './wire'
import connection from './connection'
import facecolor from './face-color'
import backcolor from './back-color'
import isdimensional from './is-dimensional'
import withaggregate from './with-aggregate'

import pendant from './pendant'

export default {
  family,
  pattern,
  contrust,
  shape,
  size,
  thickness,
  width,
  height,
  length,
  finish,
  film,
  edge,
  led,
  mounting,
  wire,
  connection,
  'face-color': facecolor,
  'back-color': backcolor,
  'is-dimensional': isdimensional,
  'with-aggregate': withaggregate,

  pendant
}
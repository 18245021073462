import React, { useState, useEffect, useRef } from 'react'
import { globalHistory } from '@reach/router'
import cn from 'classnames'
import algoliasearch from 'algoliasearch'
import { InstantSearch, connectSearchBox, connectHits, Configure } from 'react-instantsearch-dom'
import CloseOnEscape from 'react-close-on-escape'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Icon } from 'components'

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY, { _useRequestCache: true })

const Search = ({}) => {

  const [ searchState, setSearchState ] = useState({})
  const [ open, setOpen ] = useState(false)
  const searchBox = useRef()
  const scrollRef = useRef()

  const closeSearch = () => {
    setOpen(false)
    enableBodyScroll(scrollRef.current.element)
  }

  const openSearch = () => {
    searchBox.current.focus()
    setOpen(true)
    disableBodyScroll(scrollRef.current.element)
  }

  const toggleSearch = () => {
    open ? closeSearch() : openSearch()
  }

  useEffect(() => {
    return clearAllBodyScrollLocks
  }, [])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        closeSearch()
      }
    })
  }, [setOpen])

  return (
    <div ref={scrollRef} className={cn('search', { 'search--open': open })}>
      <CloseOnEscape onEscape={closeSearch}>
        <div className={cn('search__searchbar', { 'search__searchbar--open': open })}>
          <InstantSearch
            searchClient={searchClient}
            indexName="Pages"
            searchState={searchState}
            onSearchStateChange={setSearchState}
          >
            <Configure attributesToSnippet={['content']} />
            <SearchBox reference={searchBox} open={open} />
            <SearchResults query={searchState.query} open={open} />
          </InstantSearch>
        </div>
      </CloseOnEscape>
      <button className="search-toggle" onClick={toggleSearch}>
        {open ? (
          <Icon name="close" />
        ) : (
          <Icon name="search" />
          )
        }
      </button>
    </div>
  )

}

const SearchBoxComponent = ({ currentRefinement, refine, open, reference }) => (
  <input
    ref={reference}
    className={cn('search__input', { 'search__input--open': open })}
    placeholder="Search"
    value={currentRefinement}
    onChange={event => refine(event.currentTarget.value)}
  />
)
const SearchBox = connectSearchBox(SearchBoxComponent)

export const SearchResultsComponent = ({ hits, query, open }) => (
  <div className={cn('search__results', { 'search__results--open': open })}>
    <div className="search__results__header">
      <h2>Search Results</h2>
      <p>{query ? `Your search for "${query}" returned ${hits.length} results.` : `Enter a search query above to get started.`}</p>
    </div>
    <div className="search__results__inner">
      {query && hits.map((hit, index) => (
        <SearchResult key={index} image={hit.image} url={hit.url} snippet={hit._snippetResult.content.value.substring(0, 200)} title={hit.title} index={index} />
      ))}
    </div>
  </div>
)
const SearchResults = connectHits(SearchResultsComponent)

export const SearchResult = ({ image, url, snippet, title, index }) => {
  const getFirstURLSegment = url => {
    const segments = url.split('/');
    return segments[1]
  }

  return (
    <a className="search__result" key={index} href={url}>
      <div className="search__result__thumbnail" style={{ backgroundImage: `url(${image})` }} />
      <div className="search__result__text">
        <div className="search__result__section-name">{getFirstURLSegment(url)}</div>
        <h4>{title}</h4>
        <p
          className="search__result__snippet"
          dangerouslySetInnerHTML={{ __html: `...${snippet}...` }}
        />
      </div>
    </a>
  )
}

export default Search

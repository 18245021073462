import React from 'react'
import SbEditable from '../../utils/sb-editable'
import { renderBloks } from 'utils'

// Just a static component, doesn't need to be configurable from the CMS
const Lighting = ({ title, description, children }) => (
  <div className="lighting">
    <h2 className="lighting__title">{title}</h2>
    <p className="lighting__description">{description}</p>
    <div className="lighting__columns">
      {children}
    </div>
  </div>
)

export const LightingColumn = ({ title, children }) => (
  <div className="lighting-column">
    <h4 className="lighting-column__title">{title}</h4>
    {children}
  </div>
)

export const EditableLightingColumn = ({ blok }) => (
  <SbEditable content={blok}>
    <LightingColumn title={blok.title}>
      {renderBloks(blok.body)}
    </LightingColumn>
  </SbEditable>
)

export const EditableLighting = ({ blok }) => (
  <SbEditable content={blok}>
    <Lighting title={blok.title} description={blok.description}>
      {renderBloks(blok.columns)}
    </Lighting>
  </SbEditable>
)
export default Lighting

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import reactStringReplace from 'react-string-replace'


const symbols = {
  '[[1]]': { name: 'dot', title: null },
  '[[1/2]]': { name: 'dot-half', title: null },
  '[[0]]': { name: 'dot-hollow', title: null },
  '[[x]]': { name: 'close', title: null },
  '[[r]]': { name: 'resin', title: 'Resin' },
  '[[g]]': { name: 'glass', title: 'Glass' },
  '[[t]]': { name: 'terrazzo', title: 'Terrazzo' },
  '[[le]]': { name: 'light-emitting', title: 'Light Emitting' },
  '[[lf]]': { name: 'light-filtering', title: 'Light Filtering' },
  '[[i]]': { name: 'interactive', title: 'Interactive' },
}



const Icon = ({ name, badge, title }) => (
  <span title={title} className={`icon icon-${name && name.toLowerCase()}`}>
    {badge && <sup className="icon--badge">{badge}</sup>}
  </span>
)

export const replaceIcons = (content) => {
  return reactStringReplace(content, /(\[(?:\[??[^\[]*?\]\]))/gi, (match, i) => {
    if (!symbols[match]) {
      return content
    }
    return <Icon key={i} name={symbols[match].name} title={symbols[match].title} />
  })
}


export const EditableIcon = ({ blok }) => (
  <SbEditable content={blok}>
    <Icon name={blok.icon} />
  </SbEditable>
)

export default Icon

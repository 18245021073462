import React, { Component, Fragment } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { parseIncompletePhoneNumber, formatIncompletePhoneNumber } from 'libphonenumber-js/core'
import cn from 'classnames'
import FormField from './form-fields'

// adapted from https://github.com/catamphetamine/react-phone-number-input/blob/master/source/InputBasic.js
class PhoneInputField extends Component {
  static getDerivedStateFromProps({ value }) {
    return { value }
  }

  state = {}
  input = React.createRef()

  onChange = (event) => {
    const { onChange } = this.props
    const { value } = this.state

    let newValue = parseIncompletePhoneNumber(event.target.value)
    if (newValue === value) {
      if (this.format(newValue).indexOf(event.target.value) === 0) {
        // Trim the last digit (or plus sign).
        newValue = newValue.slice(0, -1)
      }
    }

    this.setState({ value: newValue }, () => onChange(newValue))
  }

  format(value) {
    const { country, metadata = { countries: {} } } = this.props

    return formatIncompletePhoneNumber(value, country, metadata)
  }

  focus = () => this.input.current.focus()

  render() {
    const { onChange, onFocus, name, chatQuestion, conversationalProps, required, errormessage, ...rest } = this.props

    const { value } = this.state

    return (
      <input
        name={name}
        type="tel"
        autoComplete="tel"
        {...rest}
        ref={this.input}
        value={this.format(value)}
        onChange={this.onChange}
        onFocus={onFocus}
        cf-questions={chatQuestion}
        cf-error={errormessage}
        required={required}
        {...conversationalProps}
      />
    )
  }
}

export class Phone extends FormField {
  state = { phone: '', valid: true }

  updatePhone = phone => {
    this.setState({ phone }, () => this.validate())
  }

  validate = () => {
    const { required } = this.props
    const { phone } = this.state
    if (required) {
      this.setState({ valid: !(phone === '') })
    }
    else this.setState({ valid: true })
  }

  renderField = () => {
    const { placeholder, required, errormessage, name, chatQuestion, conversationalProps } = this.props
    const { valid } = this.state
    return (
      <PhoneInput
        placeholder={placeholder}
        value={this.state.phone}
        onChange={phone => this.updatePhone(phone)}
        inputComponent={PhoneInputField}
        inputClassName={cn('form__input', { invalid: !valid }, { required: required })}
        errormessage={errormessage}
        name={name}
        chatQuestion={chatQuestion}
        required={required}
        conversationalProps={conversationalProps}
      />
    )
  }
}

import React, { Component } from 'react'
import CRC32 from 'crc-32'
import copy from 'copy-to-clipboard'
import getUuid from 'uuid-by-string'
const InspirationBoardContext = React.createContext()

const INSPIRATION_BOARD_URL = `${process.env.GATSBY_API_URL}/inspiration-board`

export class InspirationBoardProvider extends Component {
  state = {
    isOpen: false,
    pins: [],
    shareURL: '',
    boardID: '',
    boardContent: '',
    error: '',
  }

  togglePin = newPin => {
    const { pins } = this.state
    let existingPin = null;

    existingPin = pins.some(pin => getUuid(pin.image) === getUuid(newPin.image))

    let newPins = pins

    if (existingPin) {
      newPins = pins.filter(pin => {
        return getUuid(pin.image) !== getUuid(newPin.image)
      })
    }
    else {
      newPin.link = newPin.link ? newPin.link : '#'
      newPins.push(newPin)
    }
    this.setState({ pins: newPins }, () => {
      localStorage.setItem('pinnedItems', JSON.stringify(newPins))
      this.setState({ shareURL: this.generateShareURL() })
    })
  }

  toggleBoard = () => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  closeBoard = () => {
    this.setState({ isOpen: false })
  }

  generateShareURL = () => {
    const b64 = window.btoa(encodeURIComponent(JSON.stringify(this.state.pins)))
    const hash = CRC32.str(b64) >>> 0 // to get the unsigned value so we don't have negative indexes
    this.setState({ boardID: hash, boardContent: b64, shareURL: `${window.location.origin}/inspiration-board/?board=${hash}` })
    return `${window.location.origin}/inspiration-board/?board=${hash}`
  }

  pushInspirationBoard = () => {
    const { boardID, boardContent } = this.state

    const result = window.fetch(INSPIRATION_BOARD_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: boardID,
        boardContent: boardContent
      })
    })
    return result
  }

  shareBoard = async () => {
    const url = this.generateShareURL()
    const response = await this.pushInspirationBoard()
    const copied = copy(url)
    return { url: url, copied: copied, successful: response.status >= 200 && response.status < 300 }
  }

  setPins = pins => {
    this.setState({ pins: pins })
  }

  componentDidMount = async () => {
    this.setState({ pins: JSON.parse(localStorage.getItem('pinnedItems')) ? JSON.parse(localStorage.getItem('pinnedItems')) : [] }, () => {
      this.generateShareURL()
    })
  }

  render() {
    const { children } = this.props
    const { pins, isOpen, pushInspirationBoard } = this.state
    const { togglePin, toggleBoard, closeBoard, setPins, shareBoard } = this

    return (
      <InspirationBoardContext.Provider value={{ pins, togglePin, toggleBoard, closeBoard, isOpen, pushInspirationBoard, setPins, shareBoard }} >
        {children}
      </InspirationBoardContext.Provider>
    )
  }
}

export const InspirationBoardConsumer = InspirationBoardContext.Consumer

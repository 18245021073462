import React, { Component, Fragment } from 'react'
import SbEditable from '../../utils/sb-editable'
import { renderBloks } from 'utils'

const KeyValues = ({ children }) => (
  <div className='key-values'>
    {children}
  </div>
)

export const KeyValue = ({ title, value, subtitle, twoLine }) => {
  const keyValueTitle = () => {
    if (subtitle) {
      return (
        <Fragment>
          <span className="key-value__title">{`${title} : `}</span>
          <span className="key-value__subtitle">{subtitle}</span>
        </Fragment>
      )
    }
    else {
      return <span className="key-value__title">{`${title}:`} </span>
    }
  }

  return (
    <div className='key-value'>
      {keyValueTitle()}
      {twoLine && <br />}
      <span className="key-value__value">{value}</span>
    </div>
  )
}

export const EditableKeyValue = ({ blok }) => (
  <SbEditable content={blok}>
    <KeyValue title={blok.title} subtitle={blok.subtitle} value={blok.value} twoLine={blok.two_line} />
  </SbEditable>
)

export const EditableKeyValues = ({ blok }) => (
  <SbEditable content={blok}>
    <KeyValues>
      {renderBloks(blok.pairs)}
    </KeyValues>
  </SbEditable>
)

export default KeyValues

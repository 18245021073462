import React from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import { slugify } from 'utils'

const SectionHeading = ({ title, body, alignment }) => (
  <div className={cn('section-heading', `section-heading--${alignment}`)} id={slugify(title)}>
    <div className="row justify-content-center">
      <div className="col-md-6">
        <h2>{title}</h2>
        {body}
      </div>
    </div>
  </div>
)

export const EditableSectionHeading = ({ blok }) => (
  <SbEditable content={blok}>
    <SectionHeading
      title={blok.title}
      body={blok.body}
      alignment={blok.text_alignment == '' ? 'center' : blok.text_alignment}
    />
  </SbEditable>
)

export default SectionHeading

import React, { Fragment } from 'react'
import { EditableComponents, ErrorBoundary, Text } from 'components'
import btoa from 'btoa'
import atob from 'atob'

const setImageSide = (item, previousItem) => {
  if (item.side !== '') {
    return item
  } else if (previousItem.component !== 'river_flow') {
    item.side = 'left'
  } else {
    item.side = previousItem.side === 'left' ? 'right' : 'left'
  }
  return item
}

export const cdnAsset = url => (process.env.FE_USE_CDN === 'true' && url.replace(/https?:/, '').replace('//a.storyblok.com', '')) || url

export const formatSrc = ({
  src,
  size,
  filters = [],
  fitIn = false,
  autoCrop = false
}) => {
  if (!src) {
    return
  }
  //exclude non resizable assets
  if (
    !src.startsWith('//a.storyblok.com/') ||
    !size ||
    !/[0-9]{1,}x[0-9]{1,}/.test(size)
  ) {
    return cdnAsset(src)
  }
  return `//img2.storyblok.com${fitIn ? '/fit-in' : ''}/${size}${autoCrop ? '/smart' : ''
    }${filters && filters.length ? '/filters:' + filters.join(':') : ''
    }/${src.replace('//a.storyblok.com/', '')}`
}


export const parseBlok = (blok, index = 0, context = {}) => {

  const Blok = EditableComponents[blok.component] || Text

  return { component: Blok, props: { index: index, blok: blok, ...context } }
}



export const renderBlok = (blok, index = 0, context = {}) => {

  const Blok = EditableComponents[blok.component] || Text

  return (
    <ErrorBoundary key={blok._uid} blok={blok}>
      <Blok blok={blok} {...context} />
    </ErrorBoundary>
  )
}

export const renderBloks = (bloks, context = {}) => {
  return (
    <Fragment>
      {bloks && bloks.map((blok, index) =>
        blok.component === 'river_flow'
          ? renderBlok(setImageSide(blok, bloks[index - 1]), context)
          : renderBlok(blok, index, context)
      )}
    </Fragment>
  )
}

export const renderSEO = (blok) => {
  return (
    <Fragment>{
      renderBlok({
        component: 'SEO',
        name: blok.name || blok.title,
        slug: blok.slug,
        ...blok.SEO
      })}
    </Fragment>
  )
}

export const encodeShopifyId = (type, id) => {
  return btoa(`gid://shopify/${type}/${id}`)
}

export const decodeShopifyId = s_id => {
  const decoded = atob(s_id).split('/')
  const type = decoded[6]
  const id = decoded[7]
  return { type, id }
}

export const titleCase = str => {
  str = str.toLowerCase().split(' ')
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

export const slugify = str => {
  const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
  const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return str.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const resolveDataSourceValue = (item, attribute, datasources) => {
  const found = datasources.find(({ node }) => {
    return node.value === item && node.data_source === attribute
  })
  if (Boolean(found)) {
    return found.node.name
  }
  return item
}

export const resolveCompositeAttribute = (blok, attribute, secondaryAttribute, datasources) => {
  if ((blok[secondaryAttribute] && !blok[secondaryAttribute].length) || !blok[secondaryAttribute]) {
    return blok[attribute].map(item => resolveDataSourceValue(item, attribute, datasources)).join(', ')
  }
  return `${resolveDataSourceValue(blok[attribute][0], attribute, datasources)} ${resolveDataSourceValue(
    blok[secondaryAttribute][0],
    secondaryAttribute,
    datasources
  )}`
}

export const injectSpecificationItems = (blok, specificationBlok, datasources, attributes) => {
  if (!Boolean(specificationBlok)) {
    return blok
  }
  const itemsToInject = Object.entries(attributes)
    .filter(([attribute, label]) => blok[attribute] && blok[attribute].length > 0)
    .map(([attribute, label]) => {
      const source = [].concat(blok[attribute].length ? blok[attribute] : [blok[attribute]])
      let content
      //special cases:
      switch (attribute) {
        case 'lightPointSize':
        case 'patternAttributes':
          content = null
          break
        case 'lightPointShape':
          content = resolveCompositeAttribute(blok, attribute, 'lightPointSize', datasources)
          break;
        case 'pattern':
          content = resolveCompositeAttribute(blok, attribute, 'patternAttributes', datasources)
          break;
        default:
          content = source.map(item => resolveDataSourceValue(item, attribute, datasources)).join(', ')
      }

      if (content === null) {
        return
      }

      return {
        title: label,
        content,
        component: 'specification_item',
      }
    }).filter(item => item !== undefined)

  specificationBlok.specification_items = itemsToInject.concat(specificationBlok.specification_items)
  return blok
}

export const flattenDataSources = (datasources => {
  const result = datasources.edges.reduce((memo, { node }) => {
    memo[node.value] = node.name
    return memo
  }, {})
  return result
})


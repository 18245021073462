import { useEffect } from 'react'

const useScript = ({text, ...attributes}) => {
  useEffect(() => {
    const script = document.createElement('script')
    attributes && Object.entries(attributes).forEach(([key,value]) => {
      script.setAttribute(key,value)
    })
    script.textContent = text

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [attributes])
}

export default useScript

import React, { Fragment, Component } from 'react'
import { Location } from '@reach/router';
import { Helmet } from "react-helmet"
import cn from 'classnames'
import escape from 'escape-html'
import { Icon } from 'components'

class Share extends Component {
  state = { open: false }

  open = () => {
    this.setState({ open: true })
  }

  close = () => {
    this.setState({ open: false })
  }

  pageTitle = () => typeof document !== 'undefined' ? escape(document.title).replace('|', '-') : ''

  render() {
    const { location } = this.props
    const { open } = this.state
    return (
      <Location>
        {({ navigate, location }) => 
          <Fragment>
            <Helmet script={[
              {
                src: 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.3',
                async: true,
                defer: true,
                crossorigin: 'anonymous',
              },
            ]}
            />
            <div
              className={cn('share', { 'share--open': open })}
              onMouseEnter={this.open}
              onMouseLeave={this.close}
            >
              <span>Share</span>
              <Icon name="share" />
              <div className={cn("share__icons", { 'share__icons--open': open })}>
                <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.GATSBY_DOMAIN}${escape(location.pathname)}&amp;src=sdkpreparse`} className="fb-xfbml-parse-ignore">
                  <Icon name="facebook" />
                </a>
                <a target="_blank" href={`http://twitter.com/share?url=${process.env.GATSBY_DOMAIN}${location.pathname}&hashtags=Sensitile`}>
                  <Icon name="twitter" />
                </a>
                <a target="_blank" href={`http://www.linkedin.com/shareArticle?mini=true&url=${process.env.GATSBY_DOMAIN}${location.pathname}&title=${this.pageTitle()}`}>
                  <Icon name="linkedin" />
                </a>
              </div>
            </div>
          </Fragment>
        }
      </Location>
    )
  }
}

export default Share

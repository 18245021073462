import React, { useState, useEffect, useCallback, useMemo } from 'react'
import cn from 'classnames'
import isEqual from 'lodash.isequal'
import { hasRulesMatched, getGroupedOptions } from '../../utils/utils'
import { usePrevious } from '../../../common/hooks/use-previous/use-previous.hook'

const SpecifierField = (props) => {
  const { form, name, options, readOnly } = props
  const [state, setState] = useState({ value: '', fraction: null })
  const prevOptions = usePrevious(options)
  
  const hasCustomNoneOption = useMemo(
    () => options[0].value === '' || options[0].value === 'none' || readOnly,
    [options]
  )

  // eg replacer={{ "4'x8'": '48"x80"' }}
  const replaceStr = (match, p1) => {
    if(!props.replacer || !props.replacer[p1]) { return p1 }
    return props.replacer[p1]
  }

  const replacedLabel = useMemo(
    () => props.label.replace(/{(.*?)}/, replaceStr),
    [props.label, replaceStr]
  )

  const grouped = useMemo(
    () => getGroupedOptions(options),
    [options]
  )

  const autoSelect = value => {
    let val = value
    // if the options are changed, and there's no matching value with current selected value
    // force select empty value
    const match = options.filter(option => option.value === value)
    if(!match.length) {
      val = ''
    }
    // if there is only one option, force selecting it
    if(options.length === 1) {
      val = options[0].value
    }

    if(val !== null) {
      setValue(val)
    }
  }

  // use this to set value and manually trigger onchange
  const setValue = (value, e) => {
    const fraction = options.reduce((acc, curr) => (curr.value === value? curr.fraction : acc), null)
    setState({ value, fraction })
    
    // give fake timeout for Chrome issue
    setTimeout(() => props.triggerChange(e), 0)
  }

  const handleOnChange = useCallback(({ target: { value } }) => setValue(value), [setValue])


  // if target is set, its value needs to match with form selection
  const renderNotes = (note, idx) => {
    const content = note.content.replace(/{(.*?)}/, replaceStr);

    return (
      hasRulesMatched(note.dependon, form) &&
      <small key={idx} className='specifier-materials__field__helper'>{content}</small>
    )
  }

  const renderOptions = (option, idx) => (
    <option key={idx} value={option.value}>{option.label}</option>
  )

  // for grouped options
  const renderGroup = (key, options) => 
    key && key !== 'undefined' ? (
      <optgroup label={key} key={key}>
        {options.map(renderOptions)}
      </optgroup>
    ) : options.map(renderOptions)


  useEffect(function onFormChange() {
    // externally set value form[name] is priority
    const val = (form && form[name]) || state.value

    if(state.value !== val || !isEqual(prevOptions, options)) {
      autoSelect(val)
    }
  }, [form, options])
  
  
  return (
    <div className={cn('form-group col-sm-6 specifier-materials__field', props.classNames)}>
      <label  htmlFor={name}>
        {replacedLabel}
      </label>

      <select
        name={name}
        id={name}
        className='custom-select'
        value={state.value}
        onChange={handleOnChange}>
        {!hasCustomNoneOption && <option value="">Select {replacedLabel}</option>}
        {Object.keys(grouped).map(key => renderGroup(key, grouped[key]))}
      </select>
      {state.fraction && <input type='hidden' name={`measure-${props.measure}`} value={state.fraction} />}
      
      {props.description && <p>{props.description}</p>}
      {props.notes && props.notes.map(renderNotes)}
    </div>
  )
}


export default SpecifierField

import React, { Fragment, useRef } from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import CloseOnEscape from 'react-close-on-escape'
import { Link, Modal } from 'components'
import { renderBlok, renderBloks } from 'utils'

export const CtaGroup = ({ align, children }) => (
  <div className={cn('cta-group', `cta-group--align-${align}`)}>{children}</div>
)

export const EditableCtaGroup = ({ blok }) => (
  <SbEditable content={blok}>
    <CtaGroup align={blok.align}>
      {blok.ctas.map((cta, index) => (
        renderBlok(cta)
      ))}
    </CtaGroup>
  </SbEditable>
)

export const Button = ({ to, children, kind, inverse, ...rest }) => {
  const className = cn('cta', `cta--${kind}`, { 'cta--inverse': inverse })
  if (to) {
    return (
      <Link to={to} {...rest} className={className}>
        {children}
      </Link>
    )
  } else {
    return (
      <button {...rest} className={className}>
        {children}
      </button>
    )
  }
}


const Cta = ({ link, children, kind, inverse, modal }) => {

  const modalEl = useRef();
  let btnProps = {
    kind,
    inverse,
  }
  if (modal) {
    btnProps.to = `#${modal.title}`
    btnProps.onClick = e => modalEl.current.open(e)
  } else if (link) {
    btnProps.to = link
  }

  return (
    <>
      <Button {...btnProps}>{children}</Button>
      {modal && (
        <CloseOnEscape onEscape={() => modalEl.close()}>
          <Modal ref={modalEl} title={modal.title} fullScreen={modal.fullScreen} >
            {modal.body}
          </Modal>
        </CloseOnEscape>
      )}
    </>
  )

};

export const EditableCta = ({ blok }) => (
  <SbEditable content={blok}>
    <Cta
      link={blok.link.cached_url}
      kind={blok.style}
      inverse={blok.inverse}
      modal={
        blok.modal && blok.modal.length > 0 && { title: blok.modal[0].title, body: renderBloks(blok.modal[0].body), fullScreen: blok.modal[0].full_screen }
      }
    >
      {blok.label}
    </Cta>
  </SbEditable>
)

export default Cta

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import { renderBloks } from 'utils'
import { SectionHeading } from 'components'

const ThicknessComparison = ({
  title,
  description,
  children,
  leftHeading,
  rightHeading,
}) => (
  <div className={cn('thickness')}>
    <div className="thickness__container">
      <SectionHeading title={title} body={description} alignment="center" />
      <div className={cn('thickness__header')}>
        <div className={cn('thickness__text')}>{leftHeading}</div>
        <div className={cn('thickness__text')}>{rightHeading}</div>
      </div>
      {children}
    </div>
  </div>
)

export const EditableThicknessComparison = ({ blok }) => (
  <SbEditable content={blok}>
    <ThicknessComparison title={blok.title} description={blok.description} leftHeading={blok.leftHeading} rightHeading={blok.rightHeading}>
      {renderBloks(blok.slices)}
    </ThicknessComparison>
  </SbEditable>
)

export const ThicknessSlice = ({ grainWidth, image, materialThickness }) => (
  <div className={cn('thickness__slice')}>
    <div className={cn('thickness__text')}>{grainWidth}</div>
    <div className={cn('thickness__image')}>
      <img className="img-fluid" src={image} />
    </div>
    <div className={cn('thickness__text', 'thickness__material')}>{materialThickness}</div>
  </div>
)

export const EditableThicknessSlice = ({ blok }) => (
  <SbEditable content={blok}>
    <ThicknessSlice
      grainWidth={blok.grain_width}
      image={blok.image}
      materialThickness={renderBloks(blok.material_thickness)}
    />
  </SbEditable>
)

export default ThicknessComparison

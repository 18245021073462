import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SbEditable from '../../utils/sb-editable';
import { renderSEO, renderBloks, injectSpecificationItems } from 'utils'
import { PageIntro, StockItemForm } from 'components'

const categoriesToShow = {
  'application': 'Application',
  'industry': 'Industry',
  'environment': 'Environment',
  'illumination': 'Illumination',
  'material': 'Material',
  'color': 'Overall Color',
  'size': 'Size',
  'materialProperty': 'Material Property',
  'patternFamily': 'Family',
  'thickness': 'Thickness',
  'doubleSided': 'Double Sided',
  'lightPointShape': 'Light Point',
  // 'lightPointSize': 'Light Point Size',
  'coreThickness': 'Core Thickness',
  'pattern': 'Pattern',
  // 'patternAttributes': 'Pattern Attributes',
  'grain': 'Grain',
  'interlayers': 'Interlayers',
  'finish': 'Finish',
  'installationSolution': 'Installation Solution',
  'detailing': 'Detailing',
  'lighting': 'Lighting',
  'lightEmission': 'Light Emission'
}

const OnHandStock = ({ carousel, title, description, table, diagram, specs, relatedItems, relatedProjects }) => (
  <div className="container">
    {carousel}
    <PageIntro title={title}>{description}</PageIntro>
    {table}
    {diagram && (
      <div className="on-hand-stock__diagram">
        <img src={diagram} />
      </div>
    )}
    {specs}
    {relatedItems}
    {relatedProjects}
    <div className="on-hand-stock__form">
      <StockItemForm itemName={title} />
    </div>
  </div>
)

export const EditableOnHandStock = ({ blok }) => {
  const data = useStaticQuery(graphql`
    query {
      datasources: allDatasourcesJson {
        edges {
          node {
            name
            value
            data_source
          }
        }
      }
    }
  `)
  if (!blok.specifications) return <div />
  blok = injectSpecificationItems(blok, blok.specifications[0], data.datasources.edges, categoriesToShow)
  return (
    <Fragment>
      {renderSEO(blok)}
      <SbEditable content={blok}>
        <OnHandStock
          title={blok.title}
          carousel={renderBloks(blok.carousel)}
          description={blok.description}
          table={renderBloks(blok.table)}
          diagram={blok.custom_diagram}
          specs={renderBloks(blok.specifications)}
          relatedItems={renderBloks(blok.related_items)}
          relatedProjects={renderBloks(blok.related_projects)}
        />
      </SbEditable>
    </Fragment>
  )
}

export default OnHandStock

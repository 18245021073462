import React from 'react'
import cn from 'classnames'
import { Icon, Link, Thumbnail } from 'components'
import { renderBloks } from 'utils'

export default class NavItem extends React.Component {
  render() {
    const { url, label, mobileLabel, icon, thumbnail, children, open, onToggleDropdown, onNavigate } = this.props
    if (children && children.length > 0) {
      return (
        <li className={cn('nav-item', 'nav-item--parent', { 'nav-item--open': open })}>
          <span
            className="nav-item__link"
            onClick={() => {
              onToggleDropdown(this.dropdown.offsetHeight)
            }}
          >
            {label}
            <Icon name="arrow-down" />
          </span>
          <ul ref={r => (this.dropdown = r)} className="nav-item__subnav">
            {children.map((child, index) => (
              <NavItem key={index} {...child} onNavigate={onNavigate} />
            ))}
            {url && <NavItem url={url} label={`See All`} mobileLabel={`See All`} icon={'plus'} />}
          </ul>
        </li>
      )
    } else {
      return (
        <li className={cn('nav-item')} onClick={onNavigate}>
          <Link className="nav-item__link" to={url} activeClassName="nav-item__link--active">
            {thumbnail || icon ? (
              <Thumbnail
                image={thumbnail}
                icon={icon && <Icon name={icon} />}
                label={label}
                mobileLabel={mobileLabel}
              />
            ) : (
              label
            )}
          </Link>
        </li>
      )
    }
  }
}

import React, { useRef } from 'react'
import SbEditable from '../../utils/sb-editable'
import ReactPlayer from 'react-player'

const VideoPlayer = ({ video, autoplay, hideControls, repeat, startTime, onPlay = () => { }, onPause = () => { } }) => {

  const currentPlayer = useRef()

  return <div className="video-player__container">
    <div className="video-player__sizer">
      <ReactPlayer
        ref={player => {
          currentPlayer.current = player
        }}
        playing={autoplay}
        loop={repeat}
        controls={!hideControls}
        className="video-player__player"
        url={video}
        height="100%"
        width="100%"
        style={{ backgroundColor: 'none' }}
        onReady={() => currentPlayer.current.seekTo(startTime)}
        onPlay={onPlay}
        onPause={onPause}
      />
    </div>
  </div>
}
export const EditableVideoPlayer = ({ blok }) => (
  <SbEditable content={blok}>
    <VideoPlayer
      video={`${blok.video_url}`}
      autoplay={blok.autoplay}
      hideControls={blok.hide_controls}
      repeat={blok.repeat}
      startTime={blok.start_time ? blok.start_time : '0'}
    />
  </SbEditable>
)

export default VideoPlayer

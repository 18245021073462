import lighting from './lighting'
import frontthickness from './front-thickness'
import color from './color'
import backfilm from './back-film'
import pattern from './pattern'
import backthickness from './back-thickness'

export default {
  'lighting': lighting,
  'front-thickness': frontthickness,
  'front-color': { ...color, name: 'front-color' },
  'back-color': { ...color, name: 'back-color' },
  'back-film': backfilm,
  'pattern': pattern,
  'back-thickness': backthickness
}
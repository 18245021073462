import React, { Component, Fragment } from 'react'
import SbEditable from '../../utils/sb-editable'
import { SectionHeading, CtaGroup, Cta, } from 'components'
import SlickSlider from 'react-slick'
import { GridItem } from '..';
const slickSettings = {
  arrows: true,
  dots: true,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export const highlightHashTags = (caption) => {
  const regexp = new RegExp('#([^\\s]*)', 'g')
  if(!Boolean(caption)) {
    return ''
  }
  return caption.replace(regexp, (match, p1) => (`<a href="https://www.instagram.com/explore/tags/${p1}/" target="instagram">${match}</a>`))
}

class InstagramFeed extends Component {

  state = { slideChanging: false }

  async componentDidMount() {
    try {
      const response = await fetch(`${process.env.GATSBY_API_URL}/feed/instagram`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      })
      const result = await response.json()
      if (response.status >= 200 && response.status < 300) {
        console.log('success', result.data)
        this.setState({ live_data: result.data.instagram.edges })
      } else {
        var error = new Error(result.error)
        error.response = response
        throw error
      }
    } catch (err) {
      console.warn('error fetching live data from Instagram', err.message)
    }
  }

  render() {

    const { title, posts, numberOfPosts } = this.props
    const { slideChanging, live_data } = this.state
    const items = live_data || posts

    return (
      <div className="carousel-grid__container">
        <div className="container">
          <SectionHeading title={title} alignment="center" />
          <div className="carousel carousel-grid">
            <SlickSlider
              {...slickSettings}
              beforeChange={index => this.setState({ slideChanging: true })}
              afterChange={index => this.setState({ slideChanging: false })}
            >
              {items.slice(0, numberOfPosts).map(({ node }) => (
                <div key={node.id} className="carousel-grid__item">
                  <GridItem
                    image={node.thumbnail_url}
                    title=""
                    modal={{
                      body: (
                        <Fragment>
                          {
                            node.media_type === 'VIDEO' ?
                              <video autoPlay={true} controls={false}>
                                <source src={node.media_url}/>
                              </video>
                            :
                              <img className="modal__sample-image" src={node.media_url} />
                          }
                          <p dangerouslySetInnerHTML={{ __html: highlightHashTags(node.caption) }} />
                        </Fragment>
                      ),
                    }}
                    forceClose={slideChanging}
                    type="instagram"
                  />
                </div>
              ))}
            </SlickSlider>
          </div>
          <div>
            <CtaGroup align="center">
              <Cta kind="full" link={`https://www.instagram.com/${process.env.GATSBY_INSTAGRAM_USERNAME}/`}>
                Show More
              </Cta>
            </CtaGroup>
          </div>
        </div>
      </div>
    )
  }
}


export const EditableInstagramFeed = ({ blok }) => (
  <SbEditable content={blok}>
    <InstagramFeed
      title={blok.title}
      posts={[]}
      numberOfPosts={blok.number_of_posts}
    />
  </SbEditable>
)

export default InstagramFeed

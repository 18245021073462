import React, { Fragment } from 'react'
import cn from 'classnames'
import ConditionalWrap from 'conditional-wrap';
import { Icon } from 'components'
import FormField from './form-fields'
import { renderBloks } from '../../utils'

export const hasRulesMatched = (rules, string) => {
  let isValid = true;
  if (!rules) {
    return isValid
  }

  const isHash = string.startsWith('#')
  const cleanedString = string.slice(1,)
  const values = isHash ? [cleanedString] : cleanedString.split('&');
  rules.split(';').forEach(rule => {
    if (!values.includes(rule)) {
      isValid = false
    }
  })
  return isValid
}

export const Checkbox = ({ value, label, description, subfields, name, required, valid, checked, onChange, isConversational, conversationalProps, errormessage }) => {
  return (
    <Fragment>
      <label className="form__check-label">
        <input
          className={!isConversational ? cn('form__input', 'form__check-input', 'form__select', { invalid: !valid }, { required: required }) : ''}
          name={`${name}[]`}
          type="checkbox"
          value={value}
          checked={checked}
          onChange={onChange}
          required={required}
          cf-error={errormessage}
          {...conversationalProps}
        />
        <div className={`form__checkbox ${checked ? 'checked' : 'unchecked'}`}>
          <Icon name="checkmark" />
        </div>
        {label}
        {description && renderBloks(description)}
      </label>
      {
        isConversational ? (
          subfields.map((field, index) => (
            <field.component key={index} {...field.props} conversationalProps={{ [`cf-conditional-${name}`]: value }} />
          ))
        ) : checked && (
          subfields && subfields.map((field, index) => (
            <field.component key={index} {...field.props}></field.component>
          ))
        )
      }
    </Fragment>
  )
}


export class Checkboxes extends FormField {
  state = { checked: [], valid: true, hash: '' }

  componentDidMount = () => {
    if (window === 'undefined') { return }

    this.setState({ hash: location.search || location.hash })
  }

  check = value => {
    let { checked } = this.state

    if (checked.includes(value)) {
      checked = checked.filter(option => option !== value)
    }
    else {
      checked.push(value)
    }
    this.setState({ checked: checked }, () => this.validate())
  }

  validate = () => {
    const { required } = this.props
    let { checked } = this.state
    required && this.setState({ valid: checked.length > 0 })
  }

  renderField = () => {
    const { options, orientation, name, required, isConversational, chatQuestion, conversationalProps } = this.props
    const { checked, valid, hash } = this.state
    return (
      <div className={`form__select-wrapper form__select-wrapper--${orientation}`}>
        <ConditionalWrap
          condition={isConversational}
          wrap={children => (
            <fieldset cf-questions={chatQuestion}>{children}</fieldset>
          )}
        >
          {options.map((option, index) => hasRulesMatched(option.rules, hash) ? (
            <ConditionalWrap
              condition={!isConversational}
              key={index}
              wrap={children => (
                <div className="form__check">{children}</div>
              )}
            >
              <Checkbox
                label={option.label}
                description={option.description}
                value={option.value}
                subfields={option.subfields}
                name={name}
                required={required}
                valid={valid}
                checked={checked.includes(option.value)}
                onChange={() => this.check(option.value)}
                isConversational={isConversational}
                conversationalProps={conversationalProps}
              />
            </ConditionalWrap>
          ) : null)}
        </ConditionalWrap>
      </div>
    )
  }
}

import fieldsObject from '../data/fields'

// list of fields not included in sku value
const shouldExcludeFieldList= [
  'panel-size',
  'double-cores',
  'double-cores-stack',
  'edge-lighting',
  'led-color',
  'led-k',
  'water-resistant',
  'lpd',
  'light-point-size',
  'lighting'
]
// optional fields, will be added back after white-protect
// if selected (filterOutZeroSkuEnds)
// in this order (sortByOptionLabel)
const optionalFieldList= [
  'white-protect',
  'light-points',
  'core-density',
  'light-emission',
  'middle-film'
]

const filterOutExcludeLabel = str => {
  const [ label ] = str.split('=')
  return !shouldExcludeFieldList.concat(optionalFieldList).includes(label)
}

const filterByOptionLabel = str => {
  const [ label ] = str.split('=')
  return optionalFieldList.includes(label)
}

export const sortByOptionLabel = arr => {
  const newArr = optionalFieldList.map(curr => {
    const matched = arr.filter(str => {
      const [ label ] = str.split('=')
      return [curr].includes(label)
    })
    // could be undefined
    return matched[0]
  })
  return newArr.filter(str => !!str)
}

export const filterOutZeroSkuEnds = arr => {
  let hasSelectedOption = false
  const newArr = arr.reverse().filter(str => {
    if (Number(str) !== 0) {
      hasSelectedOption = true
    }
    return hasSelectedOption
  })
  return newArr.reverse()
}

const mapValueToSku = (str, family) => {
  const [label, fieldValue] = str.split('=');
  const fields = { ...fieldsObject, ...fieldsObject[family] }
  const fieldData = fields[label]
  let value = fieldValue

  if(!fieldData) { return value }

  // select dropdown
  const matched = (fieldData.options || []).filter(opt => opt.value === fieldValue)
  if(matched && matched[0]) {
    value = matched[0].sku || value
  }

  // checkbox
  if(typeof fieldData.checked === 'boolean') {
    value = fieldData.sku[value] || value
  }

  return value
};

// new SKU code generation (join with - for now)
export const getSku = (hash, family) => {
  if(!hash) { return null }

  const hashArray = hash.slice(1, hash.length).split('&')

  // require fields process
  const requiredFields = hashArray.filter(filterOutExcludeLabel) || []
  const requireFieldsSku = requiredFields.map(str => mapValueToSku(str, family))
  // optional fields process
  const optionalFields = hashArray.filter(filterByOptionLabel) || []
  const sortedOptionalFields = sortByOptionLabel(optionalFields)
  const sortedOptionalFieldsSku = sortedOptionalFields.map(str => mapValueToSku(str, family))
  const selectedOptionalFieldsSku = filterOutZeroSkuEnds(sortedOptionalFieldsSku)

  return requireFieldsSku.concat(selectedOptionalFieldsSku).join('').toUpperCase()
}
import React from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import ConditionalWrap from 'conditional-wrap'
import getUuid from 'uuid-by-string'
import CloseOnEscape from 'react-close-on-escape'
import { Icon, Modal, Link, InlineRiverFlow } from 'components'
import { renderBloks, renderBlok } from 'utils'
import { InspirationBoardConsumer } from '../../contexts/InspirationBoard/inspirationBoardContext'

export class RealGridItem extends React.Component {
  constructor(props) {
    super(props)
    this.childComponent = React.createRef()
  }

  updatePinned = e => {
    e.preventDefault()
    const { togglePin, title, image, link, type } = this.props
    const pin = {
      title: title,
      image: image,
      link: link !== '' && link ? link : `${window.location.pathname}#${this.imageToID()}`,
      type: type ? type : 'grid_item',
      uuid: getUuid(image),
    }
    togglePin(pin)
    return false
  }

  gridItemID = () => {
    const { inInspirationBoard = false, image } = this.props
    const id = !inInspirationBoard && image ? this.imageToID() : ''
    return id
  }

  imageToID = () => {
    const { image } = this.props
    return getUuid(image.replace(/[^a-zA-Z ]/g, ''))
  }

  openChildComponent = e => {
    this.childComponent.current && this.childComponent.current.open(e)
  }

  closeChildComponent = () => {
    this.childComponent.current && this.childComponent.current.close()
  }

  render() {
    const { className, image, title, body, modal, riverFlow, link, pinned, icons, text, isVideo } = this.props
    return (
      <ConditionalWrap
        condition={link}
        wrap={children => (
          <Link className={className} to={link}>
            {children}
          </Link>
        )}
      >
        <OutsideClickHandler onOutsideClick={() => this.closeChildComponent()}>
          <CloseOnEscape onEscape={() => this.closeChildComponent()}>
            <div id={this.gridItemID()} className="grid-item__container">
              <GridItemPin pinned={pinned} pin={{ link: link, title: title }} onClick={e => this.updatePinned(e)} />
              <figure className={cn('grid-item', { 'grid-item--has-link': link || riverFlow || modal })} onClick={this.openChildComponent}>
                <div
                  className={cn('grid-item__image', 'd-print-none', { 'grid-item__image--is-video': isVideo })}
                  style={{ backgroundImage: `url('${image && image.replace('(', '\\(').replace(')', '\\)')}')` }}
                />
                <div className="d-none d-print-block">
                  <img src={image} />
                </div>
                <figcaption className="grid-item__content">
                  <div className="grid-item__text">
                    <div className="grid-item__title">
                      <span>{title}</span>
                      {icons && <div className="grid-item__icons">{icons}</div>}
                    </div>
                    <div className="grid-item__body">{text}</div>
                  </div>
                </figcaption>
              </figure>
            </div>
            {!link && riverFlow && <InlineRiverFlow ref={this.childComponent} content={riverFlow} />}
            {!link && modal && (
              <Modal ref={this.childComponent} title={modal.title}>
                {modal.body}
              </Modal>
            )}
          </CloseOnEscape>
        </OutsideClickHandler>
      </ConditionalWrap>
    )
  }
}

class GridItem extends React.Component {
  isPinned = pins => {
    const { image } = this.props
    return pins.some(
      pin => typeof image === 'string' && typeof pin.image === 'string' && getUuid(pin.image) === getUuid(image)
    )
  }
  render() {
    return (
      <InspirationBoardConsumer>
        {({ togglePin, unpin, pins, pinURLs }) => (
          <RealGridItem
            {...this.props}
            togglePin={togglePin}
            unpin={unpin}
            pins={pins}
            pinURLs={pinURLs}
            pinned={this.isPinned(pins)}
          />
        )}
      </InspirationBoardConsumer>
    )
  }
}

const GridItemPin = ({ pinned, pin, onClick }) => (
  <div className={cn('grid-item__pin', pinned && 'selected')} onClick={e => onClick(e, pin)}>
    <Icon name={`pin`} />
  </div>
)

export const EditableGridItem = ({ blok, forceClose }) => {
  const setRiverFlowSide = () => {
    const riverFlow = blok.child[0]
    riverFlow.side = riverFlow.side === '' ? 'left' : riverFlow.side
    return riverFlow
  }
  const isVideo = blok.child.length > 0 && blok.child[0].component === 'modal' && blok.child[0].body.find(item => item.component === 'video_player')
  return (
    <SbEditable content={blok}>
      <GridItem
        image={blok.image}
        title={blok.title}
        modal={
          blok.child.length > 0 &&
          blok.child[0].component === 'modal' && { title: blok.child[0].title, body: renderBloks(blok.child[0].body) }
        }
        isVideo={isVideo}
        riverFlow={blok.child.length > 0 && blok.child[0].component === 'river_flow' && renderBlok(setRiverFlowSide())}
        link={blok.link.cached_url}
        forceClose={forceClose}
        text={blok.text && blok.text.length ? renderBlok(blok.text[0]) : []}
        icons={renderBloks(blok.icons)}
      />
    </SbEditable>
  )
}

export default GridItem

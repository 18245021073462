import React, { Fragment } from 'react'
import fieldsObject from '../data/fields';
import { filteredFields } from '../utils/utils';
import SpecifierField from '../components/specifier-field/specifier-field'

const Disc = (props) => {
  const { form } = props;
  const fields = filteredFields({ ...props, fields: { ...fieldsObject, ...fieldsObject['cube'] }});

  return (
    <Fragment>
      <input type="hidden" name="type" value="cube" />
      
      <section className='specifier-light__section'>
        <div className='row'>
          {fields['family'] && <SpecifierField {...fields['family']} {...props} />}
          {form && form['family'] && fields['pattern'] && <SpecifierField {...fields['pattern']} {...props} />}
        </div>
        
        <div className='row'>
          {fields['contrust'] && <SpecifierField {...fields['contrust']} {...props} />}
        </div>

        <div className='row'>
          {fields['size'] && <SpecifierField {...fields['size']} {...props} />}
        </div>

        <div className='row'>
          {fields['face-color'] && <SpecifierField {...fields['face-color']} {...props} />}
          {fields['back-color'] && <SpecifierField {...fields['back-color']} {...props} />}
        </div>

        <div className='row'>
          {fields['film'] && <SpecifierField {...fields['film']} {...props} />}
        </div>

        <div className='row'>
          {fields['led'] && <SpecifierField {...fields['led']} {...props} />}
        </div>
      </section>
    </Fragment>
  )
};

export default Disc;
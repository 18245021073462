import React, { Fragment } from 'react'
import { renderBloks, renderSEO } from 'utils'

const Page = ({ blok }) => {
  return (
    <Fragment>
      {renderSEO(blok)}
      {blok.body && renderBloks(blok.body)}
    </Fragment>
  )
}

export default Page

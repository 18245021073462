import React, { Fragment } from 'react'
import fieldsObject from '../data/fields';
import { filteredFields } from '../utils/utils';
import SpecifierField from '../components/specifier-field/specifier-field'
import SpecifierCheckbox from '../components/specifier-checkbox/specifier-checkbox'



const TerrazzoLumina = (props) => {
  const { form } = props;
  const fields = filteredFields({ ...props, fields: { ...fieldsObject, ...fieldsObject['terrazzo-lumina'] }});

  return (
    <Fragment>
      {fields['type'] && (
        <section className='specifier-block__section row'>
          <SpecifierField {...fields['type']} {...props} />
        </section>
      )}
      {form && form['type'] &&
        <Fragment>
          <section className='specifier-block__section'>
            <h2 className='text--subheader'>A. Core</h2>
            <div className='row'>
              {fields['thickness'] && <SpecifierField {...fields['thickness']} {...props} />}
              {fields['size'] && <SpecifierField {...fields['size']} {...props} />}
              {fields['pattern'] && <SpecifierField {...fields['pattern']} {...props} />}
            </div>
          </section>

          <section className='specifier-block__section'>
            <h2 className='text--subheader'>Terminal</h2>
            <div className='row'>
            {fields['terminal-shape'] && <SpecifierField {...fields['terminal-shape']} {...props} />}
            {fields['terminal-size'] && <SpecifierField {...fields['terminal-size']} {...props} />}
            </div>
          </section>

          <section className='specifier-block__section'>
            <div className='row'>
              {fields['terrazzo-color'] && <SpecifierField {...fields['terrazzo-color']} {...props} />}
              {fields['with-aggregate'] && <SpecifierCheckbox {...fields['with-aggregate']} {...props} />}
              {fields['finish'] && <SpecifierField {...fields['finish']} {...props} />}
            </div>
            <div className='row'>
              {fields['edge'] && <SpecifierField {...fields['edge']} {...props} />}
            </div>
            <div className='row'>
              {fields['sealer'] && <SpecifierField {...fields['sealer']} {...props} />}
            </div>
          </section>
        </Fragment>
      }
    </Fragment>
  )
};

export default TerrazzoLumina;
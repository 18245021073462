import React, { Fragment } from 'react'
import SbEditable from '../../utils/sb-editable'
import { CtaGroup, Cta, RiverFlow, GridItem, Text, Button, Icon, Image } from 'components'
class Sample extends React.Component {
  sampleImageUrl = () => {
    const { image_override, image } = this.props
    return image_override ? image_override : image
  }

  sampleModal = () => {
    const { family, pattern, description, image } = this.props
    return {
      title: `${family} | ${pattern}`,
      body: (
        <Fragment>
          <img src={image} />
          <Text>{description}</Text>
          <CtaGroup>
            <Cta link="/#related_family" kind="outline">
              {`${family} Family`}
            </Cta>
            <Cta link="/#related_projects" kind="outline">
              Related Projects
            </Cta>
            <Cta link="/#sample_kit_collection" kind="outline">
              Sample Kits
            </Cta>
            <Cta link="/#on_hand_stock" kind="outline">
              On-Hand Stock
            </Cta>
          </CtaGroup>
        </Fragment>
      ),
    }
  }

  sampleRiverFlow = () => {
    const { family, pattern } = this.props
    return (
      <RiverFlow
        side="left"
        whiteText={false}
        verticalSide="top"
        fullBleed={false}
        textAlign="left"
        content={
          <Fragment>
            <h2>{family}</h2>
            <p>{pattern}</p>
            <Button to="/#" kind="outline">
              Learn More
            </Button>
          </Fragment>
        }
        images={[<Image key={1} src={this.sampleImageUrl()} />]}
        showThumbnails={false}
      />
    )
  }

  sampleMaterialType = () => {
    const { materialType } = this.props
    if (materialType === 'Interactive') {
      return [<Icon key="1" title="Interactive" name="interactive" />]
    } else if (materialType === 'Light Filtering') {
      return [<Icon key="1" title="Light Filtering" name="light-filtering" />]
    } else if (materialType === 'Light Emitting') {
      return [<Icon key="1" title="Light Emitting" name="light-emitting" />]
    } else return []
  }

  sampleBodyToText = () => {
    const { pattern } = this.props
    return <Text>{pattern}</Text>
  }

  render() {
    const { family, modal, riverFlow, forceClose, image } = this.props
    return (
      <GridItem
        image={image}
        title={family}
        text={this.sampleBodyToText()}
        modal={modal && this.sampleModal()}
        icons={this.sampleMaterialType()}
        riverFlow={riverFlow && this.sampleRiverFlow()}
        forceClose={forceClose}
        type="sample"
      />
    )
  }
}

export const EditableSample = ({ blok, forceClose }) => {
  return (
    <SbEditable content={blok}>
      <Sample
        image={blok.image_override.length > 0 ? blok.image_override : blok.selected_sample.selectedSample.image}
        family={blok.selected_sample.selectedSample.family}
        pattern={blok.selected_sample.selectedSample.pattern}
        modal={!blok.river_flow}
        riverFlow={blok.river_flow}
        material={blok.selected_sample.selectedSample.material}
        materialType={blok.selected_sample.selectedSample.materialtype}
        description={blok.selected_sample.selectedSample.description}
        forceClose={forceClose}
      />
    </SbEditable>
  )
}

export default Sample

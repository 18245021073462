import React, { useRef, Fragment, useCallback, useMemo, useContext } from 'react'
import cn from 'classnames'
import { Icon, Banner } from '../'
import { Price } from '../price/Price'
import ShopifyContext from '../../contexts/Shopify/shopifyContext'

export const CartToggle = () => {

  const { checkout, toggleCart, getTotalItems } = useContext(ShopifyContext)

  return (
    <div className="cart__toggle" onClick={toggleCart}>
      <Icon name={`cart`} badge={checkout && checkout.lineItems.length > 0 && getTotalItems()} />
    </div>
  )
}

const formatPriceWithDiscounts = (lineItem) => {
  const price = lineItem.variant.priceV2.amount * lineItem.quantity
  if (!lineItem.discountAllocations) {
    return price / lineItem.quantity
  }
  const totalDiscounts = lineItem.discountAllocations.reduce((memo, allocation) => {
    const { amount } = allocation.allocatedAmount
    memo += amount
    return memo
  }, 0)
  return (price - totalDiscounts) / lineItem.quantity
}

export const DiscountBlock = ({
  code,
  onSubmit,
  onReset,
  error
}) => {

  const field = useRef(code)

  const handleSubmit = useCallback((ev) => {
    const { value } = field.current
    ev.preventDefault()
    if (!code && !!value) {
      onSubmit(value)
    }
  }, [field])

  return (
    <form className="cart__discount" onSubmit={handleSubmit}>
      <input className="form__input inline" placeholder="Code" type="text" name="discountCode" ref={field} defaultValue={code} readOnly={!!code} />

      {!code && (<button className="btn btn-primary" type="submit">Apply</button>)}
      {code && (<button className="btn btn-inverse" onClick={onReset} type="reset">Remove</button>)}

      {error && (
        <p className="form__error-text">{error}</p>
      )}
    </form>
  )
}

const Cart = () => {

  const {
    checkout,
    client,
    removeOneItem,
    addOneItem,
    openCheckout,
    addDiscount,
    removeDiscounts,
    getDiscountCode,
    isOpen,
    hideCart,
    clearCart,
    getTotalItems,
    error,
    fieldErrors
  } = useContext(ShopifyContext)
  const discountCode = useMemo(getDiscountCode, [checkout])

  return (
    <Fragment>
      {error && <Banner>Sorry an error occurred: <strong>{error}</strong></Banner>}
      <aside className={cn('cart', isOpen ? 'cart--open' : '')}>
        <header className="cart__header">
          <span className="cart__title">
            Cart <small>({getTotalItems()} item{getTotalItems() > 1 && 's'})</small>
          </span>
          <span className="cart__dismiss" onClick={hideCart}>
            <Icon name="close">Close</Icon>
          </span>
        </header>
        <div className="cart__body">
          {checkout && checkout.lineItems && checkout.lineItems.length ? (
            <Fragment>
              <ul className="cart__list">
                {checkout.lineItems.map(lineItem => (
                  <li key={lineItem.id} className="cart__item">
                    <figure className="cart__item-details">
                      <img
                        className="cart__item-thumbnail"
                        src={client.image.helpers.imageForSize(lineItem.variant.image, { maxWidth: 64, maxHeight: 64 })}
                      />
                      <figcaption className="cart__item-info">
                        <strong className="cart__item-title">{lineItem.title}</strong>
                        <span className="cart__item-variant">{lineItem.variant.title}</span>
                        <span className="cart__item-price">
                          {lineItem.discountAllocations.length > 0 ?
                            <Fragment>
                              <Price amount={lineItem.variant.priceV2.amount} className="crossed-out" />
                              <Price amount={formatPriceWithDiscounts(lineItem)} />
                            </Fragment>
                            :
                            <Price amount={lineItem.variant.priceV2.amount} />
                          }
                        </span>
                      </figcaption>
                    </figure>
                    <div className="cart__item-controls">
                      <span className="cart__item-control cart__item-control--add" onClick={() => removeOneItem(lineItem)}>
                        <Icon name="minus">Remove one</Icon>
                      </span>
                      <span className="cart__item-quantity">{lineItem.quantity}</span>
                      <span className="cart__item-control cart__item-control--remove" onClick={() => addOneItem(lineItem)}>
                        <Icon name="plus">Add one</Icon>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
              <DiscountBlock
                code={discountCode}
                onSubmit={(code) => addDiscount(code)}
                onReset={removeDiscounts}
                error={fieldErrors['discountCode']}
              />
              <div className="cart__subtotal">
                <span className="cart__subtotal-label">
                  Subtotal:
                </span>
                <span className="cart__subtotal-amount">
                  <Price amount={checkout.subtotalPriceV2.amount} />
                </span>
              </div>
              <footer className="cart__actions">
                <button aria-label="Proceed to Checkout" className="cart__action cta--full cart__action--open-chekout" onClick={openCheckout}>
                  Checkout
                </button>
                <button aria-label="Empty Cart" className="cart__action cta--link cart__action--clear-cart" onClick={clearCart}>
                  Empty Cart
                </button>
              </footer>
            </Fragment>
          ) : (
            <p className="cart__empty-message">Cart is empty</p>
          )}
        </div>
      </aside>
    </Fragment>
  )
}

export default Cart

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import { SampleRiverFlow } from 'components/samples-page/samples-page'

const whiteTextBg = ['#222222'];

export const EditableSingleSample = ({ blok }) => (
  <SbEditable content={blok}>
    {!!blok.sample && <SampleRiverFlow sample={blok.sample} side={blok.side || 'left'} backgroundColor={blok.background_color || 'white'} whiteText={whiteTextBg.includes(blok.background_color)} />}
  </SbEditable>
)
import React, { Fragment } from 'react'
import { marked } from 'marked'
import ConditionalWrap from 'conditional-wrap'
import { Link, FileDownload } from 'components'
import SbEditable from '../../utils/sb-editable'
import { renderBlok } from 'utils'
import { replaceIcons } from 'components/icon/icon'
import ReactHtmlParser from 'react-html-parser'

export const SpecificationBlock = ({ title, specificationItems, fileDownloads, image }) => (
  <div className="specification-block">
    <div className="specification-block__container">
      <div className="specification-block__title">
        <h2>{title}</h2>
      </div>
      <div className="specification-block__content">
        {specificationItems && (
          <div className="specification-block__items">
            <ul>
              {specificationItems.map((item, index) => (
                <li key={index}>
                  <ConditionalWrap
                    condition={item.link && item.link.url}
                    wrap={children => (
                      <Link to={item.link.url}>
                        {children}
                      </Link>
                    )}
                  >
                    <h3>{item.title}</h3>
                  </ConditionalWrap>
                  <Fragment>
                    {item.content && ReactHtmlParser(marked(item.content), {
                      transform: node => {
                        node.data = replaceIcons(node.data)
                      }
                    })}
                  </Fragment>
                  {item.attachments && item.attachments.map((file, index) => (
                    <FileDownload key={`${file}-${index}`} {...file} />
                  ))}
                </li>
              ))}
            </ul>
          </div>
        )}

        {fileDownloads && fileDownloads.length > 0 && (
          <div>
            <hr />
            <h3 className="specification-block__downloads-title">Detailed Specifications</h3>
            <div className="specification-block__downloads">
              <ul>
                {fileDownloads.map((file, index) => (
                  <li key={index}>{file}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      {image && (
        <div className="specification-block__image">
          <img src={image} />
        </div>
      )}
    </div>
  </div>
)

export const EditableSpecificationBlock = ({ blok }) => (
  <SbEditable content={blok}>
    <SpecificationBlock
      title={blok.title}
      specificationItems={
        blok.specification_items.length && blok.specification_items.filter(item => !!item).map(item => ({ title: item.title, link: item.link, content: item.content, attachments: item.attachments }))
      }
      fileDownloads={blok.file_downloads && blok.file_downloads.map(file => renderBlok(file))}
      image={blok.image && blok.image[0] && blok.image[0].image}
    />
  </SbEditable>
)

export default SpecificationBlock

import React, { Fragment } from 'react'
import SbEditable from '../../utils/sb-editable'
import ConditionalWrap from 'conditional-wrap'
import { Link } from 'gatsby'
import { FacetSearch, Grid, GridItem, RiverFlow, Image } from 'components'
import { renderBloks, slugify } from '../../utils'

const colorRiverFlow = ({item}) => (
  <RiverFlow
    side="left"
    whiteText={false}
    verticalSide="top"
    fullBleed={false}
    textAlign="left"
    content={
      <Fragment>
        <ConditionalWrap
          condition={item.family || item.familyFilterable}
          wrap={children => (
            <Link to={`/materials/families/${slugify(item.family || item.familyFilterable)}`}>{children}</Link>
          )}
        >
          <h2>{item.family || item.familyFilterable}</h2>
        </ConditionalWrap>
        <p>
          {(item.color || item.colorFilterable) && (
            <>
              Color:{' '}
              <strong>
                {item.color || item.colorFilterable} {item.number || item.numberFilterable}
              </strong>
              <br />
            </>
          )}

          {(item.aggregate || item.aggregateFilterable) && (
            <>
              Aggregate: <strong>{item.aggregate || item.aggregateFilterable}</strong>
              <br />
            </>
          )}
        </p>
      </Fragment>
    }
    images={[<Image key={1} src={item.imageRiverflow} />]}
    showThumbnails={false}
  />
)

const ColorPage = ({ family, hero, body, facets=[] }) => (
  <div>
    {hero}
    {body}
    {facets && (
      <FacetSearch
        indexName={`${family} colors`}
        facets={facets}
        perPage={30}
        renderHits={hits =>
          hits.length > 0 ? (
            <Grid columns={5}>
              {hits.map(item => (
                <GridItem
                  key={item.objectID}
                  {...item}
                  title={`${(item.color || item.colorFilterable)} ${(item.number || item.numberFilterable)}`}
                  image={item.imageThumbnail}
                  riverFlow={colorRiverFlow({item})}
                  type="pattern"
                  uuid={item.objectID}
                />
              ))}
            </Grid>
          ) : (
            <div className="container">
              <p>No results were found for your search</p>
            </div>
          )
        }
      />
    )}
  </div>
)


export const EditableColorPage = ({ blok }) => (
  <SbEditable content={blok}>
    <ColorPage
      family={blok.color_name}
      hero={renderBloks(blok.hero)}
      body={renderBloks(blok.body)}
      attributes={blok.attributes && blok.attributes.split(',')}
      facets={blok.facets}
    />
  </SbEditable>
)

export default ColorPage

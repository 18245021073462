import React from 'react'

const CategoryTable = ({ categories }) => (
  <div className="category-table">
    <div className="category-table__container">
      {categories.map((category, index) => (
        <div className="category-table__column" key={index}>
          <h4 className="category-table__category-title">{category.title}</h4>
          <ul>
            {category.values.map((value, i) => (
              <li className="category-table__category-detail" key={i}>
                {value}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
)

export default CategoryTable

import panelsize from './panel-size'
import preview from './preview'
import ledcolor from './led-color'
import frontfinish from './front-finish'
import backfinish from './back-finish'

export default {
  'panel-size': panelsize,
  'preview': preview,
  'led-color': ledcolor,
  'front-finish': frontfinish,
  'back-finish': backfinish,
}
import React, { Fragment } from 'react'
import cn from 'classnames'
import ConditionalWrap from 'conditional-wrap';
import { Icon } from 'components'
import FormField from './form-fields'
import { renderBloks } from '../../utils'

export class RadioButtons extends FormField {
  state = { selected: '', valid: true }

  check = value => {
    this.setState({ selected: value }, () => this.validate())
  }

  validate = () => {
    const { required } = this.props
    const { selected } = this.state

    if (required) {
      this.setState({ valid: !(selected === '') })
    }
    else {
      this.setState({ valid: true })
    }
  }

  renderField = () => {
    const { options, orientation, required, name, isConversational, chatQuestion, conversationalProps, errormessage } = this.props
    const { selected, valid } = this.state
    return (
      <Fragment>
        <div className={`form__select-wrapper form__select-wrapper--${orientation}`}>
          <ConditionalWrap
            condition={isConversational}
            wrap={children => (
              <fieldset cf-questions={chatQuestion}>{children}</fieldset>
            )}
          >
            {options.map((option, index) => {
              return (
                <ConditionalWrap
                  condition={!isConversational}
                  key={index}
                  wrap={children => (
                    <div className="form__check">{children}</div>
                  )}
                >
                  <RadioButton
                    name={name}
                    value={option.value}
                    label={option.label}
                    checked={option.value === selected}
                    onChange={() => this.check(option.value)}
                    required={required}
                    valid={valid}
                    addClass={!isConversational}
                    errormessage={errormessage}
                    conversationalProps={conversationalProps}

                  />
                </ConditionalWrap>
              )
            })}
          </ConditionalWrap>
        </div>
        {options.map(option => (
          isConversational ? (
            option.subfields?.map((field, index) => (
              <field.component {...field.props} key={index} conversationalProps={{ [`cf-conditional-${name}`]: option.value }} />
            ))
          ) : option.value === selected && (
            option.subfields?.map((field, index) => (
              <field.component {...field.props} key={index}></field.component>
            ))
          )
        ))}
      </Fragment>
    )
  }
}

export const RadioButton = ({ name, value, label, description, checked, onChange, required, valid, addClass, errormessage, conversationalProps }) => {
  return (
    <label htmlFor={`${name}-${value}`} className="form__check-label">
      <input
        id={`${name}-${value}`}
        className={addClass ? cn('form__input', 'form__check-input', 'form__select', { invalid: !valid }, { required: required }) : ''}
        name={name}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        cf-error={errormessage}
        {...conversationalProps}
      />
      {label}
      <div className={`form__radio ${checked ? 'checked' : 'unchecked'}`}>
        <Icon name="dot" />
      </div>
      {description && renderBloks(description)}
    </label>
  )
}

import React, { Fragment } from 'react'
import fieldsObject from '../data/fields';
import { filteredFields } from '../utils/utils';
import SpecifierField from '../components/specifier-field/specifier-field'
import SpecifierCheckbox from '../components/specifier-checkbox/specifier-checkbox'


const Infinity = (props) => {
  const { form } = props;
  const fields = filteredFields({ ...props, fields: { ...fieldsObject, ...fieldsObject['infinity'] }});

  return (
    <Fragment>
      <input type="hidden" name="material" value="resin" />
      <input type="hidden" name="family" value="infinity" /> 
      <small>These specifiers are designed to allow viable selections to be made. However light emission and efficient light travel through the material is complex and affected by the property of the light emitting surface material, including its surface finish.</small>

      {form && form['material'] &&
        <Fragment>
          {fields['panel-size'] && (
            <section className='specifier-block__section row'>
              <SpecifierField {...fields['panel-size']} {...props} />
            </section>  
          )}

        {form['material'] && form['panel-size'] &&
          <Fragment>
            <section className='specifier-block__section'>
              <h2 className='text--subheader'>Lighting</h2>
              <div className='row'>
                <input type="hidden" name="core-density" value="00" />
                {fields['light-emission'] && <SpecifierField {...fields['light-emission']} {...props} />}
                {fields['water-resistant'] && <SpecifierCheckbox {...fields['water-resistant']} {...props} />}
                {fields['led-color'] && <SpecifierField {...fields['led-color']} {...props} />}
                {fields['led-k'] && <SpecifierField {...fields['led-k']} {...props} />}
              </div>
            </section>

            <section className='specifier-block__section'>
              <h2 className='text--subheader'>A. Front Cladding</h2>
              <div className='row'>
                {fields['front-property'] && <SpecifierField {...fields['front-property']} {...props} />}
              </div>
              {form['front-property'] &&
                <div className='row'>
                  {fields['front-color'] && <SpecifierField {...fields['front-color']} {...props} />}
                  {form['front-color'] && <input type="hidden" name="front-finish" value={form['front-property'] === 'polycarb' ? 'frosted' : 'none'} />}
                  {fields['front-thickness'] && <SpecifierField {...fields['front-thickness']} {...props} />}
                </div>
              }
            </section>

            <section className='specifier-block__section'>
              <h2 className='text--subheader'>B. Clear Core</h2>
              <div className='row'>
                {fields['light-points'] && <SpecifierField {...fields['light-points']} {...props} />}
                {fields['pattern'] && <SpecifierField {...fields['pattern']} {...props} />}
              </div>
              <div className='row'>
                {form['core-thickness'] && <input type="hidden" name="core-grain-width" value="0" />}
                {fields['core-thickness'] && <SpecifierField {...fields['core-thickness']} {...props} replacer={{ "Core": 'Total Core' }} />}
              </div>
              <div className='row'>
                {fields['interlayer'] && <SpecifierField {...fields['interlayer']} {...props} />}
              </div>
            </section>

            <section className='specifier-block__section'>
              <h2 className='text--subheader'>C. Back Cladding</h2>
              <div className='row'>
                {fields['back-property'] && <SpecifierField {...fields['back-property']} {...props} />}
              </div>
              {form['back-property'] &&
                <div className='row'>
                  {fields['back-color'] && <SpecifierField {...fields['back-color']} {...props} />}
                  {form['back-color'] && <input type="hidden" name="back-finish" value={form['back-property'] === 'polycarb' ? 'frosted' : 'none'} />}
                  {fields['back-thickness'] && <SpecifierField {...fields['back-thickness']} {...props} />}
                </div>
              }
            </section>

            <section className='specifier-block__section'>
              <h2 className='text--subheader'>D. White Protect</h2>
              <div className='row'>
                {fields['white-protect'] && <SpecifierCheckbox {...fields['white-protect']} {...props} />}
              </div>
            </section>
          </Fragment>
        }
      </Fragment>
    }
    </Fragment>
  )
};

export default Infinity;
import React, { Component } from 'react'
import serialize from 'form-serialize'
import Products from './products'
import SpecifierCodeField from '../common/components/specifier-code-field/specifier-code-field'
import specifiers from './data/rules';
import { removeDuplicatedRules, getOrderingCode } from './utils/utils'

/**
 * Specifier for Light Fixtures (eg Fin, Wing, Disc etc)
 * originally copied from specifier-materials, but totally separated, nothing shared
 */
class SpecifierLights extends Component {
  state = {
    form: null,
    hash: '',
    offsetTop: null,
    orderingCode: null,
    isCopyTooltipVisible: false
  }

  componentDidMount() {
    const hash = location.search
    if(hash) {
      this.setForm(hash)
    } else {
      this.handleOnChange()
    }
  }

  componentDidUpdate() {
    const hash = location.search
    if(this.state.hash !== hash) {
      this.setForm(hash)
    }
  }

  setForm = hash => {
    if(!hash) { return; }

    const orderingCode = getOrderingCode(hash)
    const split = hash.slice(1, hash.length).split('&');
    let form = {};
    (split || []).forEach(key => {
      const [ name, value ] = key.split('=')
      form[name] = value
    })
    
    this.setState({ hash, form, orderingCode })
  }

  setHash = form => {
    const values = form && Object.keys(form)
      .filter(key => key.indexOf('measure') < 0)
      .map(key => `${key}=${form[key]}`)
    const hash = `?${values.join('&')}`;
    if(this.state.hash !== hash) {
      history.pushState(null, null, `${location.pathname.replace(/\/+$/, '')}/${hash}`)
      const orderingCode = getOrderingCode(hash)
      this.setState({ hash, orderingCode })
    }
  }

  /** All methonds you need, will include also some function to pushState */
  handleOnChange = (e) => {
    const form = serialize(this.form, { hash: true })
    this.setState({ form})

    // only when history is available, do this
    // don't do hash update on hashchange either
    if(!history || e === 'hashchange') { return }

    this.setHash(form)
  }

  handleCopyClick = () => {
    clearTimeout()
    setTimeout(() => this.setState({ isCopyTooltipVisible: false }), 3000)
    this.setState({ isCopyTooltipVisible: true })

    this.codefield.select()
    this.codefield.setSelectionRange(0, 99999) // for mobile

    document.execCommand('copy')
  }

  render() {
    const { blok: { family, image } } = this.props
    const Fields = Products[family]
    let rules = [
      ...specifiers[family] || [],
      ...specifiers.specifier
    ]
    rules = removeDuplicatedRules(rules)

    return (
      <section className="specifier-lights">
        <form ref={el => (this.form = el)}
          onChange={this.handleOnChange}
          className='container-fluid specifier-lights__form'>
          <div className='row'>
            {Fields && 
            <div className='col-sm-10 col-lg-8 col-xl-6 offset-sm-1 offset-lg-2 offset-xl-3'>
              <Fields form={this.state.form} rules={rules} triggerChange={this.handleOnChange} />
            </div>
            }
          </div>
          <div className='row'>
            <div className='col-sm-10 col-lg-8 col-xl-6 offset-sm-1 offset-lg-2 offset-xl-3'>
              <SpecifierCodeField
                orderingCode={this.state.orderingCode}
                isCopyTooltipVisible={this.state.isCopyTooltipVisible}
                handleCopyClick={this.handleCopyClick}
                ref={field => this.codefield = field}
              />
              {image && image.filename && (
                <>
                  <img src={image.filename} alt={`${family} measurement image`} className="specifier-lights__measurement-image" />
                  <p className="text-center">
                    <small>Picture shown may not be an exact match for your configuration</small>
                  </p>
                </>
              )}
            </div>
          </div>
        </form>
      </section>
    )
  }
}

export default SpecifierLights
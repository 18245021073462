import React, { Component, Fragment, useCallback, useState } from 'react'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import capitalize from 'lodash/capitalize'
import { Thumbnail, Icon } from 'components'
import { connectRefinementList, connectCurrentRefinements } from 'react-instantsearch-dom'

const Facet = ({ active, onClick, label, datasources, count, hideImage }) => (
  <li className={cn('nav-item', { active: active })} onClick={onClick}>
    <Thumbnail
      label={`${datasources && datasources[label] || label} (${count})`}
      image={!hideImage && '//a.storyblok.com/f/47554/512x512/18d83e0b59/c4abeda8e9c98278c1a6f507c483cd05ad5e0bef.png'}
    />
  </li>
)

const ActiveFilter = ({ name, datasources, onClick }) => (
  <span className="facet-search__selected-filter">
    {(datasources && datasources[name]) || name.replace(/_+/g, ' ').replace(/\w+/g, capitalize)}
    <span className="facet-search__remove-filter" onClick={onClick}>
      <Icon name="close" />
    </span>
  </span>
)

const FiltersGroup = connectRefinementList(({ items, currentRefinement, refine, index, onOpen, label, datasources, hideImages }) => (
  <Fragment>
    <button className="nav-item__link" onClick={() => onOpen(index)}>
      {label}
      <Icon name="arrow-down" />
    </button>
    {currentRefinement && (
      <div className="filters__selected">
        {currentRefinement.map((refinement, i) => (
          <ActiveFilter
            key={i}
            name={refinement}
            datasources={datasources}
            onClick={() => refine(currentRefinement.filter(r => r !== refinement))}
          />
        ))}
      </div>
    )}
    <ul className="nav-item__subnav">
      {items
        .sort((a, b) => a.label.localeCompare(b.label, 'en'))
        .map((item, i) => (
          <Facet
            key={i}
            active={item.isRefined}
            onClick={() => refine(item.value)}
            label={item.label}
            datasources={datasources}
            count={item.count}
            hideImage={hideImages}
          />
        ))}
    </ul>
  </Fragment>
))

const ClearFilters = connectCurrentRefinements(({ items, refine, className }) => (
  <span className={className} onClick={() => refine(items)} disabled={!items.length}>
    Reset All Filters
  </span>
))

const FacetSearchFilters = ({ facets, datasources, hideImages }) => {
  const [openFilters, setOpenFilters] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleFilters = () => {
    setOpenFilters(!openFilters)
  }

  const openSubfilters = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setOpenDropdown(null)}>
      <div className={cn('facet-search', { 'facet-search--open': openFilters })}>
        <div className="facet-search__inner">
          <div className="facet-search__toggle" onClick={toggleFilters}>
            <Icon name={`filters`} />
          </div>
          <div className="facet-search__navbar">
            <div className="facet-search__nav">
              <div>
                <ul className="nav">
                  <li className={cn('nav-item', 'facet-search__icon')}>
                    <Icon name="filters" />
                  </li>
                  {facets.map((facet, index) => {
                    return (
                      <li
                        key={index}
                        className={cn('nav-item', 'nav-item--parent', { 'nav-item--open': openDropdown === index })}
                      >
                        <FiltersGroup
                          index={index}
                          attribute={facet.key}
                          limit={50}
                          datasources={datasources}
                          label={facet.label}
                          onOpen={index => openSubfilters(index)}
                          hideImages={hideImages}
                        />
                      </li>
                    )
                  })}
                  <li className={cn('nav-item', 'nav-item--parent facet-search__clear')}>
                    <ClearFilters className="nav-item__link" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
};

export default FacetSearchFilters

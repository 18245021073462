import type from './type'
import pattern from './pattern'
import terminalshape from './terminal-shape'
import terminalsize from './terminal-size'
import preview from './preview'

export default {
  'type': type,
  'pattern': pattern,
  'terminal-shape': terminalshape,
  'terminal-size': terminalsize,
  'preview': preview
}
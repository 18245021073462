import React, { useState } from 'react'
import cn from 'classnames'
import SbEditable from '../../utils/sb-editable'
import SlickSlider from 'react-slick'
import { renderBlok, renderBloks } from 'utils'
import { SectionHeading } from 'components'

const slickSettings = {
  arrows: true,
  dots: true,
  infinite: false,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const CarouselGrid = ({ items, autoplay, itemsPerSlide, itemsPerScroll, title, subTitle, ctas, children, hidePrint }) => {
  const [ slideChanging, setSlideChanging ] = useState(false)
  const configurableSlickSettings = {
    autoplay: autoplay ? autoplay : false,
    slidesToShow: itemsPerSlide,
    slidesToScroll: itemsPerScroll,
  }

  return (
    <div className={cn('carousel-grid__container', hidePrint && 'd-print-none')}>
      <div className="container">
        <SectionHeading title={title} body={subTitle} alignment="center" />
        <div className="carousel carousel-grid">
          <SlickSlider
            {...slickSettings}
            {...configurableSlickSettings}
            beforeChange={() => setSlideChanging(true)}
            afterChange={() => setSlideChanging(false)}
          >
            {items && items.map((item, index) => {
              return <div key={index} className="carousel-grid__item">{renderBlok(item, index, { forceClose: slideChanging })}</div>
            })}
            {children && children}
          </SlickSlider>
        </div>
        <div>{ctas}</div>
      </div>
    </div>
  )

}

export const EditableCarouselGrid = ({ blok }) => (
  <SbEditable content={blok}>
    <CarouselGrid
      items={blok.items}
      autoplay={blok.autoplay}
      itemsPerSlide={blok.items_per_slide}
      itemsPerScroll={parseInt(blok.items_per_scroll) > 0 ? parseInt(blok.items_per_scroll) : 1}
      title={blok.title}
      subTitle={blok.subtitle && renderBloks(blok.subtitle)}
      ctas={blok.ctas && renderBloks(blok.ctas)}
      hidePrint={blok.hide_print}
    />
  </SbEditable>
)

export default CarouselGrid

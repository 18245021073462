import React, { forwardRef } from 'react'
import Fraction from 'fraction.js'
import cn from 'classnames'
import { hasRulesMatched } from '../../utils/utils';

// get total of a,b,c,d
const getTotalHeight = (props) => {
  const fraction = Object.keys(props)
    .map(key => props[key])
    .reduce((acc, curr) => acc + new Fraction(curr), 0)
  return `${fraction && new Fraction(fraction).toFraction(true)}"`
}

const Box = props => {
  const { position, fraction } = props
  let height = new Fraction(fraction === 'Custom' ? 0.5 : fraction)
  height = Number(height.toString()) * 300
  const style = { height, lineHeight: `${height}px`}

  return (
    <div className="specifier-materials__preview__box" style={style}>
      <strong>{position.toUpperCase()} </strong>
      <small> {fraction}"</small>
    </div>
  )
}


const SpecifierPreview = forwardRef(({ className, form, fields: { notes }, ...rest }, ref) => {

  // if target is set, its value needs to match with form selection
  const renderNotes = (note, idx) => (
    note.dependon && hasRulesMatched(note.dependon, form) &&
    <small key={idx} className='alert alert-info' role='alert'>
      <strong>Note: </strong>{note.content}
    </small>
  )

  let total = 0;
  try {
    total = getTotalHeight(rest)
  } catch(err) {
    total = 'Custom'
  }

  return (
    <div className={cn("specifier-materials__preview", className || null)} ref={ref}>
      <div className="specifier-materials__preview__scene">
        {Object.keys(rest).map(key => <Box key={key} position={key} fraction={rest[key]} />)}
      </div>
      <small>
        Total Thickness (Depth)
        <strong> {total}</strong>
      </small>

      {notes && notes.map(renderNotes)}
    </div>
  )
})

export default SpecifierPreview
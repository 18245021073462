import React from 'react'
import { HeaderContainer, FooterContainer, Cart, Board, Breadcrumbs, Toolbar, Share } from 'components'
import { ShopifyProvider } from '../../contexts/Shopify/shopifyContext'
import { InspirationBoardProvider } from '../../contexts/InspirationBoard/inspirationBoardContext'
import 'styles/all.scss'

// Layout is not re-rendered between routes, so we only want to put shared items here: https://www.gatsbyjs.org/docs/layout-components/#how-to-prevent-layout-components-from-unmounting
const Layout = (props) => (
  <div className="site-container">
    <InspirationBoardProvider>
      <ShopifyProvider>
        <HeaderContainer />
        <Cart />
        <Board />
        <Toolbar>
          <Breadcrumbs location={props.location} />
          <Share location={props.location} />
        </Toolbar>
        {props.children}
        <FooterContainer />
      </ShopifyProvider>
    </InspirationBoardProvider>
  </div>
)

export default Layout

import React, { useCallback, useRef, useEffect, useState } from 'react'
import serialize from 'form-serialize'
import { Modal } from 'components'



/**
 *  sku: string;
 *  children: ReactNode; (expect to have form with name="sku" field)
 */
const SpecifierContact = ({ sku, children }) => {
  const modalRef = useRef()
  const skuFieldRef = useRef()
  const isSkuValid = sku && sku.length >= 20

  const setSkuFieldValue = (options = []) => {
    const values = [sku].concat([options.join('')])
    skuFieldRef.current.value = values.join('-')
  }

  const setSkuField = (form) => {
    skuFieldRef.current = form.querySelector('[name="sku"]')
    if(!skuFieldRef.current) {
      return
    }
    setSkuFieldValue()
    handleFormChange(form)
  }

  const handleFormChange = (form) => {
    form.addEventListener('change', () => {
      const values = serialize(form, { hash: true })
      setSkuFieldValue(values.options)
    })
  }

  const handleAfterOpen = (res) => {
    const form = res.contentEl.querySelector('form')
    if(!form) {
      return
    }
    setSkuField(form)
  }

  const handleOpenModal = useCallback((e) => {
    if(!modalRef.current) {
      return
    }
    modalRef.current.open(e)
  }, [])

  return (
    <>
      {isSkuValid && (
        <p className="specifier-materials__contact">
          <button onClick={handleOpenModal} className="btn btn-primary">
            Contact Us
          </button>
        </p>
      )}

      <Modal
        ref={modalRef}
        title="Contact Us"
        fullScreen 
        className="specifier-materials__contact-form"
        onAfterOpen={handleAfterOpen}
      >
        {children}
      </Modal>
    </>
  )
}

export default SpecifierContact
import React, { useEffect, useState } from 'react'
import SbEditable from '../../utils/sb-editable'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import { Container, PageIntro } from '../'

const SiteMap = ({ pages }) => (
  <Container size={10}>
    <PageIntro title="Site Map"></PageIntro>
    <ul>
      {pages && pages.map((page, index) =>
        <li key={index}>
          <strong>{page.title}</strong>: <a href={page.fullSlug}>{page.fullSlug}</a>
        </li>
      )}
    </ul>
  </Container>
)

export const StoryblokSiteMapConverter = ({ excludedPaths, excludedPages, pages }) => {

  const [formattedPages, setFormattedPages] = useState()

  useEffect(() => {
    const includedPages = pages.filter(page => {
      const containingPaths = excludedPaths.filter(excludedPath => (
        page.node.full_slug.includes(excludedPath.path)
      ))
      return !excludedPages.includes(page.node.uuid) && containingPaths.length === 0
    })
    const simplePages = includedPages.map(page => (
      page.node
    ))
    setFormattedPages(simplePages.map(page => ({
      title: page.name,
      fullSlug: '/' + page.full_slug,
    })));
  }, [
    excludedPaths, excludedPages, pages
  ])

  return <SiteMap pages={formattedPages} />
}

export const EditableSiteMap = ({ blok }) => {

  const data = useStaticQuery(graphql`
    query AllPagesQuery {
      allStoryblokEntry {
        edges {
          node {
            uuid
            full_slug
            name
          }
        }
      }
    }
  `);

  return (
    <SbEditable content={blok}>
      <StoryblokSiteMapConverter pages={data.allStoryblokEntry.edges} excludedPaths={blok.excluded_paths} excludedPages={blok.excluded_pages} />
    </SbEditable>
  )
}

export default SiteMap;

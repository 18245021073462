import specifier from './global'
import fin from './fin'
import disc from './disc'
import wing from './wing'
import cube from './cube'


export default {
  specifier,
  fin,
  disc,
  wing,
  cube
}

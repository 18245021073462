import React, { PureComponent } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'
import { hasRulesMatched } from '../../utils/utils';

class SpecifierField extends PureComponent {
  state = { value: '', fraction: null }

  static propTypes = {
    name: PropTypes.string.isRequired,
    init: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    notes: PropTypes.arrayOf(PropTypes.shape({
      target: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string, PropTypes.number
      ])),
      content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    })),
    triggerChange: PropTypes.func,
    classNames: PropTypes.string,
  }

  componentDidMount() {
    this.setHashValue()
    
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { options, name, form } = this.props
    const { value } = this.state
    // externally set value form[name] is priority
    const val = (form && form[name]) || value

    if(prevState.value !== val || !isEqual(prevProps.options, options)) {
      this.autoSelect(val)
      this.setHashValue()
    }
  }

  autoSelect = value => {
    const { options } = this.props
    let val = value
    // if the options are changed, and there's no matching value with current selected value
    // force select empty value
    const match = options.filter(option => option.value === value)
    if(!match.length) {
      val = ''
    }
    // if there is only one option, force selecting it
    if(options.length === 1) {
      val = options[0].value
    }

    if(val !== null) {
      this.setValue(val)
    }
  }

  setHashValue = () => {
    const { name, form } = this.props
    const { value } = this.state;

    if(form && value !== form[name]) {
      this.autoSelect(form[name])
    }
  }

  // use this to set value and manually trigger onchange
  setValue = (value, e) => {
    const { options } = this.props;
    const fraction = options.reduce((acc, curr) => (curr.value === value? curr.fraction : acc), null)
    this.setState({ value, fraction })
    
    // give fake timeout for Chrome issue
    setTimeout(() => this.props.triggerChange(e), 0)
  }

  handleOnChange = ({ target: { value } }) => this.setValue(value)

  // eg replacer={{ "4'x8'": '48"x80"' }}
  replaceStr = (match, p1) => {
    const { replacer } = this.props
    if(!replacer || !replacer[p1]) { return p1 }
    return replacer[p1]
  }

  // if target is set, its value needs to match with form selection
  renderNotes = (note, idx) => {
    const content = note.content.replace(/{(.*?)}/, this.replaceStr);

    return (
      hasRulesMatched(note.dependon, this.props.form) &&
      <small key={idx} className='specifier-lights__field__helper'>{content}</small>
    )
  }

  renderOptions = (option, idx) => (
    <option key={idx} value={option.value}>{option.label}</option>
  )

  // for grouped options
  renderGroup = (key, options) => 
    key && key !== 'undefined' ? (
      <optgroup label={key} key={key}>
        {options.map(this.renderOptions)}
      </optgroup>
    ) : options.map(this.renderOptions)

  render() {
    const { name, label, description, options, notes, measure, classNames } = this.props
    const { fraction } = this.state
    const replacedLabel = label.replace(/{(.*?)}/, this.replaceStr);
    const grouped = {}
    options.forEach(option => {
      if(!grouped[option.group]) {
        grouped[option.group] = []
      }
      grouped[option.group].push(option)
    })

    return (
      <div className={cn('form-group col-sm-6 specifier-lights__field', classNames)}>
        <label  htmlFor={name}>
          {replacedLabel}
        </label>

        <select
          name={name}
          id={name}
          className='custom-select'
          value={this.state.value}
          onChange={this.handleOnChange}>
          {options[0].value !== '' && <option value="">Select {replacedLabel}</option>}
          {Object.keys(grouped).map(key => this.renderGroup(key, grouped[key]))}
        </select>
        {fraction && <input type='hidden' name={`measure-${measure}`} value={fraction} />}
        
        {description && <p>{description}</p>}
        {notes && notes.map(this.renderNotes)}
      </div>
    )
  }
}

export default SpecifierField

import React, { Fragment } from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import { Phone } from 'components'
import { renderBloks } from 'utils'
import { checkValidity } from './form-fields'
import FormField from './form-fields'

export class ShortText extends FormField {
  state = { valid: true, value: '' }

  updateValue = value => {
    this.setState({ value: value }, () => this.validate())
  }

  validate = () => {
    const { required, validation } = this.props
    const { value } = this.state
    this.setState({ value: value, valid: checkValidity(value, required, validation) })
  }

  renderField = () => {
    const { type, placeholder, name, chatQuestion, conversationalProps, errormessage, required, validation, defaultValue } = this.props
    const { valid, value } = this.state
    const fieldValue = value != '' ? value : defaultValue
    return (
      <Fragment>
        <input
          className={cn('form__input', { invalid: !valid })}
          placeholder={placeholder}
          type={type}
          name={name}
          onChange={e => this.updateValue(e.target.value)}
          value={fieldValue}
          cf-questions={chatQuestion}
          required={required}
          cf-error={errormessage}
          data-validation={validation}
          {...conversationalProps}
        />
      </Fragment>
    )
  }
}

export class LongText extends FormField {
  state = { valid: true, value: '' }

  updateValue = value => {
    this.setState({ value: value }, () => this.validate())
  }

  validate = () => {
    const { required } = this.props
    const { value } = this.state
    this.setState({ value: value, valid: checkValidity(value, required) })
  }

  renderField = () => {
    const { maxLength, placeholder, name, chatQuestion, conversationalProps, errormessage, required } = this.props
    const { valid, value } = this.state

    return (
      <textarea
        placeholder={placeholder}
        className={cn('form__input', { invalid: !valid })}
        maxLength={maxLength}
        name={name}
        onChange={e => this.updateValue(e.target.value)}
        value={value}
        cf-questions={chatQuestion}
        required={required}
        cf-error={errormessage}
        {...conversationalProps}
      />
    )
  }
}

export const EditableTextField = ({ blok, registerField, deregisterField, isConversational, conversationalProps }) => {
  const textField = type => {
    switch (type) {
      case 'long_text':
        return (
          <Fragment>
            <LongText
              label={renderBloks(blok.label)}
              placeholder={blok.placeholder}
              maxLength={blok.max_length ? blok.max_length : 500}
              helpText={blok.help_text}
              required={blok.required}
              validation={blok.custom_validation ? blok.custom_validation : blok.validation}
              name={blok.name}
              errormessage={blok.error_message ? blok.error_message : 'This field is required.'}
              chatQuestion={blok.chat_question}
              registerField={registerField}
              deregisterField={deregisterField}
              conversationalProps={conversationalProps}
              isConversational={isConversational}
            />
          </Fragment>
        )
      case 'password':
        return (
          <ShortText
            type="password"
            label={renderBloks(blok.label)}
            placeholder={blok.placeholder}
            validation={blok.custom_validation ? blok.custom_validation : blok.validation}
            helpText={blok.help_text}
            required={blok.required}
            name={blok.name}
            errormessage={blok.error_message ? blok.error_message : 'This field is required.'}
            chatQuestion={blok.chat_question}
            registerField={registerField}
            deregisterField={deregisterField}
            conversationalProps={conversationalProps}
            isConversational={isConversational}
          />
        )
      case 'phone':
        return (
          <Phone
            label={renderBloks(blok.label)}
            placeholder={blok.placeholder}
            helpText={blok.help_text}
            required={blok.required}
            name={blok.name}
            errormessage={blok.error_message ? blok.error_message : 'This field is required.'}
            chatQuestion={blok.chat_question}
            registerField={registerField}
            deregisterField={deregisterField}
            conversationalProps={conversationalProps}
            isConversational={isConversational}
          />
        )
      default:
        return (
          <ShortText
            type="text"
            label={renderBloks(blok.label)}
            placeholder={blok.placeholder}
            validation={blok.custom_validation ? blok.custom_validation : blok.validation}
            helpText={blok.help_text}
            required={blok.required}
            name={blok.name}
            errormessage={blok.error_message ? blok.error_message : 'This field is required.'}
            chatQuestion={blok.chat_question}
            registerField={registerField}
            deregisterField={deregisterField}
            conversationalProps={conversationalProps}
            isConversational={isConversational}
          />
        )
    }
  }

  return <SbEditable content={blok}>{textField(blok.type)}</SbEditable>
}

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import { GridItem, Text } from 'components'

const SingleStockItem = ({ image, title, link, uuid, body }) => {
  const bodyToTextComponent = () => <Text>{body}</Text>
  return <GridItem image={image} title={title} link={link} text={bodyToTextComponent()} />
}

export const EditableSingleStockItem = ({ blok }) => (
  <SbEditable content={blok}>
    <SingleStockItem
      image={blok.stock_item.thumbnail}
      title={blok.stock_item.title}
      link={'/' + blok.stock_item.full_slug}
      body={blok.stock_item.description}
    />
  </SbEditable>
)

export default SingleStockItem

import React from 'react'
import SbEditable from '../../utils/sb-editable'
import { renderBloks } from 'utils'

const PageIntro = ({ alignment, title, subtitle, children }) => (
  <div className={`page-intro page-intro--${alignment}`}>
    <h1 className="page-intro__title">{title}</h1>
    {subtitle && <h3>{subtitle}</h3>}
    <hr className="page-intro__bar" />
    <div className="page-intro__description">{children}</div>
  </div>
)

export const EditablePageIntro = ({ blok }) => (
  <SbEditable content={blok}>
    <PageIntro
      title={blok.title}
      subtitle={blok.subtitle}
      alignment={blok.alignment}
    >
      {renderBloks(blok.description)}
    </PageIntro>
  </SbEditable>
)

export default PageIntro

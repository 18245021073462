import React from 'react'
import SbEditable from '../../utils/sb-editable'
import cn from 'classnames'
import mime from 'mime'

import { Icon } from 'components'

const icons = {
  'application/pdf': 'pdf',
  default: 'generic-file',
}

const parseFilenameFromPath = path => {
  return path.replace(/.+[/]([^\.]+).*/g, '$1') // Pulls file name from full path: /dir/filename.extension -> filename
}

const FileDownload = ({ file, type, label }) => {
  // const humanizedSize = () => {
  //   if (size < 1000) {
  //     return size + 'b'
  //   } else if (size >= 1000 && size < 1000000) {
  //     return size / 1000 + 'kB'
  //   } else {
  //     return size / 1000000 + 'MB'
  //   }
  // }
  const fileType = type || mime.getType(file)
  const fileName = parseFilenameFromPath(file)

  return (
    <span className="file-download__container">
      <Icon className={''} name={fileType in icons ? icons[fileType] : icons.default} />
      <a className={'file-download file-download__name'} href={file}>
        {label || fileName}
      </a>
      {/* <span className={'file-download file-download__size'}> ({humanizedSize()})</span> */}
    </span>
  )
}

export const EditableFileDownload = ({ blok }) => (
  <SbEditable content={blok}>
    <FileDownload
      file={blok.file}
      // size={100}
      label={blok.label}
    />
  </SbEditable>
)

export default FileDownload

import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { renderBloks, renderSEO, injectSpecificationItems } from 'utils'
import startCase from 'lodash/startCase'
import { CategoryTable } from 'components'

const projectCategoriesToShow = {
  'application': 'Application',
  'industry': 'Industry',
  'environment': 'Environment',
  'illumination': 'Illumination',
  'material': 'Material',
  'color': 'Overall Color',
  'size': 'Size',
  'materialProperty': 'Material Property',
  'patternFamily': 'Family',
  'thickness': 'Thickness',
  'doubleSided': 'Double Sided',
  'lightPointShape': 'Light Point',
  // 'lightPointSize': 'Light Point Size',
  'coreThickness': 'Core Thickness',
  'pattern': 'Pattern',
  // 'patternAttributes': 'Pattern Attributes',
  'grain': 'Grain',
  'interlayers': 'Interlayers',
  'finish': 'Finish',
  'installationSolution': 'Installation Solution',
  'detailing': 'Detailing',
  'lighting': 'Lighting',
  'lightEmission': 'Light Emission'
}

const overrideSeoImage = (blok) => {
  return {
    ...blok,
    SEO: {
      ...blok.SEO,
      og_image: !!blok.SEO.og_image ? blok.SEO.og_image : blok.thumbnail,
      twitter_image: !!blok.SEO.twitter_image ? blok.SEO.twitter_image : blok.thumbnail
    }
  }
};

export const ProjectCategoriesTable = ({ project, blok }) => {
  if (!project) {
    console.log('You must pass a project to the project category table component')
    return <div />
  }
  const categories = projectCategoriesToShow
    .filter(category => project[category] && project[category].length > 0)
    .map(category => ({
      title: startCase(category),
      values: project[category].map(value => startCase(value)),
    }))

  return <CategoryTable categories={categories} />
}

const Project = ({ blok }) => {
  const data = useStaticQuery(graphql`
    query {
      datasources: allDatasourcesJson {
        edges {
          node {
            name
            value
            data_source
          }
        }
      }
    }
  `)
  if (!blok.body) return <div />
  const specificationBlok = blok.body.find(item => item.component === 'specification_block')
  blok = injectSpecificationItems(blok, specificationBlok, data.datasources.edges, projectCategoriesToShow);
  blok = overrideSeoImage(blok);
  return (
    <Fragment>
      {renderSEO(blok)}
      {renderBloks(blok.body, { project: blok })}
    </Fragment>
  )
}

export default Project

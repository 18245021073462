/**
 * @function hasRulesMatched
 * @param {Array} obj - array of object in `dependon`
 * @param {*} form - current form values
 * validate the current option against each dependon rules
 * - parent loop is OR condition
 * - children loop is AND condition
 */
const isFormValueValid = (formValue, rule) => {
  const isCheckbox = typeof rule.checked === 'boolean'
  const checkMatched =
    (!rule.checked && formValue === 'off') ||
    (rule.checked && formValue === 'on')

  const valueMatched =
    (rule.values && rule.values.includes(formValue)) ||
    (rule.not && !rule.not.includes(formValue))

  return valueMatched || (isCheckbox && checkMatched)
}

export const hasRulesMatched = (obj, form) => {
  let overallValid = false;
  (obj || []).forEach(depend => {
    let childValid = true;
    (depend.rules || []).forEach(rule => {
      // in case empty value is included in the rules
      const formValue = (form && form[rule.name]) || '';
      const isValid = isFormValueValid(formValue, rule)
      if(!isValid) {
        childValid = false;
      }
    })
    if(childValid) {
      overallValid = true;
    }
  });
  return !obj || overallValid;
};


/**
 * @function filteredFields
 * @param {Object} `form` `rules` 
 * TODO: cleanup
 */
export const filteredFields = ({ form, rules = [], fields = fieldsObject }) => {
  let dependon = rules;
  let newfields = {};

  Object.keys(fields).forEach(key => {
    const label = fields[key].name;
    let target = dependon && dependon.filter(rule => (rule && rule.target === `[name='${label}']`)) || [];
    // if there's no dependon rules, leave it as is
    if(!target.length) {
      newfields[key] = fields[key];
      return;
    // otherwise set the first array value as target
    } else {
      target = target[0];
    }


    // filter out field options by dependon conditions
    if(fields[key].options) {
      const options = fields[key].options.filter(option => {
        let valid = false;
        let found = false;
        target.conditions && target.conditions.forEach(condition => {
          if(!condition.options.includes(option.value)) { return; }
          found = true;
          const isValid = condition.dependon && hasRulesMatched(condition.dependon, form);
          if(isValid) {
            valid = true;
          }
        });
        // if the option is not found in any conditions, valid too
        return !found || valid;
      });
      if(options && options.length > 0) {
        newfields[key] = { ...fields[key], options };
      }
    }


    // set up checkbox field
    if(typeof fields[key].checked === 'boolean') {
      // reduce to whichever the conditions matches
      // (expect to be only one, if not the later one)
      const valid = target.conditions && target.conditions.reduce((acc, curr) => {
        const isValid = curr.dependon && hasRulesMatched(curr.dependon, form);
        return isValid ? curr : acc;
      }, null);

      newfields[key] = { 
        ...fields[key],
        // checked value could be from the form, and value could be `on`
        checked: !!(valid ? valid.checked : (form && (form[key] === 'on'))),
        // readonly is not in the form value
        readonly: valid && valid.readonly
      };
    }
  });

  return newfields;
};

/**
 * keep the beginning of array values
 * any duplicated values in the later in the loop will be excluded
 * @param {*} rules 
 */
export const removeDuplicatedRules = rules => {
  let names = [];
  return (rules || []).reduce((acc, curr) => {
    if(curr && curr.target && !names.includes(curr.target)) {
      names.push(curr.target)
      return [...acc, curr]
    }
    return acc
  }, [])
}

// select dropdown options in groups (if specified)
export const getGroupedOptions = options => {
  const grouped = {}
  options.forEach(option => {
    if(!grouped[option.group]) {
      grouped[option.group] = []
    }
    grouped[option.group].push(option)
  })
  return grouped
}

// get only value from str=value, join with dash
export const getOrderingCode = hash => hash ? 
  hash.split('&').map(str => str.split('=')[1])
    .filter(str => !!str)
    .join('-')
    .toUpperCase() : null
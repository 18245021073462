import React, { Fragment, useContext, useMemo, useState, useEffect } from 'react'
import ShopifyContext from '../../contexts/Shopify/shopifyContext'
import ProductItem from './product-item'
import { Hero, Grid } from '../'

const ShopifyProduct = ({ blok }) => {

  const { client } = useContext(ShopifyContext)
  const [descriptionHtml, setDescriptionHtml] = useState((blok.product && blok.product.descriptionHtml) || '')
  const [variants, setVariants] = useState((blok.product && blok.product.variants) || [])
  const [title, setTitle] = useState((blok.product && blok.product.title) || '')
  const [tags, setTags] = useState(blok.product && blok.product.tags || [])
  const require_tc = useMemo(() => (tags && tags.map(tag => tag.toLowerCase()).includes('sample kit') || false), [tags])
  const image = useMemo(() => (variants && variants[0].image), [variants])

  useEffect(() => {
    const fetchProduct = async () => {
      const res = await client.product.fetch(blok.product.id).catch(console.error)
      if (!res) { return }
      const { descriptionHtml, variants, title, tags } = res
      setDescriptionHtml(descriptionHtml)
      setVariants(variants)
      setTitle(title)
      // setTags(tags)
    }
    fetchProduct()
  }, [blok])

  return (
    <Fragment>
      <Hero align="left" image={image && image.src}>
        <Fragment>
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
        </Fragment>
      </Hero>
      <Grid columns={4}>
        {variants.map((variant, index) => (
          <ProductItem key={variant.id} require_tc={require_tc} {...variant} />
        ))}
      </Grid>
    </Fragment>
  )

}

export default ShopifyProduct

import React, { Fragment } from 'react'
import SbEditable from '../../utils/sb-editable'
import { Link } from 'gatsby'
import ConditionalWrap from 'conditional-wrap'
import { Grid, FacetSearch, GridItem, Text, CtaGroup, Cta, RiverFlow, Icon, Image } from 'components'
import { renderBloks, renderBlok, slugify } from 'utils'
import MBlogo from './MB-logo.svg'
const GATSBY_SAMPLES_BUCKET = process.env.GATSBY_SAMPLES_BUCKET;

const facets = [
  { key: 'color', label: 'Color' },
  { key: 'doubleSided', label: 'Double Sided' },
  { key: 'material', label: 'Material' },
  { key: 'combinedFinish', label: 'Finish' },
  { key: 'materialType', label: 'Material Type' },
  { key: 'family', label: 'Family' },
]

const sampleBody = sample => {
  return <Text>{`${sample.pattern ? sample.pattern + ' | ' : ''} ${sample.material}`}</Text>
}

const sampleImage = sample => {
  return `${GATSBY_SAMPLES_BUCKET}/${sample.image}`
}

const sampleBackImage = sample => {
  return `${GATSBY_SAMPLES_BUCKET}/${sample.backImage}`
}


const sampleMaterialType = sample => {
  if (sample.materialtype === 'Interactive') {
    return { title: 'Interactive', icon: 'interactive' }
  } else if (sample.materialtype === 'Light Filtering') {
    return { title: 'Light Filtering', icon: 'light-filtering' }
  } else if (sample.materialtype === 'Light Emitting') {
    return { title: 'Light Emitting', icon: 'light-emitting' }
  } else
    return {
      title: '',
      icon: '',
    }
}

const sampleImages = sample => {
  // multiple assets 
  if (sample.assets?.length) {
    return sample.assets.map((asset, index) => <Image src={`${GATSBY_SAMPLES_BUCKET}/${asset}`} key={index} />)
  }
  const images = [<Image src={sampleImage(sample)} key={1} />]
  if (!!sample.backImage) {
    images.push(<Image src={sampleBackImage(sample)} key={2} />)
  }
  return images;
}

const sampleThumbnails = sample => {
  if (sample.assets?.length) {
    return sample.assets.map((asset) => ({ image: `${GATSBY_SAMPLES_BUCKET}/${asset}` }));
  }
  if (!!sample.backImage) {
    return [
      {
        image: sampleImage(sample),
        text: "Front"
      },
      {
        image: sampleBackImage(sample),
        text: "Back"
      },
    ]
  }
  return [];
}

export const SampleRiverFlow = ({ sample, side = "left", backgroundColor = 'white', whiteText = false }) => (
  <RiverFlow
    side={side}
    whiteText={whiteText}
    verticalSide="top"
    fullBleed={false}
    textAlign="left"
    backgroundColor={backgroundColor}
    content={
      <Fragment>
        <ConditionalWrap
          condition={sample.family}
          wrap={children => <Link to={`/materials/families/${slugify(sample.family)}`}>{children}</Link>}
        >
          <h2>
            {sample.family}{' '}
            <small>
              <Icon name={sample.material} title={sample.material} />{' '}
              <Icon name={sampleMaterialType(sample).icon} title={sampleMaterialType(sample).title} />
            </small>
          </h2>
        </ConditionalWrap>
        <p>
          Sku: <strong>{sample.sku}</strong><br />
          {sample.material && (
            <>
              Material: <strong>{sample.material}</strong>
              <br />
            </>
          )}
          {sample.materialtype && (
            <>
              Material Type: <strong>{sample.materialtype}</strong>
              <br />
            </>
          )}
          {sample.pattern && (
            <>
              Pattern: <strong>{sample.pattern}</strong>
              <br />
            </>
          )}
          {sample.frontColor && (
            <>
              Front Color: <strong>{sample.frontColor}</strong>
              <br />
            </>
          )}
          {sample.backColor && (
            <>
              Back Color: <strong>{sample.backColor}</strong>
              <br />
            </>
          )}
          {sample.combinedFinish[0] && (
            <>
              Finish: <strong>{sample.combinedFinish.join(', ')}</strong>
              <br />
            </>
          )}
        </p>
        {sample.description && <p>{sample.description}</p>}
        {sample.family && (
          <CtaGroup>
            <Cta kind="full" link={`/projects/?page=1&patternFamily=${slugify(sample.family)}`}>
              See Portfolio
            </Cta>
            {/* <Cta
              kind="full"
              inverse={true}
              link={`/shop/on-hand-stock/?page=1&patterFamily=${slugify(sample.family)}`}
            >
              See On-Hand Stock
            </Cta> */}
            {sample.video && (
              <Cta
                kind="full"
                inverse={true}
                modal={{
                  title: 'Video',
                  body: renderBlok({
                    repeat: false,
                    autoplay: false,
                    component: 'video_player',
                    video_url: sample.video,
                    start_time: '',
                    hide_controls: false,
                  }),
                }}
              >
                Video
              </Cta>
            )}
            {sample.materialBankUrl && (
              <Cta kind="full" inverse={true} link={sample.materialBankUrl}>
                <img src={MBlogo} alt="Material Bank®" />
              </Cta>
            )}
          </CtaGroup>
        )}
      </Fragment>
    }
    images={sampleImages(sample)}
    thumbnails={sampleThumbnails(sample)}
    showThumbnails={!!sample.backImage}
  />
)

const SamplesPage = ({ header, body, gridColumns }) => (
  <Fragment>
    {header}
    <FacetSearch
      indexName="All Samples"
      facets={facets}
      renderHits={hits =>
        hits.length > 0 ? (
          <Grid columns={gridColumns}>
            {hits.map(sample => {
              return (
                <GridItem
                  key={sample.objectID}
                  {...sample}
                  title={sample.family}
                  image={sampleImage(sample)}
                  text={sampleBody(sample)}
                  icon={sampleMaterialType(sample)}
                  riverFlow={SampleRiverFlow({ sample })}
                  type="sample"
                  uuid={sample.objectID}
                />
              )
            })}
          </Grid>
        ) : (
          <div className="container">
            <p>No results were found for your search</p>
          </div>
        )
      }
    />
    {body}
  </Fragment>
);

export const EditableSamplesPage = ({ blok }) => (
  <SbEditable content={blok}>
    <SamplesPage header={renderBloks(blok.header)} gridColumns={blok.grid_columns} body={renderBloks(blok.body)} />
  </SbEditable>
)

export default SamplesPage

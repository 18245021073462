import React, { Component } from 'react'
import Client from 'shopify-buy'
const ShopifyContext = React.createContext()

const GATSBY_SHOPIFY_TOKEN = process.env.GATSBY_SHOPIFY_TOKEN
const GATSBY_SHOPIFY_DOMAIN = process.env.GATSBY_SHOPIFY_DOMAIN

let closeTimer = setInterval(() => { }, 100000)

const client = Client.buildClient({
  storefrontAccessToken: GATSBY_SHOPIFY_TOKEN,
  domain: GATSBY_SHOPIFY_DOMAIN,
  apiVersion: '2023-07'
})

export class ShopifyProvider extends Component {
  state = {
    isOpen: false,
    error: null,
    fieldErrors: {},
    checkout: { lineItems: [] },
  }

  handleshopifyError = (shopifyError) => {
    const error = shopifyError.length && shopifyError[0].message || shopifyError.message
    this.setState({ error })
    setTimeout(() => this.setState({ error: null }), 3000)
  }

  handleUserErrors = ({ checkout }) => {
    if (!checkout.userErrors) {
      return
    }
    const fieldErrors = checkout.userErrors.reduce((memo, { field, message }) => {
      memo[field[0].value] = message
      return memo
    }, {})
    this.setState({
      fieldErrors
    })
    console.log('User errors', checkout.userErrors)
  }

  updateCheckout = ({ checkout }) => {
    this.handleUserErrors({ checkout })
    this.setState({ checkout })
  }

  checkWindowState = (instance) => {
    if (instance.closed) {
      clearInterval(closeTimer)
      window.removeEventListener('message', this.onShopifyWindowPostMessage)
    }
  }

  openCheckout = () => {
    const checkoutWindow = window.open(
      this.state.checkout.webUrl,
      'checkout',
      'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30'
    )
    window.addEventListener('message', this.onShopifyWindowPostMessage)
    clearInterval(closeTimer)
    closeTimer = setInterval(this.checkWindowState, 1000, checkoutWindow)
    return checkoutWindow
  }

  onShopifyWindowPostMessage = (event) => {
    const origin = event.origin || event.originalEvent.origin
    const expectedOrigin = `https://${GATSBY_SHOPIFY_DOMAIN}`
    if (origin !== expectedOrigin) { return }
    const data = JSON.parse(event.data)
    if (data.current_checkout_page === '/checkout/thank_you') {
      this.clearCart(false)
      window.removeEventListener('message', this.onShopifyWindowPostMessage)
    }
  }

  toggleCart = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  showCart = () => {
    this.setState({ isOpen: true })
  }

  hideCart = () => {
    this.setState({ isOpen: false })
  }

  clearCart = async (shouldRemoveAllItems = true) => {
    shouldRemoveAllItems && await this.removeAllItems()
    localStorage.removeItem('checkoutId')
    this.getOrCreateCheckout()
    this.setState({ isOpen: false })
  }

  addItem = async (variant) => {
    const existingLineItem = this.state.checkout.lineItems.find(lineItem => lineItem.variant.id === variant.id)
    if (existingLineItem) { return this.addOneItem(existingLineItem) }
    const variantToAdd = [{ variantId: variant.id, quantity: 1 }]
    const checkout = await client.checkout.addLineItems(this.state.checkout.id, variantToAdd).catch(this.handleshopifyError)
    this.updateCheckout({ checkout })
  }

  addOneItem = async (lineItem) => {
    const { id, quantity, variant } = lineItem
    const lineItemToAdd = [{ id, quantity: quantity + 1, variantId: variant.id }]
    const checkout = await client.checkout.updateLineItems(this.state.checkout.id, lineItemToAdd).catch(this.handleshopifyError)
    this.updateCheckout({ checkout })
  }

  removeOneItem = async (lineItem) => {
    const { id, quantity, variant } = lineItem
    const lineItemToRemove = [{ id, quantity: quantity - 1, variantId: variant.id }]
    const checkout = await client.checkout.updateLineItems(this.state.checkout.id, lineItemToRemove).catch(this.handleshopifyError)
    this.updateCheckout({ checkout })
  }

  removeAllItems = async () => {
    const allLineItemIds = this.state.checkout.lineItems.map(lineItem => lineItem.id)
    const checkout = await client.checkout.removeLineItems(this.state.checkout.id, allLineItemIds).catch(this.handleshopifyError)
    this.updateCheckout({ checkout })
  }

  addDiscount = async (discountCode) => {
    const checkout = await client.checkout.addDiscount(this.state.checkout.id, discountCode).catch(this.handleshopifyError)
    this.updateCheckout({ checkout })
  }

  removeDiscounts = async () => {
    const checkout = await client.checkout.removeDiscount(this.state.checkout.id).catch(this.handleshopifyError)
    this.updateCheckout({ checkout })
  }

  getDiscountCode = () => {
    const { discountApplications } = this.state.checkout
    if (!discountApplications || !discountApplications.length) {
      return ''
    }
    const discountCode = discountApplications[0].code
    return discountCode
  }

  getCheckoutById = async (checkoutId) => {
    const checkout = await client.checkout.fetch(checkoutId).catch(this.handleshopifyError)
    if (!checkout) { return this.createCheckout() }
    this.updateCheckout({ checkout })
  }

  createCheckout = async () => {
    const checkout = await client.checkout.create().catch(this.handleshopifyError)
    localStorage.setItem('checkoutId', checkout.id)
    this.updateCheckout({ checkout })
  }

  getOrCreateCheckout = () => {
    const existingCheckout = localStorage.getItem('checkoutId')
      ; (existingCheckout && this.getCheckoutById(existingCheckout)) || this.createCheckout()
  }

  getTotalItems = () => {
    return this.state.checkout && this.state.checkout.lineItems.reduce((memo, item) => memo + item.quantity, 0)
  }

  componentDidMount() {
    this.getOrCreateCheckout()
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onShopifyWindowPostMessage)
  }

  render() {
    const { children } = this.props
    const { checkout, isOpen, error, fieldErrors } = this.state
    const { addItem, removeOneItem, addOneItem, openCheckout, addDiscount, removeDiscounts, getDiscountCode, toggleCart, hideCart, clearCart, getTotalItems } = this

    return (
      <ShopifyContext.Provider
        value={{
          client,
          addItem,
          removeOneItem,
          addOneItem,
          openCheckout,
          addDiscount,
          removeDiscounts,
          getDiscountCode,
          toggleCart,
          hideCart,
          clearCart,
          getTotalItems,
          isOpen,
          error,
          fieldErrors,
          checkout,
        }}
      >
        {children}
      </ShopifyContext.Provider>
    )
  }
}

export const ShopifyConsumer = ShopifyContext.Consumer
export default ShopifyContext
